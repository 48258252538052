export default {
  get({ values }) {
    return [200, {
      data: [
        {
          id: null,
          branch_id: 1,
          user_id: 1,
        },
        {
          id: null,
          branch_id: 1,
          user_id: 35,
        },
        {
          id: null,
          branch_id: 1,
          user_id: 34,
        },
        {
          id: null,
          branch_id: 1,
          user_id: 33,
        },
        {
          id: null,
          branch_id: 1,
          user_id: 32,
        },
        {
          id: null,
          branch_id: 1,
          user_id: 31,
        },
        {
          id: null,
          branch_id: 1,
          user_id: 30,
        },
        {
          id: null,
          branch_id: 1,
          user_id: 29,
        },
        {
          id: null,
          branch_id: 1,
          user_id: 28,
        },
        {
          id: null,
          branch_id: 1,
          user_id: 27,
        },
        {
          id: null,
          branch_id: 1,
          user_id: 69,
        },
        {
          id: null,
          branch_id: 1,
          user_id: 76,
        },
        {
          id: null,
          branch_id: 1,
          user_id: 74,
        },
        {
          id: null,
          branch_id: 3,
          user_id: 35,
        },
        {
          id: null,
          branch_id: 3,
          user_id: 34,
        },
        {
          id: null,
          branch_id: 3,
          user_id: 33,
        },
        {
          id: null,
          branch_id: 3,
          user_id: 32,
        },
        {
          id: null,
          branch_id: 3,
          user_id: 31,
        },
        {
          id: null,
          branch_id: 3,
          user_id: 30,
        },
        {
          id: null,
          branch_id: 3,
          user_id: 29,
        },
        {
          id: null,
          branch_id: 3,
          user_id: 28,
        },
        {
          id: null,
          branch_id: 3,
          user_id: 27,
        },
        {
          id: null,
          branch_id: 3,
          user_id: 39,
        },
        {
          id: null,
          branch_id: 3,
          user_id: 38,
        },
        {
          id: null,
          branch_id: 3,
          user_id: 37,
        },
        {
          id: null,
          branch_id: 3,
          user_id: 36,
        },
        {
          id: null,
          branch_id: 3,
          user_id: 1,
        },
        {
          id: null,
          branch_id: 3,
          user_id: 40,
        },
        {
          id: null,
          branch_id: 3,
          user_id: 53,
        },
        {
          id: null,
          branch_id: 3,
          user_id: 76,
        },
        {
          id: null,
          branch_id: 3,
          user_id: 74,
        },
        {
          id: null,
          branch_id: 4,
          user_id: 39,
        },
        {
          id: null,
          branch_id: 4,
          user_id: 38,
        },
        {
          id: null,
          branch_id: 4,
          user_id: 35,
        },
        {
          id: null,
          branch_id: 4,
          user_id: 34,
        },
        {
          id: null,
          branch_id: 4,
          user_id: 33,
        },
        {
          id: null,
          branch_id: 4,
          user_id: 32,
        },
        {
          id: null,
          branch_id: 4,
          user_id: 31,
        },
        {
          id: null,
          branch_id: 4,
          user_id: 30,
        },
        {
          id: null,
          branch_id: 4,
          user_id: 29,
        },
        {
          id: null,
          branch_id: 4,
          user_id: 28,
        },
        {
          id: null,
          branch_id: 4,
          user_id: 27,
        },
        {
          id: null,
          branch_id: 4,
          user_id: 8,
        },
        {
          id: null,
          branch_id: 4,
          user_id: 7,
        },
        {
          id: null,
          branch_id: 4,
          user_id: 75,
        },
        {
          id: null,
          branch_id: 4,
          user_id: 74,
        },
        {
          id: null,
          branch_id: 4,
          user_id: 76,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 37,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 36,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 26,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 25,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 24,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 23,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 22,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 21,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 20,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 19,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 18,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 17,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 16,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 15,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 14,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 13,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 12,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 11,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 10,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 9,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 6,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 5,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 1,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 40,
        },
        {
          id: null,
          branch_id: 5,
          user_id: 71,
        },
      ],
      links: [],
    }]
  },
}
