<template>
  <span v-if="exists" class="notification-badge" :style="outerStyle">
    <i class="fa fa-exclamation-circle" :style="innerStyle" />
  </span>
</template>
<style lang="scss">
.ant-menu-inline-collapsed-tooltip {
  .notification-badge {
    display: none;
  }
}
</style>
<script>
export default {
  name: 'NotificationBadge',
  props: {
    notificationKey: String, // 通知キー
    menuPosition: String, // 'left' | 'top'
  },
  data() {
    const outerSize = 12
    // 内部だけ白にするための円形の白い背景
    const outerStyles = [
      'background-color: #FFFFFF',
      `height: ${outerSize}px`,
      `width: ${outerSize}px`,
      `border-radius: ${outerSize / 2}px`,
      'padding: 0',
      'position: absolute',
      `margin-left: ${this.menuPosition === 'left' ? -11 : -14}px`,
      `margin-top: ${this.menuPosition === 'left' ? 2 : 6}px`,
    ]
    const innerStyles = [
      'color: #FF0000',
      'position: absolute',
      'top: -2px',
      `font-size: 16px`,
    ]

    return {
      outerStyle: outerStyles.join(';') + ';',
      innerStyle: innerStyles.join(';') + ';',
    }
  },
  computed: {
    exists() {
      if (!this.notificationKey) {
        return false
      }
      return this.$store.getters['notification/getExist'](this.notificationKey)
    },
  },
}
</script>
