var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style.topbar,
        _vm.settings.isMobileView ? _vm.$style.mobileTopbar : ""
      ]
    },
    [
      _vm.settings.isMobileView
        ? _c(
            "a-dropdown",
            {
              attrs: {
                trigger: ["click"],
                placement: "bottomLeft",
                overlayClassName: _vm.$style.NavigationTopMenu
              },
              model: {
                value: _vm.NavigationMenuVisible,
                callback: function($$v) {
                  _vm.NavigationMenuVisible = $$v
                },
                expression: "NavigationMenuVisible"
              }
            },
            [
              _c("div", { class: _vm.$style.openMenuButton }, [
                !_vm.NavigationMenuVisible
                  ? _c("i", {
                      staticClass: "fa fa-bars fa-2x fa-regular",
                      staticStyle: { color: "white" }
                    })
                  : _vm._e(),
                _vm.NavigationMenuVisible
                  ? _c("i", {
                      staticClass: "fa fa-close fa-2x fa-regular",
                      staticStyle: { color: "white" }
                    })
                  : _vm._e()
              ]),
              _c("cui-menu-top", {
                attrs: {
                  slot: "overlay",
                  close: _vm.closeNavigationMenu,
                  settings: _vm.settings
                },
                slot: "overlay"
              })
            ],
            1
          )
        : _vm._e(),
      _vm.settings.isMobileView
        ? _c("img", {
            class: _vm.$style.mobileLogoText,
            attrs: { src: "resources/images/logoText.png", alt: "" }
          })
        : _vm._e(),
      _c("cui-profile-menu", { attrs: { settings: _vm.settings } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }