export default {
  accquirable_vehicles: [
    {
      id: 1,
      branch_id: 1,
      branch_name: '本社',
      user_id: 1,
      user: {
        id: 1,
        company_id: 1,
        role: 1,
        name: 'テスト太郎',
        email: 'test@test.com',
        created_at: '2020-06-29 16:09:50',
        updated_at: '2021-11-11 17:21:38',
        deleted_at: null,
        email_new: null,
        confirmation_token: null,
        bounce_flag: 99,
        mobile_model: null,
        mobile_sim: null,
        tel: null,
        unavailable: 0,
        announcement_flag: 1,
        last_login: '2021-11-11 17:21:38',
      },
      name: '1234567',
      number: '1234567',
      logger_id: 1,
      car_type: 6,
      only_app: 0,
      created_at: '2020-06-29T07:16:21.000000Z',
      updated_at: '2021-07-21T05:51:26.000000Z',
      deleted_at: null,
    },
    {
      id: 2,
      branch_id: 1,
      branch_name: '本社',
      user_id: null,
      user: null,
      name: '管理 い',
      number: 'い1111111',
      logger_id: 2,
      car_type: 6,
      only_app: 0,
      created_at: '2020-06-29T07:16:28.000000Z',
      updated_at: '2021-10-20T06:50:51.000000Z',
      deleted_at: null,
    },
    {
      id: 3,
      branch_id: 1,
      branch_name: '本社',
      user_id: null,
      user: null,
      name: '管理 う',
      number: 'う1321133',
      logger_id: 3,
      car_type: 6,
      only_app: 0,
      created_at: '2020-06-29T07:16:34.000000Z',
      updated_at: '2020-09-15T12:28:50.000000Z',
      deleted_at: null,
    },
    {
      id: 6,
      branch_id: 1,
      branch_name: '本社',
      user_id: null,
      user: null,
      name: 'ID6',
      number: '666-6666',
      logger_id: 6,
      car_type: 6,
      only_app: 0,
      created_at: '2020-08-29T04:10:29.000000Z',
      updated_at: '2021-08-03T15:07:28.000000Z',
      deleted_at: null,
    },
    {
      id: 7,
      branch_id: 1,
      branch_name: '本社',
      user_id: null,
      user: null,
      name: 'ID7',
      number: '777-7777',
      logger_id: 7,
      car_type: 6,
      only_app: 0,
      created_at: '2020-08-29T04:11:07.000000Z',
      updated_at: '2021-08-05T13:32:13.000000Z',
      deleted_at: null,
    },
    {
      id: 8,
      branch_id: 1,
      branch_name: '本社',
      user_id: null,
      user: null,
      name: 'ID8',
      number: '888-8888',
      logger_id: 8,
      car_type: 6,
      only_app: 0,
      created_at: '2020-08-29T04:11:17.000000Z',
      updated_at: '2021-07-09T07:44:03.000000Z',
      deleted_at: null,
    },
    {
      id: 9,
      branch_id: 1,
      branch_name: '本社',
      user_id: null,
      user: null,
      name: 'ID9',
      number: '999-9999',
      logger_id: 9,
      car_type: 6,
      only_app: 0,
      created_at: '2020-08-29T04:11:27.000000Z',
      updated_at: '2021-07-12T04:21:07.000000Z',
      deleted_at: null,
    },
    {
      id: 4,
      branch_id: 3,
      branch_name: '神奈川支店',
      user_id: null,
      user: null,
      name: '管理 AAA',
      number: 'A5553333',
      logger_id: 4,
      car_type: 6,
      only_app: 0,
      created_at: '2020-06-29T07:16:44.000000Z',
      updated_at: '2021-08-03T08:51:33.000000Z',
      deleted_at: null,
    },
    {
      id: 5,
      branch_id: 3,
      branch_name: '神奈川支店',
      user_id: null,
      user: null,
      name: '管理 BBB',
      number: 'B3439966',
      logger_id: 5,
      car_type: 6,
      only_app: 1,
      created_at: '2020-06-29T07:16:55.000000Z',
      updated_at: '2021-10-26T10:21:38.000000Z',
      deleted_at: null,
    },
    {
      id: 88,
      branch_id: 3,
      branch_name: '神奈川支店',
      user_id: 36,
      user: {
        id: 36,
        company_id: 1,
        role: 0,
        name: '飯田 貫',
        email: 'iida@kenpalinc.com',
        created_at: '2020-09-07 22:14:01',
        updated_at: '2021-12-05 13:49:11',
        deleted_at: null,
        email_new: null,
        confirmation_token: null,
        bounce_flag: 0,
        mobile_model: null,
        mobile_sim: null,
        tel: null,
        unavailable: 0,
        announcement_flag: 1,
        last_login: '2021-12-05 13:49:11',
      },
      name: 'ロガー番号テスト',
      number: 'hoge',
      logger_id: 10000000080,
      car_type: 6,
      only_app: 0,
      created_at: '2021-05-05T18:42:04.000000Z',
      updated_at: '2021-05-25T05:40:01.000000Z',
      deleted_at: null,
    },
    {
      id: 10,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: 'ロガー10136',
      number: 'ロガー10136',
      logger_id: 10136,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-08T07:03:50.000000Z',
      updated_at: '2021-06-07T07:46:03.000000Z',
      deleted_at: null,
    },
    {
      id: 11,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両1_genext',
      number: '車両1_genext',
      logger_id: 10,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:10:41.000000Z',
      updated_at: '2021-12-06T07:28:59.000000Z',
      deleted_at: null,
    },
    {
      id: 12,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: 4,
      user: {
        id: 4,
        company_id: 1,
        role: 0,
        name: '佐野 岳史',
        email: 'takeshi_sano@genext.co.jp',
        created_at: '2020-06-29 16:12:40',
        updated_at: '2021-12-09 15:08:42',
        deleted_at: null,
        email_new: null,
        confirmation_token: null,
        bounce_flag: 0,
        mobile_model: null,
        mobile_sim: null,
        tel: null,
        unavailable: 0,
        announcement_flag: 0,
        last_login: '2021-12-09 15:08:42',
      },
      name: '車両2_genext',
      number: '車両2_genext',
      logger_id: 11,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:10:54.000000Z',
      updated_at: '2021-07-22T05:04:02.000000Z',
      deleted_at: null,
    },
    {
      id: 13,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両3_genext',
      number: '車両3_genext',
      logger_id: 12,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2021-10-14T01:25:02.000000Z',
      deleted_at: null,
    },
    {
      id: 14,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両4_genext',
      number: '車両4_genext',
      logger_id: 13,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2020-09-29T04:47:29.000000Z',
      deleted_at: null,
    },
    {
      id: 15,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両5_genext',
      number: '車両5_genext',
      logger_id: 14,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2021-12-11T11:24:14.000000Z',
      deleted_at: null,
    },
    {
      id: 16,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両6_genext',
      number: '車両6_genext',
      logger_id: 15,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2021-08-05T22:27:49.000000Z',
      deleted_at: null,
    },
    {
      id: 17,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: 35,
      user: {
        id: 35,
        company_id: 1,
        role: 3,
        name: 'Hajime Kasahara',
        email: 'test_genext9@gmail.com',
        created_at: '2020-08-29 13:13:08',
        updated_at: '2021-12-11 20:02:39',
        deleted_at: null,
        email_new: null,
        confirmation_token: '',
        bounce_flag: 3,
        mobile_model: null,
        mobile_sim: null,
        tel: null,
        unavailable: 0,
        announcement_flag: 1,
        last_login: '2021-12-11 20:02:39',
      },
      name: '車両7_genext',
      number: '車両7_genext',
      logger_id: 16,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2021-12-11T11:30:02.000000Z',
      deleted_at: null,
    },
    {
      id: 18,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両8_genext',
      number: '車両8_genext',
      logger_id: 17,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2021-10-17T08:32:38.000000Z',
      deleted_at: null,
    },
    {
      id: 19,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両9_genext',
      number: '車両9_genext',
      logger_id: 18,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2021-07-28T12:05:20.000000Z',
      deleted_at: null,
    },
    {
      id: 20,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: 'ロガー10135',
      number: 'ロガー10135',
      logger_id: 10135,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T08:05:03.000000Z',
      updated_at: '2020-10-01T02:46:29.000000Z',
      deleted_at: null,
    },
    {
      id: 89,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '粟野テスト1',
      number: '粟野テスト1',
      logger_id: 10000000081,
      car_type: 6,
      only_app: 0,
      created_at: '2021-05-06T00:25:15.000000Z',
      updated_at: '2021-11-12T07:23:24.000000Z',
      deleted_at: null,
    },
    {
      id: 90,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '粟野テスト2',
      number: '粟野テスト2',
      logger_id: 10000000082,
      car_type: 6,
      only_app: 0,
      created_at: '2021-05-06T00:25:46.000000Z',
      updated_at: '2021-05-06T00:25:46.000000Z',
      deleted_at: null,
    },
    {
      id: 91,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '粟野テスト3',
      number: '粟野テスト3',
      logger_id: 10000000083,
      car_type: 11,
      only_app: 0,
      created_at: '2021-05-06T00:28:32.000000Z',
      updated_at: '2021-07-14T01:55:49.000000Z',
      deleted_at: null,
    },
    {
      id: 133,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: 89,
      user: {
        id: 89,
        company_id: 1,
        role: 1,
        name: 'ゼンリン 生駒',
        email: 'takashiik3952@zenrin.co.jp',
        created_at: '2021-10-12 13:07:29',
        updated_at: '2021-11-17 17:21:29',
        deleted_at: null,
        email_new: null,
        confirmation_token: null,
        bounce_flag: 0,
        mobile_model: null,
        mobile_sim: null,
        tel: null,
        unavailable: 0,
        announcement_flag: 1,
        last_login: '2021-11-17 17:21:29',
      },
      name: 'ゼンリン社検証車両',
      number: 'ゼンリン社検証車両',
      logger_id: 10000000110,
      car_type: 6,
      only_app: 1,
      created_at: '2021-10-13T05:09:46.000000Z',
      updated_at: '2021-10-24T10:01:01.000000Z',
      deleted_at: null,
    },
    {
      id: 21,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両901_Kenpal',
      number: '901-1001',
      logger_id: 901,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:49:56.000000Z',
      updated_at: '2021-12-05T05:48:38.000000Z',
      deleted_at: null,
    },
    {
      id: 22,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両902_Kenpal',
      number: '902-1001',
      logger_id: 902,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:50:24.000000Z',
      updated_at: '2021-12-02T05:26:02.000000Z',
      deleted_at: null,
    },
    {
      id: 23,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両903_Kenpal',
      number: '903-1001',
      logger_id: 903,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:51:14.000000Z',
      updated_at: '2021-07-13T11:31:49.000000Z',
      deleted_at: null,
    },
    {
      id: 24,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両904_Kenpal',
      number: '904-1001',
      logger_id: 904,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:51:29.000000Z',
      updated_at: '2021-11-30T12:46:36.000000Z',
      deleted_at: null,
    },
    {
      id: 25,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両905_Kenpal',
      number: '905-1001',
      logger_id: 905,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:51:48.000000Z',
      updated_at: '2021-12-01T13:13:12.000000Z',
      deleted_at: null,
    },
    {
      id: 26,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両906_Kenpal',
      number: '906-1001',
      logger_id: 906,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:52:06.000000Z',
      updated_at: '2021-08-23T13:00:40.000000Z',
      deleted_at: null,
    },
    {
      id: 27,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両907_Kenpal',
      number: '907-1001',
      logger_id: 907,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:52:23.000000Z',
      updated_at: '2021-08-23T13:00:48.000000Z',
      deleted_at: null,
    },
    {
      id: 28,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両908_Kenpal',
      number: '908-1001',
      logger_id: 908,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:52:43.000000Z',
      updated_at: '2021-07-27T10:23:25.000000Z',
      deleted_at: null,
    },
    {
      id: 29,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両909_Kenpal',
      number: '909-1001',
      logger_id: 909,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:52:58.000000Z',
      updated_at: '2021-04-20T08:09:13.000000Z',
      deleted_at: null,
    },
    {
      id: 30,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両910_Kenpal',
      number: '910-1001',
      logger_id: 910,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:53:18.000000Z',
      updated_at: '2021-07-08T06:05:35.000000Z',
      deleted_at: null,
    },
    {
      id: 31,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両911_Kenpal',
      number: '911-1001',
      logger_id: 911,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:54:03.000000Z',
      updated_at: '2021-11-29T07:39:05.000000Z',
      deleted_at: null,
    },
    {
      id: 32,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両912_Kenpal',
      number: '912-1001',
      logger_id: 912,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:54:19.000000Z',
      updated_at: '2021-11-24T09:12:10.000000Z',
      deleted_at: null,
    },
    {
      id: 33,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両913_Kenpal',
      number: '913-1001',
      logger_id: 913,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:54:33.000000Z',
      updated_at: '2021-11-24T09:35:08.000000Z',
      deleted_at: null,
    },
    {
      id: 34,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両914_Kenpal',
      number: '914-1001',
      logger_id: 914,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:54:51.000000Z',
      updated_at: '2021-11-19T07:57:44.000000Z',
      deleted_at: null,
    },
    {
      id: 35,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両915_Kenpal',
      number: '915-1001',
      logger_id: 915,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:55:06.000000Z',
      updated_at: '2021-12-09T04:38:01.000000Z',
      deleted_at: null,
    },
    {
      id: 36,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両916_Kenpal',
      number: '916-1001',
      logger_id: 916,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:55:22.000000Z',
      updated_at: '2021-07-20T11:02:59.000000Z',
      deleted_at: null,
    },
    {
      id: 37,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両917_Kenpal',
      number: '917-1001',
      logger_id: 917,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:55:35.000000Z',
      updated_at: '2021-04-22T14:30:27.000000Z',
      deleted_at: null,
    },
    {
      id: 38,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両918_Kenpal',
      number: '918-1001',
      logger_id: 918,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:56:00.000000Z',
      updated_at: '2021-06-14T12:17:26.000000Z',
      deleted_at: null,
    },
    {
      id: 39,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: 25,
      user: {
        id: 25,
        company_id: 1,
        role: 3,
        name: 'テストドライバー19',
        email: 'test_user19@gmail.com',
        created_at: '2020-08-29 13:13:59',
        updated_at: '2020-08-29 13:13:59',
        deleted_at: null,
        email_new: null,
        confirmation_token: '',
        bounce_flag: 3,
        mobile_model: null,
        mobile_sim: null,
        tel: null,
        unavailable: 0,
        announcement_flag: 0,
        last_login: null,
      },
      name: '車両919_Kenpal',
      number: '919-1001',
      logger_id: 919,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:56:14.000000Z',
      updated_at: '2021-06-01T11:34:01.000000Z',
      deleted_at: null,
    },
    {
      id: 40,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両920_Kenpal',
      number: '920-1001',
      logger_id: 920,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:56:27.000000Z',
      updated_at: '2021-10-26T10:21:57.000000Z',
      deleted_at: null,
    },
  ],
  branches: [
    {
      id: 1,
      company_id: 1,
      company_name: 'Genext',
      name: '本社',
      address: '新横浜',
      comment: '',
      created_at: '2020-06-29T07:09:49.000000Z',
      updated_at: '2020-06-29T07:09:49.000000Z',
      deleted_at: null,
    },
    {
      id: 3,
      company_id: 1,
      company_name: 'Genext',
      name: '神奈川支店',
      address: '神奈川県横浜市',
      comment: null,
      created_at: '2020-06-29T07:16:07.000000Z',
      updated_at: '2020-06-29T07:16:07.000000Z',
      deleted_at: null,
    },
    {
      id: 4,
      company_id: 1,
      company_name: 'Genext',
      name: 'Genext 支店',
      address: null,
      comment: null,
      created_at: '2020-09-11T07:08:57.000000Z',
      updated_at: '2020-09-11T07:08:57.000000Z',
      deleted_at: null,
    },
    {
      id: 5,
      company_id: 1,
      company_name: 'Genext',
      name: 'Kenpal支店',
      address: null,
      comment: null,
      created_at: '2020-09-15T12:47:27.000000Z',
      updated_at: '2020-09-15T12:47:27.000000Z',
      deleted_at: null,
    },
    {
      id: 36,
      company_id: 1,
      company_name: 'Genext',
      name: 'UNCOVER_渋谷',
      address: '東京都渋谷区',
      comment: 'アンカバーテスト作成',
      created_at: '2021-11-10T08:56:12.000000Z',
      updated_at: '2021-11-10T08:56:12.000000Z',
      deleted_at: null,
    },
  ],
  vehicles: [
    {
      id: 1,
      branch_id: 1,
      branch_name: '本社',
      user_id: 1,
      user: {
        id: 1,
        company_id: 1,
        role: 1,
        name: 'テスト太郎',
        email: 'test@test.com',
        created_at: '2020-06-29 16:09:50',
        updated_at: '2021-11-11 17:21:38',
        deleted_at: null,
        email_new: null,
        confirmation_token: null,
        bounce_flag: 99,
        mobile_model: null,
        mobile_sim: null,
        tel: null,
        unavailable: 0,
        announcement_flag: 1,
        last_login: '2021-11-11 17:21:38',
      },
      name: '1234567',
      number: '1234567',
      logger_id: 1,
      car_type: 6,
      only_app: 0,
      created_at: '2020-06-29T07:16:21.000000Z',
      updated_at: '2021-07-21T05:51:26.000000Z',
      deleted_at: null,
    },
    {
      id: 2,
      branch_id: 1,
      branch_name: '本社',
      user_id: null,
      user: null,
      name: '管理 い',
      number: 'い1111111',
      logger_id: 2,
      car_type: 6,
      only_app: 0,
      created_at: '2020-06-29T07:16:28.000000Z',
      updated_at: '2021-10-20T06:50:51.000000Z',
      deleted_at: null,
    },
    {
      id: 3,
      branch_id: 1,
      branch_name: '本社',
      user_id: null,
      user: null,
      name: '管理 う',
      number: 'う1321133',
      logger_id: 3,
      car_type: 6,
      only_app: 0,
      created_at: '2020-06-29T07:16:34.000000Z',
      updated_at: '2020-09-15T12:28:50.000000Z',
      deleted_at: null,
    },
    {
      id: 6,
      branch_id: 1,
      branch_name: '本社',
      user_id: null,
      user: null,
      name: 'ID6',
      number: '666-6666',
      logger_id: 6,
      car_type: 6,
      only_app: 0,
      created_at: '2020-08-29T04:10:29.000000Z',
      updated_at: '2021-08-03T15:07:28.000000Z',
      deleted_at: null,
    },
    {
      id: 7,
      branch_id: 1,
      branch_name: '本社',
      user_id: null,
      user: null,
      name: 'ID7',
      number: '777-7777',
      logger_id: 7,
      car_type: 6,
      only_app: 0,
      created_at: '2020-08-29T04:11:07.000000Z',
      updated_at: '2021-08-05T13:32:13.000000Z',
      deleted_at: null,
    },
    {
      id: 8,
      branch_id: 1,
      branch_name: '本社',
      user_id: null,
      user: null,
      name: 'ID8',
      number: '888-8888',
      logger_id: 8,
      car_type: 6,
      only_app: 0,
      created_at: '2020-08-29T04:11:17.000000Z',
      updated_at: '2021-07-09T07:44:03.000000Z',
      deleted_at: null,
    },
    {
      id: 9,
      branch_id: 1,
      branch_name: '本社',
      user_id: null,
      user: null,
      name: 'ID9',
      number: '999-9999',
      logger_id: 9,
      car_type: 6,
      only_app: 0,
      created_at: '2020-08-29T04:11:27.000000Z',
      updated_at: '2021-07-12T04:21:07.000000Z',
      deleted_at: null,
    },
    {
      id: 4,
      branch_id: 3,
      branch_name: '神奈川支店',
      user_id: null,
      user: null,
      name: '管理 AAA',
      number: 'A5553333',
      logger_id: 4,
      car_type: 6,
      only_app: 0,
      created_at: '2020-06-29T07:16:44.000000Z',
      updated_at: '2021-08-03T08:51:33.000000Z',
      deleted_at: null,
    },
    {
      id: 5,
      branch_id: 3,
      branch_name: '神奈川支店',
      user_id: null,
      user: null,
      name: '管理 BBB',
      number: 'B3439966',
      logger_id: 5,
      car_type: 6,
      only_app: 1,
      created_at: '2020-06-29T07:16:55.000000Z',
      updated_at: '2021-10-26T10:21:38.000000Z',
      deleted_at: null,
    },
    {
      id: 88,
      branch_id: 3,
      branch_name: '神奈川支店',
      user_id: 36,
      user: {
        id: 36,
        company_id: 1,
        role: 0,
        name: '飯田 貫',
        email: 'iida@kenpalinc.com',
        created_at: '2020-09-07 22:14:01',
        updated_at: '2021-12-05 13:49:11',
        deleted_at: null,
        email_new: null,
        confirmation_token: null,
        bounce_flag: 0,
        mobile_model: null,
        mobile_sim: null,
        tel: null,
        unavailable: 0,
        announcement_flag: 1,
        last_login: '2021-12-05 13:49:11',
      },
      name: 'ロガー番号テスト',
      number: 'hoge',
      logger_id: 10000000080,
      car_type: 6,
      only_app: 0,
      created_at: '2021-05-05T18:42:04.000000Z',
      updated_at: '2021-05-25T05:40:01.000000Z',
      deleted_at: null,
    },
    {
      id: 10,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: 'ロガー10136',
      number: 'ロガー10136',
      logger_id: 10136,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-08T07:03:50.000000Z',
      updated_at: '2021-06-07T07:46:03.000000Z',
      deleted_at: null,
    },
    {
      id: 11,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両1_genext',
      number: '車両1_genext',
      logger_id: 10,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:10:41.000000Z',
      updated_at: '2021-12-06T07:28:59.000000Z',
      deleted_at: null,
    },
    {
      id: 12,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: 4,
      user: {
        id: 4,
        company_id: 1,
        role: 0,
        name: '佐野 岳史',
        email: 'takeshi_sano@genext.co.jp',
        created_at: '2020-06-29 16:12:40',
        updated_at: '2021-12-09 15:08:42',
        deleted_at: null,
        email_new: null,
        confirmation_token: null,
        bounce_flag: 0,
        mobile_model: null,
        mobile_sim: null,
        tel: null,
        unavailable: 0,
        announcement_flag: 0,
        last_login: '2021-12-09 15:08:42',
      },
      name: '車両2_genext',
      number: '車両2_genext',
      logger_id: 11,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:10:54.000000Z',
      updated_at: '2021-07-22T05:04:02.000000Z',
      deleted_at: null,
    },
    {
      id: 13,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両3_genext',
      number: '車両3_genext',
      logger_id: 12,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2021-10-14T01:25:02.000000Z',
      deleted_at: null,
    },
    {
      id: 14,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両4_genext',
      number: '車両4_genext',
      logger_id: 13,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2020-09-29T04:47:29.000000Z',
      deleted_at: null,
    },
    {
      id: 15,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両5_genext',
      number: '車両5_genext',
      logger_id: 14,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2021-12-11T11:24:14.000000Z',
      deleted_at: null,
    },
    {
      id: 16,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両6_genext',
      number: '車両6_genext',
      logger_id: 15,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2021-08-05T22:27:49.000000Z',
      deleted_at: null,
    },
    {
      id: 17,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: 35,
      user: {
        id: 35,
        company_id: 1,
        role: 3,
        name: 'Hajime Kasahara',
        email: 'test_genext9@gmail.com',
        created_at: '2020-08-29 13:13:08',
        updated_at: '2021-12-11 20:02:39',
        deleted_at: null,
        email_new: null,
        confirmation_token: '',
        bounce_flag: 3,
        mobile_model: null,
        mobile_sim: null,
        tel: null,
        unavailable: 0,
        announcement_flag: 1,
        last_login: '2021-12-11 20:02:39',
      },
      name: '車両7_genext',
      number: '車両7_genext',
      logger_id: 16,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2021-12-11T11:30:02.000000Z',
      deleted_at: null,
    },
    {
      id: 18,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両8_genext',
      number: '車両8_genext',
      logger_id: 17,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2021-10-17T08:32:38.000000Z',
      deleted_at: null,
    },
    {
      id: 19,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両9_genext',
      number: '車両9_genext',
      logger_id: 18,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2021-07-28T12:05:20.000000Z',
      deleted_at: null,
    },
    {
      id: 20,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: 'ロガー10135',
      number: 'ロガー10135',
      logger_id: 10135,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T08:05:03.000000Z',
      updated_at: '2020-10-01T02:46:29.000000Z',
      deleted_at: null,
    },
    {
      id: 89,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '粟野テスト1',
      number: '粟野テスト1',
      logger_id: 10000000081,
      car_type: 6,
      only_app: 0,
      created_at: '2021-05-06T00:25:15.000000Z',
      updated_at: '2021-11-12T07:23:24.000000Z',
      deleted_at: null,
    },
    {
      id: 90,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '粟野テスト2',
      number: '粟野テスト2',
      logger_id: 10000000082,
      car_type: 6,
      only_app: 0,
      created_at: '2021-05-06T00:25:46.000000Z',
      updated_at: '2021-05-06T00:25:46.000000Z',
      deleted_at: null,
    },
    {
      id: 91,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '粟野テスト3',
      number: '粟野テスト3',
      logger_id: 10000000083,
      car_type: 11,
      only_app: 0,
      created_at: '2021-05-06T00:28:32.000000Z',
      updated_at: '2021-07-14T01:55:49.000000Z',
      deleted_at: null,
    },
    {
      id: 133,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: 89,
      user: {
        id: 89,
        company_id: 1,
        role: 1,
        name: 'ゼンリン 生駒',
        email: 'takashiik3952@zenrin.co.jp',
        created_at: '2021-10-12 13:07:29',
        updated_at: '2021-11-17 17:21:29',
        deleted_at: null,
        email_new: null,
        confirmation_token: null,
        bounce_flag: 0,
        mobile_model: null,
        mobile_sim: null,
        tel: null,
        unavailable: 0,
        announcement_flag: 1,
        last_login: '2021-11-17 17:21:29',
      },
      name: 'ゼンリン社検証車両',
      number: 'ゼンリン社検証車両',
      logger_id: 10000000110,
      car_type: 6,
      only_app: 1,
      created_at: '2021-10-13T05:09:46.000000Z',
      updated_at: '2021-10-24T10:01:01.000000Z',
      deleted_at: null,
    },
    {
      id: 21,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両901_Kenpal',
      number: '901-1001',
      logger_id: 901,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:49:56.000000Z',
      updated_at: '2021-12-05T05:48:38.000000Z',
      deleted_at: null,
    },
    {
      id: 22,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両902_Kenpal',
      number: '902-1001',
      logger_id: 902,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:50:24.000000Z',
      updated_at: '2021-12-02T05:26:02.000000Z',
      deleted_at: null,
    },
    {
      id: 23,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両903_Kenpal',
      number: '903-1001',
      logger_id: 903,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:51:14.000000Z',
      updated_at: '2021-07-13T11:31:49.000000Z',
      deleted_at: null,
    },
    {
      id: 24,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両904_Kenpal',
      number: '904-1001',
      logger_id: 904,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:51:29.000000Z',
      updated_at: '2021-11-30T12:46:36.000000Z',
      deleted_at: null,
    },
    {
      id: 25,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両905_Kenpal',
      number: '905-1001',
      logger_id: 905,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:51:48.000000Z',
      updated_at: '2021-12-01T13:13:12.000000Z',
      deleted_at: null,
    },
    {
      id: 26,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両906_Kenpal',
      number: '906-1001',
      logger_id: 906,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:52:06.000000Z',
      updated_at: '2021-08-23T13:00:40.000000Z',
      deleted_at: null,
    },
    {
      id: 27,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両907_Kenpal',
      number: '907-1001',
      logger_id: 907,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:52:23.000000Z',
      updated_at: '2021-08-23T13:00:48.000000Z',
      deleted_at: null,
    },
    {
      id: 28,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両908_Kenpal',
      number: '908-1001',
      logger_id: 908,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:52:43.000000Z',
      updated_at: '2021-07-27T10:23:25.000000Z',
      deleted_at: null,
    },
    {
      id: 29,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両909_Kenpal',
      number: '909-1001',
      logger_id: 909,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:52:58.000000Z',
      updated_at: '2021-04-20T08:09:13.000000Z',
      deleted_at: null,
    },
    {
      id: 30,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両910_Kenpal',
      number: '910-1001',
      logger_id: 910,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:53:18.000000Z',
      updated_at: '2021-07-08T06:05:35.000000Z',
      deleted_at: null,
    },
    {
      id: 31,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両911_Kenpal',
      number: '911-1001',
      logger_id: 911,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:54:03.000000Z',
      updated_at: '2021-11-29T07:39:05.000000Z',
      deleted_at: null,
    },
    {
      id: 32,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両912_Kenpal',
      number: '912-1001',
      logger_id: 912,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:54:19.000000Z',
      updated_at: '2021-11-24T09:12:10.000000Z',
      deleted_at: null,
    },
    {
      id: 33,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両913_Kenpal',
      number: '913-1001',
      logger_id: 913,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:54:33.000000Z',
      updated_at: '2021-11-24T09:35:08.000000Z',
      deleted_at: null,
    },
    {
      id: 34,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両914_Kenpal',
      number: '914-1001',
      logger_id: 914,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:54:51.000000Z',
      updated_at: '2021-11-19T07:57:44.000000Z',
      deleted_at: null,
    },
    {
      id: 35,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両915_Kenpal',
      number: '915-1001',
      logger_id: 915,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:55:06.000000Z',
      updated_at: '2021-12-09T04:38:01.000000Z',
      deleted_at: null,
    },
    {
      id: 36,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両916_Kenpal',
      number: '916-1001',
      logger_id: 916,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:55:22.000000Z',
      updated_at: '2021-07-20T11:02:59.000000Z',
      deleted_at: null,
    },
    {
      id: 37,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両917_Kenpal',
      number: '917-1001',
      logger_id: 917,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:55:35.000000Z',
      updated_at: '2021-04-22T14:30:27.000000Z',
      deleted_at: null,
    },
    {
      id: 38,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両918_Kenpal',
      number: '918-1001',
      logger_id: 918,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:56:00.000000Z',
      updated_at: '2021-06-14T12:17:26.000000Z',
      deleted_at: null,
    },
    {
      id: 39,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: 25,
      user: {
        id: 25,
        company_id: 1,
        role: 3,
        name: 'テストドライバー19',
        email: 'test_user19@gmail.com',
        created_at: '2020-08-29 13:13:59',
        updated_at: '2020-08-29 13:13:59',
        deleted_at: null,
        email_new: null,
        confirmation_token: '',
        bounce_flag: 3,
        mobile_model: null,
        mobile_sim: null,
        tel: null,
        unavailable: 0,
        announcement_flag: 0,
        last_login: null,
      },
      name: '車両919_Kenpal',
      number: '919-1001',
      logger_id: 919,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:56:14.000000Z',
      updated_at: '2021-06-01T11:34:01.000000Z',
      deleted_at: null,
    },
    {
      id: 40,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両920_Kenpal',
      number: '920-1001',
      logger_id: 920,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:56:27.000000Z',
      updated_at: '2021-10-26T10:21:57.000000Z',
      deleted_at: null,
    },
  ],
  dashboard_vehicles: [
    {
      id: 1,
      branch_id: 1,
      branch_name: '本社',
      user_id: 1,
      user: {
        id: 1,
        company_id: 1,
        role: 1,
        name: 'テスト太郎',
        email: 'test@test.com',
        created_at: '2020-06-29 16:09:50',
        updated_at: '2021-11-11 17:21:38',
        deleted_at: null,
        email_new: null,
        confirmation_token: null,
        bounce_flag: 99,
        mobile_model: null,
        mobile_sim: null,
        tel: null,
        unavailable: 0,
        announcement_flag: 1,
        last_login: '2021-11-11 17:21:38',
      },
      name: '1234567',
      number: '1234567',
      logger_id: 1,
      car_type: 6,
      only_app: 0,
      created_at: '2020-06-29T07:16:21.000000Z',
      updated_at: '2021-07-21T05:51:26.000000Z',
      deleted_at: null,
    },
    {
      id: 2,
      branch_id: 1,
      branch_name: '本社',
      user_id: null,
      user: null,
      name: '管理 い',
      number: 'い1111111',
      logger_id: 2,
      car_type: 6,
      only_app: 0,
      created_at: '2020-06-29T07:16:28.000000Z',
      updated_at: '2021-10-20T06:50:51.000000Z',
      deleted_at: null,
    },
    {
      id: 3,
      branch_id: 1,
      branch_name: '本社',
      user_id: null,
      user: null,
      name: '管理 う',
      number: 'う1321133',
      logger_id: 3,
      car_type: 6,
      only_app: 0,
      created_at: '2020-06-29T07:16:34.000000Z',
      updated_at: '2020-09-15T12:28:50.000000Z',
      deleted_at: null,
    },
    {
      id: 6,
      branch_id: 1,
      branch_name: '本社',
      user_id: null,
      user: null,
      name: 'ID6',
      number: '666-6666',
      logger_id: 6,
      car_type: 6,
      only_app: 0,
      created_at: '2020-08-29T04:10:29.000000Z',
      updated_at: '2021-08-03T15:07:28.000000Z',
      deleted_at: null,
    },
    {
      id: 7,
      branch_id: 1,
      branch_name: '本社',
      user_id: null,
      user: null,
      name: 'ID7',
      number: '777-7777',
      logger_id: 7,
      car_type: 6,
      only_app: 0,
      created_at: '2020-08-29T04:11:07.000000Z',
      updated_at: '2021-08-05T13:32:13.000000Z',
      deleted_at: null,
    },
    {
      id: 8,
      branch_id: 1,
      branch_name: '本社',
      user_id: null,
      user: null,
      name: 'ID8',
      number: '888-8888',
      logger_id: 8,
      car_type: 6,
      only_app: 0,
      created_at: '2020-08-29T04:11:17.000000Z',
      updated_at: '2021-07-09T07:44:03.000000Z',
      deleted_at: null,
    },
    {
      id: 9,
      branch_id: 1,
      branch_name: '本社',
      user_id: null,
      user: null,
      name: 'ID9',
      number: '999-9999',
      logger_id: 9,
      car_type: 6,
      only_app: 0,
      created_at: '2020-08-29T04:11:27.000000Z',
      updated_at: '2021-07-12T04:21:07.000000Z',
      deleted_at: null,
    },
    {
      id: 4,
      branch_id: 3,
      branch_name: '神奈川支店',
      user_id: null,
      user: null,
      name: '管理 AAA',
      number: 'A5553333',
      logger_id: 4,
      car_type: 6,
      only_app: 0,
      created_at: '2020-06-29T07:16:44.000000Z',
      updated_at: '2021-08-03T08:51:33.000000Z',
      deleted_at: null,
    },
    {
      id: 5,
      branch_id: 3,
      branch_name: '神奈川支店',
      user_id: null,
      user: null,
      name: '管理 BBB',
      number: 'B3439966',
      logger_id: 5,
      car_type: 6,
      only_app: 1,
      created_at: '2020-06-29T07:16:55.000000Z',
      updated_at: '2021-10-26T10:21:38.000000Z',
      deleted_at: null,
    },
    {
      id: 88,
      branch_id: 3,
      branch_name: '神奈川支店',
      user_id: 36,
      user: {
        id: 36,
        company_id: 1,
        role: 0,
        name: '飯田 貫',
        email: 'iida@kenpalinc.com',
        created_at: '2020-09-07 22:14:01',
        updated_at: '2021-12-24 09:34:47',
        deleted_at: null,
        email_new: null,
        confirmation_token: null,
        bounce_flag: 0,
        mobile_model: null,
        mobile_sim: null,
        tel: null,
        unavailable: 0,
        announcement_flag: 1,
        last_login: '2021-12-24 09:34:47',
      },
      name: 'ロガー番号テスト',
      number: 'hoge',
      logger_id: 10000000080,
      car_type: 6,
      only_app: 0,
      created_at: '2021-05-05T18:42:04.000000Z',
      updated_at: '2021-05-25T05:40:01.000000Z',
      deleted_at: null,
    },
    {
      id: 10,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: 'ロガー10136',
      number: 'ロガー10136',
      logger_id: 10136,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-08T07:03:50.000000Z',
      updated_at: '2021-06-07T07:46:03.000000Z',
      deleted_at: null,
    },
    {
      id: 11,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両1_genext',
      number: '車両1_genext',
      logger_id: 10,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:10:41.000000Z',
      updated_at: '2021-12-21T06:27:15.000000Z',
      deleted_at: null,
    },
    {
      id: 12,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: 4,
      user: {
        id: 4,
        company_id: 1,
        role: 3,
        name: '佐野 岳史',
        email: 'takeshi_sano@genext.co.jp',
        created_at: '2020-06-29 16:12:40',
        updated_at: '2021-12-23 18:47:48',
        deleted_at: null,
        email_new: null,
        confirmation_token: null,
        bounce_flag: 0,
        mobile_model: null,
        mobile_sim: null,
        tel: null,
        unavailable: 0,
        announcement_flag: 0,
        last_login: '2021-12-23 18:47:48',
      },
      name: '車両2_genext',
      number: '車両2_genext',
      logger_id: 11,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:10:54.000000Z',
      updated_at: '2021-07-22T05:04:02.000000Z',
      deleted_at: null,
    },
    {
      id: 13,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両3_genext',
      number: '車両3_genext',
      logger_id: 12,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2021-10-14T01:25:02.000000Z',
      deleted_at: null,
    },
    {
      id: 14,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両4_genext',
      number: '車両4_genext',
      logger_id: 13,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2020-09-29T04:47:29.000000Z',
      deleted_at: null,
    },
    {
      id: 15,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両5_genext',
      number: '車両5_genext',
      logger_id: 14,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2021-12-11T11:24:14.000000Z',
      deleted_at: null,
    },
    {
      id: 16,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両6_genext',
      number: '車両6_genext',
      logger_id: 15,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2021-08-05T22:27:49.000000Z',
      deleted_at: null,
    },
    {
      id: 17,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: 35,
      user: {
        id: 35,
        company_id: 1,
        role: 3,
        name: 'Hajime Kasahara',
        email: 'test_genext9@gmail.com',
        created_at: '2020-08-29 13:13:08',
        updated_at: '2021-12-11 20:02:39',
        deleted_at: null,
        email_new: null,
        confirmation_token: '',
        bounce_flag: 3,
        mobile_model: null,
        mobile_sim: null,
        tel: null,
        unavailable: 0,
        announcement_flag: 1,
        last_login: '2021-12-11 20:02:39',
      },
      name: '車両7_genext',
      number: '車両7_genext',
      logger_id: 16,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2021-12-11T11:30:02.000000Z',
      deleted_at: null,
    },
    {
      id: 18,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両8_genext',
      number: '車両8_genext',
      logger_id: 17,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2021-10-17T08:32:38.000000Z',
      deleted_at: null,
    },
    {
      id: 19,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '車両9_genext',
      number: '車両9_genext',
      logger_id: 18,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-11T07:11:17.000000Z',
      updated_at: '2021-07-28T12:05:20.000000Z',
      deleted_at: null,
    },
    {
      id: 20,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: 'ロガー10135',
      number: 'ロガー10135',
      logger_id: 10135,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T08:05:03.000000Z',
      updated_at: '2020-10-01T02:46:29.000000Z',
      deleted_at: null,
    },
    {
      id: 89,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '粟野テスト1',
      number: '粟野テスト1',
      logger_id: 10000000081,
      car_type: 6,
      only_app: 0,
      created_at: '2021-05-06T00:25:15.000000Z',
      updated_at: '2021-11-12T07:23:24.000000Z',
      deleted_at: null,
    },
    {
      id: 90,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '粟野テスト2',
      number: '粟野テスト2',
      logger_id: 10000000082,
      car_type: 6,
      only_app: 0,
      created_at: '2021-05-06T00:25:46.000000Z',
      updated_at: '2021-05-06T00:25:46.000000Z',
      deleted_at: null,
    },
    {
      id: 91,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: null,
      user: null,
      name: '粟野テスト3',
      number: '粟野テスト3',
      logger_id: 10000000083,
      car_type: 11,
      only_app: 0,
      created_at: '2021-05-06T00:28:32.000000Z',
      updated_at: '2021-07-14T01:55:49.000000Z',
      deleted_at: null,
    },
    {
      id: 133,
      branch_id: 4,
      branch_name: 'Genext 支店',
      user_id: 89,
      user: {
        id: 89,
        company_id: 1,
        role: 1,
        name: 'ゼンリン 生駒',
        email: 'takashiik3952@zenrin.co.jp',
        created_at: '2021-10-12 13:07:29',
        updated_at: '2021-12-29 09:20:45',
        deleted_at: null,
        email_new: null,
        confirmation_token: null,
        bounce_flag: 0,
        mobile_model: null,
        mobile_sim: null,
        tel: null,
        unavailable: 0,
        announcement_flag: 1,
        last_login: '2021-12-29 09:20:45',
      },
      name: 'ゼンリン社検証車両',
      number: 'ゼンリン社検証車両',
      logger_id: 10000000110,
      car_type: 6,
      only_app: 1,
      created_at: '2021-10-13T05:09:46.000000Z',
      updated_at: '2021-10-24T10:01:01.000000Z',
      deleted_at: null,
    },
    {
      id: 21,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両901_Kenpal',
      number: '901-1001',
      logger_id: 901,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:49:56.000000Z',
      updated_at: '2021-12-24T01:20:53.000000Z',
      deleted_at: null,
    },
    {
      id: 22,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両902_Kenpal',
      number: '902-1001',
      logger_id: 902,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:50:24.000000Z',
      updated_at: '2021-12-02T05:26:02.000000Z',
      deleted_at: null,
    },
    {
      id: 23,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両903_Kenpal',
      number: '903-1001',
      logger_id: 903,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:51:14.000000Z',
      updated_at: '2021-07-13T11:31:49.000000Z',
      deleted_at: null,
    },
    {
      id: 24,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両904_Kenpal',
      number: '904-1001',
      logger_id: 904,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:51:29.000000Z',
      updated_at: '2021-11-30T12:46:36.000000Z',
      deleted_at: null,
    },
    {
      id: 25,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両905_Kenpal',
      number: '905-1001',
      logger_id: 905,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:51:48.000000Z',
      updated_at: '2021-12-01T13:13:12.000000Z',
      deleted_at: null,
    },
    {
      id: 26,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両906_Kenpal',
      number: '906-1001',
      logger_id: 906,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:52:06.000000Z',
      updated_at: '2021-08-23T13:00:40.000000Z',
      deleted_at: null,
    },
    {
      id: 27,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両907_Kenpal',
      number: '907-1001',
      logger_id: 907,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:52:23.000000Z',
      updated_at: '2021-08-23T13:00:48.000000Z',
      deleted_at: null,
    },
    {
      id: 28,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両908_Kenpal',
      number: '908-1001',
      logger_id: 908,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:52:43.000000Z',
      updated_at: '2021-07-27T10:23:25.000000Z',
      deleted_at: null,
    },
    {
      id: 29,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両909_Kenpal',
      number: '909-1001',
      logger_id: 909,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:52:58.000000Z',
      updated_at: '2021-04-20T08:09:13.000000Z',
      deleted_at: null,
    },
    {
      id: 30,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両910_Kenpal',
      number: '910-1001',
      logger_id: 910,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:53:18.000000Z',
      updated_at: '2021-07-08T06:05:35.000000Z',
      deleted_at: null,
    },
    {
      id: 31,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両911_Kenpal',
      number: '911-1001',
      logger_id: 911,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:54:03.000000Z',
      updated_at: '2021-11-29T07:39:05.000000Z',
      deleted_at: null,
    },
    {
      id: 32,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両912_Kenpal',
      number: '912-1001',
      logger_id: 912,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:54:19.000000Z',
      updated_at: '2021-11-24T09:12:10.000000Z',
      deleted_at: null,
    },
    {
      id: 33,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両913_Kenpal',
      number: '913-1001',
      logger_id: 913,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:54:33.000000Z',
      updated_at: '2021-11-24T09:35:08.000000Z',
      deleted_at: null,
    },
    {
      id: 34,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両914_Kenpal',
      number: '914-1001',
      logger_id: 914,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:54:51.000000Z',
      updated_at: '2021-11-19T07:57:44.000000Z',
      deleted_at: null,
    },
    {
      id: 35,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両915_Kenpal',
      number: '915-1001',
      logger_id: 915,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:55:06.000000Z',
      updated_at: '2021-12-09T04:38:01.000000Z',
      deleted_at: null,
    },
    {
      id: 36,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両916_Kenpal',
      number: '916-1001',
      logger_id: 916,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:55:22.000000Z',
      updated_at: '2021-07-20T11:02:59.000000Z',
      deleted_at: null,
    },
    {
      id: 37,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両917_Kenpal',
      number: '917-1001',
      logger_id: 917,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:55:35.000000Z',
      updated_at: '2021-04-22T14:30:27.000000Z',
      deleted_at: null,
    },
    {
      id: 38,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両918_Kenpal',
      number: '918-1001',
      logger_id: 918,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:56:00.000000Z',
      updated_at: '2021-06-14T12:17:26.000000Z',
      deleted_at: null,
    },
    {
      id: 39,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: 25,
      user: {
        id: 25,
        company_id: 1,
        role: 3,
        name: 'テストドライバー19',
        email: 'test_user19@gmail.com',
        created_at: '2020-08-29 13:13:59',
        updated_at: '2020-08-29 13:13:59',
        deleted_at: null,
        email_new: null,
        confirmation_token: '',
        bounce_flag: 3,
        mobile_model: null,
        mobile_sim: null,
        tel: null,
        unavailable: 0,
        announcement_flag: 0,
        last_login: null,
      },
      name: '車両919_Kenpal',
      number: '919-1001',
      logger_id: 919,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:56:14.000000Z',
      updated_at: '2021-06-01T11:34:01.000000Z',
      deleted_at: null,
    },
    {
      id: 40,
      branch_id: 5,
      branch_name: 'Kenpal支店',
      user_id: null,
      user: null,
      name: '車両920_Kenpal',
      number: '920-1001',
      logger_id: 920,
      car_type: 6,
      only_app: 0,
      created_at: '2020-09-15T12:56:27.000000Z',
      updated_at: '2021-10-26T10:21:57.000000Z',
      deleted_at: null,
    },
  ],
}
