import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  state: {
    user: null,
    id: null,
    name: null,
    email: null,
    role: null,
    company_id: null,
    company_plan: null,
    access_token: null,
    refresh_token: null,
    show_drive_monitor: null,
    show_trajectory: null,
    use_announce: null,
    use_analyze: null,
    is_2fa: null,
    plus_flag: null,
    show_caution_driver: null,
    operator: null,
    fleet: null,
    violation_map: null,
    hide_menu: null,
  },
  mutations: {
    UPDATE_USER(state, user) {
      state.user = user
    },
    UPDATE_USER_INFO(state, { id, name, email, role, companyId, companyPlan, showDriveMonitor, showTrajectory, useAnnounce, useAnalyze, is2FA, plusFlag, operator, fleet, violationMap, hideMenu }) {
      state.id = id
      state.name = name
      state.email = email
      state.role = role
      state.company_id = companyId
      state.company_plan = companyPlan
      state.show_drive_monitor = showDriveMonitor
      state.show_trajectory = showTrajectory
      state.use_announce = useAnnounce
      state.use_analyze = useAnalyze
      state.is_2fa = is2FA
      state.plus_flag = plusFlag
      state.operator = operator
      state.fleet = fleet
      state.violation_map = violationMap
      state.hide_menu = hideMenu
    },
    UPDATE_ACCESS_TOKEN(state, { accessToken, refreshToken }) {
      state.access_token = accessToken
      state.refresh_token = refreshToken
    },
    INITIALIZE_USER(state) {
      state.user = null
      state.email = null
      state.role = null
      state.access_token = null
      state.refresh_token = null
      state.show_drive_monitor = null
      state.show_trajectory = null
      state.use_announce = null
      state.use_analyze = null
      state.is_2fa = null
      state.plus_flag = null
      state.show_caution_driver = null
      state.operator = null
      state.fleet = null
      state.violation_map = null
      state.hide_menu = null
    },
    UPDATE_COMPANY_INFO(state, company) {
      if (company) {
        if (company.show_caution_driver != null) {
          state.show_caution_driver = company.show_caution_driver
        }
      } else {
        state.show_caution_driver = null
      }
    },
  },
  actions: {
    // ユーザーの所属会社情報をAPIで取得し、ストアを更新する
    FETCH_COMPANY_INFO({ commit }) {
      Vue.prototype.$api.send('get', 'company')
        .then(response => {
          commit('UPDATE_COMPANY_INFO', response)
        })
        .catch(error => {
          console.warn(`会社情報取得失敗`, error)
        })
    },
  },
  getters: {
    id: state => state.id,
    company_id: state => state.company_id,
    company_plan: state => state.company_plan,
    user: state => state.user,
    name: state => state.name,
    email: state => state.email,
    role: state => state.role,
    access_token: state => state.access_token,
    refresh_token: state => state.refresh_token,
    show_drive_monitor: state => state.show_drive_monitor,
    show_trajectory: state => state.show_trajectory,
    use_announce: state => state.use_announce,
    use_analyze: state => state.use_analyze,
    is_2fa: state => state.is_2fa,
    plus_flag: state => state.plus_flag,
    show_caution_driver: state => state.show_caution_driver,
    operator: state => state.operator,
    fleet: state => state.fleet,
    violation_map: state => state.violation_map,
    hide_menu: state => state.hide_menu,
  },
}
