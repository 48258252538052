export default {
  user: [
    {
      id: '31392',
      user_id: 35,
      user_name: 'Hajime Kasahara',
      date: '2021-12-11',
      driving_time: '1258',
      distance: '2680',
      violation_count: 0,
      regulation_num: '15',
      announce_count: 0,
      compliance_rate: 100,
    },
    {
      id: '31391',
      user_id: 40,
      user_name: '梅澤琢磨',
      date: '2021-12-09',
      driving_time: '24',
      distance: '0',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 0,
      compliance_rate: 100,
    },
    {
      id: '31379,31380',
      user_id: 4,
      user_name: '佐野 岳史',
      date: '2021-12-06',
      driving_time: '65',
      distance: '0',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 0,
      compliance_rate: 100,
    },
    {
      id: '31378',
      user_id: 36,
      user_name: '飯田 貫',
      date: '2021-12-05',
      driving_time: '3553',
      distance: '22712',
      violation_count: 2,
      regulation_num: '54',
      announce_count: 1,
      compliance_rate: 96.3,
    },
    {
      id: '31377',
      user_id: 4,
      user_name: '佐野 岳史',
      date: '2021-12-03',
      driving_time: '267',
      distance: '0',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 0,
      compliance_rate: 100,
    },
    {
      id: '31374,31376',
      user_id: 6,
      user_name: 'テストドライバー2',
      date: '2021-12-02',
      driving_time: '6176',
      distance: '30540',
      violation_count: 5,
      regulation_num: '130',
      announce_count: 4,
      compliance_rate: 96.15,
    },
    {
      id: '31375,31373',
      user_id: 36,
      user_name: '飯田 貫',
      date: '2021-12-02',
      driving_time: '6186',
      distance: '29778',
      violation_count: 3,
      regulation_num: '133',
      announce_count: 5,
      compliance_rate: 97.74,
    },
    {
      id: '31371',
      user_id: 11,
      user_name: 'テストドライバー5',
      date: '2021-12-01',
      driving_time: '2072',
      distance: '16239',
      violation_count: 4,
      regulation_num: '60',
      announce_count: 2,
      compliance_rate: 93.33,
    },
    {
      id: '31372,31370',
      user_id: 36,
      user_name: '飯田 貫',
      date: '2021-12-01',
      driving_time: '1722',
      distance: '12187',
      violation_count: 1,
      regulation_num: '42',
      announce_count: 1,
      compliance_rate: 97.62,
    },
    {
      id: '31368,31365',
      user_id: 6,
      user_name: 'テストドライバー2',
      date: '2021-11-30',
      driving_time: '2517',
      distance: '19379',
      violation_count: 6,
      regulation_num: '74',
      announce_count: 1,
      compliance_rate: 91.89,
    },
    {
      id: '31369',
      user_id: 10,
      user_name: 'テストドライバー4',
      date: '2021-11-30',
      driving_time: '1323',
      distance: '8525',
      violation_count: 0,
      regulation_num: '27',
      announce_count: 0,
      compliance_rate: 100,
    },
    {
      id: '31366',
      user_id: 11,
      user_name: 'テストドライバー5',
      date: '2021-11-30',
      driving_time: '1191',
      distance: '11040',
      violation_count: 5,
      regulation_num: '42',
      announce_count: 1,
      compliance_rate: 88.1,
    },
    {
      id: '31367,31364',
      user_id: 36,
      user_name: '飯田 貫',
      date: '2021-11-30',
      driving_time: '2523',
      distance: '19671',
      violation_count: 5,
      regulation_num: '69',
      announce_count: 1,
      compliance_rate: 92.75,
    },
    {
      id: '31359,31357,31358,31356',
      user_id: 17,
      user_name: 'テストドライバー11',
      date: '2021-11-29',
      driving_time: null,
      distance: null,
      violation_count: 0,
      regulation_num: null,
      announce_count: 0,
      compliance_rate: 100,
    },
    {
      id: '31362,31355',
      user_id: 36,
      user_name: '飯田 貫',
      date: '2021-11-29',
      driving_time: '1633',
      distance: '2469',
      violation_count: 1,
      regulation_num: '10',
      announce_count: 0,
      compliance_rate: 90,
    },
    {
      id: '31361,31363,31360',
      user_id: 40,
      user_name: '梅澤琢磨',
      date: '2021-11-29',
      driving_time: '2439',
      distance: '909',
      violation_count: 0,
      regulation_num: '2',
      announce_count: 0,
      compliance_rate: 100,
    },
    {
      id: '31354,31352',
      user_id: 6,
      user_name: 'テストドライバー2',
      date: '2021-11-28',
      driving_time: '3758',
      distance: '18559',
      violation_count: 7,
      regulation_num: '102',
      announce_count: 5,
      compliance_rate: 93.14,
    },
    {
      id: '31351,31347,31348',
      user_id: 11,
      user_name: 'テストドライバー5',
      date: '2021-11-28',
      driving_time: '1679',
      distance: '4791',
      violation_count: 2,
      regulation_num: '40',
      announce_count: 0,
      compliance_rate: 95,
    },
    {
      id: '31350,31346,31344,31353,31349,31345',
      user_id: 36,
      user_name: '飯田 貫',
      date: '2021-11-28',
      driving_time: '7485',
      distance: '29136',
      violation_count: 4,
      regulation_num: '181',
      announce_count: 7,
      compliance_rate: 97.79,
    },
    {
      id: '31343,31342',
      user_id: 40,
      user_name: '梅澤琢磨',
      date: '2021-11-27',
      driving_time: '3844',
      distance: '867',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 1,
      compliance_rate: 100,
    },
  ],
  vehicle:
    [
      {
        id: '11747',
        vehicle_name: '1234567',
        vehicle_id: 1,
        branch_id: 1,
        branch_name: '本社',
        date: '2021-07-10',
        driving_time: '8',
        distance: '0',
        violation_count: 0,
        announce_count: 0,
        regulation_num: '0',
        compliance_rate: 100,
      },
      {
        id: '11643',
        vehicle_name: '1234567',
        vehicle_id: 1,
        branch_id: 1,
        branch_name: '本社',
        date: '2021-07-08',
        driving_time: null,
        distance: null,
        violation_count: 0,
        announce_count: 0,
        regulation_num: null,
        compliance_rate: 100,
      },
      {
        id: '3963',
        vehicle_name: '1234567',
        vehicle_id: 1,
        branch_id: 1,
        branch_name: '本社',
        date: '2021-07-01',
        driving_time: '16',
        distance: '1',
        violation_count: 0,
        announce_count: 0,
        regulation_num: '0',
        compliance_rate: 100,
      },
      {
        id:
          '3266,3267,3269,3270,3271,3272,3279,3280,3285,3286,3287,3288,3289,3291,3292,3297,3298,3299,3300',
        vehicle_name: '1234567',
        vehicle_id: 1,
        branch_id: 1,
        branch_name: '本社',
        date: '2021-06-25',
        driving_time: '74266',
        distance: '235',
        violation_count: 0,
        announce_count: 0,
        regulation_num: '14',
        compliance_rate: 100,
      },
      {
        id: '3263',
        vehicle_name: '1234567',
        vehicle_id: 1,
        branch_id: 1,
        branch_name: '本社',
        date: '2021-06-10',
        driving_time: '13238',
        distance: '111707',
        violation_count: 2,
        announce_count: 0,
        regulation_num: '296',
        compliance_rate: 99.32,
      },
      {
        id: '1885',
        vehicle_name: '1234567',
        vehicle_id: 1,
        branch_id: 1,
        branch_name: '本社',
        date: '2021-04-26',
        driving_time: '15',
        distance: '2',
        violation_count: 0,
        announce_count: 0,
        regulation_num: '0',
        compliance_rate: 100,
      },
      {
        id: '1212',
        vehicle_name: '1234567',
        vehicle_id: 1,
        branch_id: 1,
        branch_name: '本社',
        date: '2020-12-08',
        driving_time: '6',
        distance: '0',
        violation_count: 0,
        announce_count: 0,
        regulation_num: '0',
        compliance_rate: 100,
      },
      {
        id: '1208,1209',
        vehicle_name: '1234567',
        vehicle_id: 1,
        branch_id: 1,
        branch_name: '本社',
        date: '2020-12-07',
        driving_time: '42',
        distance: '0',
        violation_count: 0,
        announce_count: 0,
        regulation_num: '0',
        compliance_rate: 100,
      },
      {
        id: '1205,1206',
        vehicle_name: '1234567',
        vehicle_id: 1,
        branch_id: 1,
        branch_name: '本社',
        date: '2020-12-06',
        driving_time: '20',
        distance: '1',
        violation_count: 0,
        announce_count: 0,
        regulation_num: '0',
        compliance_rate: 100,
      },
      {
        id: '22,28',
        vehicle_name: '1234567',
        vehicle_id: 1,
        branch_id: 1,
        branch_name: '本社',
        date: '2020-11-21',
        driving_time: '11822',
        distance: '19844',
        violation_count: 0,
        announce_count: 0,
        regulation_num: '110',
        compliance_rate: 100,
      },
      {
        id: '886',
        vehicle_name: '1234567',
        vehicle_id: 1,
        branch_id: 1,
        branch_name: '本社',
        date: '2020-10-09',
        driving_time: '95',
        distance: '0',
        violation_count: 0,
        announce_count: 0,
        regulation_num: '0',
        compliance_rate: 100,
      },
      {
        id: '868',
        vehicle_name: '1234567',
        vehicle_id: 1,
        branch_id: 1,
        branch_name: '本社',
        date: '2020-10-01',
        driving_time: '11',
        distance: null,
        violation_count: 0,
        announce_count: 0,
        regulation_num: null,
        compliance_rate: 100,
      },
      {
        id: '807',
        vehicle_name: '1234567',
        vehicle_id: 1,
        branch_id: 1,
        branch_name: '本社',
        date: '2020-09-27',
        driving_time: '2',
        distance: '0',
        violation_count: 0,
        announce_count: 0,
        regulation_num: '0',
        compliance_rate: 100,
      },
      {
        id: '549,553,557',
        vehicle_name: '1234567',
        vehicle_id: 1,
        branch_id: 1,
        branch_name: '本社',
        date: '2020-09-15',
        driving_time: '4444',
        distance: '54664',
        violation_count: 26,
        announce_count: 0,
        regulation_num: null,
        compliance_rate: 100,
      },
      {
        id: '470,476,479',
        vehicle_name: '1234567',
        vehicle_id: 1,
        branch_id: 1,
        branch_name: '本社',
        date: '2020-09-14',
        driving_time: '9677',
        distance: '43485',
        violation_count: 8,
        announce_count: 0,
        regulation_num: null,
        compliance_rate: 100,
      },
      {
        id: '465',
        vehicle_name: '1234567',
        vehicle_id: 1,
        branch_id: 1,
        branch_name: '本社',
        date: '2020-09-12',
        driving_time: '3937',
        distance: '7874',
        violation_count: 1,
        announce_count: 0,
        regulation_num: null,
        compliance_rate: 100,
      },
      {
        id: '422,441,450,455',
        vehicle_name: '1234567',
        vehicle_id: 1,
        branch_id: 1,
        branch_name: '本社',
        date: '2020-09-11',
        driving_time: '2154',
        distance: '1849',
        violation_count: 0,
        announce_count: 0,
        regulation_num: null,
        compliance_rate: 100,
      },
      {
        id: '366,367,374,379,384,392,394,407,412',
        vehicle_name: '1234567',
        vehicle_id: 1,
        branch_id: 1,
        branch_name: '本社',
        date: '2020-09-10',
        driving_time: '47058975',
        distance: '5029',
        violation_count: 1,
        announce_count: 0,
        regulation_num: null,
        compliance_rate: 100,
      },
      {
        id: '339,341',
        vehicle_name: '1234567',
        vehicle_id: 1,
        branch_id: 1,
        branch_name: '本社',
        date: '2020-09-08',
        driving_time: '17231094',
        distance: null,
        violation_count: 9,
        announce_count: 0,
        regulation_num: null,
        compliance_rate: 100,
      },
      {
        id: '275,280,291,298,308,313',
        vehicle_name: '1234567',
        vehicle_id: 1,
        branch_id: 1,
        branch_name: '本社',
        date: '2020-09-07',
        driving_time: '827',
        distance: null,
        violation_count: 0,
        announce_count: 0,
        regulation_num: null,
        compliance_rate: 100,
      },
    ],
}
