export default [
  {
    id: 1,
    company_id: 1,
    company_name: 'Genext',
    name: '本社',
    address: '新横浜',
    comment: '',
    created_at: '2020-06-29T07:09:49.000000Z',
    updated_at: '2020-06-29T07:09:49.000000Z',
    deleted_at: null,
  },
  {
    id: 3,
    company_id: 1,
    company_name: 'Genext',
    name: '神奈川支店',
    address: '神奈川県横浜市',
    comment: null,
    created_at: '2020-06-29T07:16:07.000000Z',
    updated_at: '2020-06-29T07:16:07.000000Z',
    deleted_at: null,
  },
  {
    id: 4,
    company_id: 1,
    company_name: 'Genext',
    name: 'Genext 支店',
    address: null,
    comment: null,
    created_at: '2020-09-11T07:08:57.000000Z',
    updated_at: '2020-09-11T07:08:57.000000Z',
    deleted_at: null,
  },
  {
    id: 5,
    company_id: 1,
    company_name: 'Genext',
    name: 'Kenpal支店',
    address: null,
    comment: null,
    created_at: '2020-09-15T12:47:27.000000Z',
    updated_at: '2020-09-15T12:47:27.000000Z',
    deleted_at: null,
  },
  {
    id: 36,
    company_id: 1,
    company_name: 'Genext',
    name: 'UNCOVER_渋谷',
    address: '東京都渋谷区',
    comment: 'アンカバーテスト作成',
    created_at: '2021-11-10T08:56:12.000000Z',
    updated_at: '2021-11-10T08:56:12.000000Z',
    deleted_at: null,
  },
]
