var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    [
      _c("a-layout-content", [
        _c("div", { class: _vm.$style.layout }, [
          _c("div", { class: _vm.$style.left }, [
            _c("p", { class: _vm.$style.msgText }, [_vm._v("WELCOME TO")]),
            _c("div", { class: _vm.$style.logoContainer }, [
              _c("img", {
                class: _vm.$style.logo,
                attrs: {
                  src: "resources/images/logo-white.png",
                  alt: "AI-Contact"
                }
              }),
              _c("img", {
                class: _vm.$style.logoText,
                attrs: {
                  src: "resources/images/logoText.png",
                  alt: "AI-Contact"
                }
              })
            ])
          ]),
          _c("div", { class: _vm.$style.right }, [
            _c(
              "div",
              { class: _vm.$style.loginFormContainer },
              [_c("router-view")],
              1
            ),
            _c("div", { class: _vm.$style.rightFooter }, [_c("cui-footer")], 1)
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }