export default [
  {
    id: 1,
    branch_id: 1,
    branch_name: '本社',
    user_id: 1,
    user: {
      id: 1,
      company_id: 1,
      role: 1,
      name: 'テスト太郎',
      email: 'test@test.com',
      created_at: '2020-06-29 16:09:50',
      updated_at: '2021-11-11 17:21:38',
      deleted_at: null,
      email_new: null,
      confirmation_token: null,
      bounce_flag: 99,
      mobile_model: null,
      mobile_sim: null,
      unavailable: 0,
      announcement_flag: 1,
      last_login: '2021-11-11 17:21:38',
    },
    name: '1234567',
    number: '1234567',
    logger_id: 1,
    car_type: 6,
    manufacturer: '',
    model: '',
    inspection_expiration_date: null,
    ownership: 0,
    monthly_lease_fee: null,
    date_purchase: null,
    drive_recorder: 0,

    only_app: 0,
    created_at: '2020-06-29T07:16:21.000000Z',
    updated_at: '2021-07-21T05:51:26.000000Z',
    deleted_at: null,
  },
  {
    id: 2,
    branch_id: 1,
    branch_name: '本社',
    user_id: null,
    user: null,
    name: '管理 い',
    number: 'い1111111',
    logger_id: 2,
    car_type: 6,
    manufacturer: '',
    model: '',
    inspection_expiration_date: null,
    ownership: 0,
    monthly_lease_fee: null,
    date_purchase: null,
    drive_recorder: 0,
    only_app: 0,
    created_at: '2020-06-29T07:16:28.000000Z',
    updated_at: '2021-10-20T06:50:51.000000Z',
    deleted_at: null,
  },
  {
    id: 3,
    branch_id: 1,
    branch_name: '本社',
    user_id: null,
    user: null,
    name: '管理 う',
    number: 'う1321133',
    logger_id: 3,
    car_type: 6,
    manufacturer: '',
    model: '',
    inspection_expiration_date: null,
    ownership: 0,
    monthly_lease_fee: null,
    date_purchase: null,
    drive_recorder: 0,
    only_app: 0,
    created_at: '2020-06-29T07:16:34.000000Z',
    updated_at: '2020-09-15T12:28:50.000000Z',
    deleted_at: null,
  },
  {
    id: 6,
    branch_id: 1,
    branch_name: '本社',
    user_id: null,
    user: null,
    name: 'ID6',
    number: '666-6666',
    logger_id: 6,
    car_type: 6,
    manufacturer: '',
    model: '',
    inspection_expiration_date: null,
    ownership: 0,
    monthly_lease_fee: null,
    date_purchase: null,
    drive_recorder: 0,
    only_app: 0,
    created_at: '2020-08-29T04:10:29.000000Z',
    updated_at: '2021-08-03T15:07:28.000000Z',
    deleted_at: null,
  },
  {
    id: 7,
    branch_id: 1,
    branch_name: '本社',
    user_id: null,
    user: null,
    name: 'ID7',
    number: '777-7777',
    logger_id: 7,
    car_type: 6,
    manufacturer: '',
    model: '',
    inspection_expiration_date: null,
    ownership: 0,
    monthly_lease_fee: null,
    date_purchase: null,
    drive_recorder: 0,
    only_app: 0,
    created_at: '2020-08-29T04:11:07.000000Z',
    updated_at: '2021-08-05T13:32:13.000000Z',
    deleted_at: null,
  },
  {
    id: 8,
    branch_id: 1,
    branch_name: '本社',
    user_id: null,
    user: null,
    name: 'ID8',
    number: '888-8888',
    logger_id: 8,
    car_type: 6,
    manufacturer: '',
    model: '',
    inspection_expiration_date: null,
    ownership: 0,
    monthly_lease_fee: null,
    date_purchase: null,
    drive_recorder: 0,
    only_app: 0,
    created_at: '2020-08-29T04:11:17.000000Z',
    updated_at: '2021-07-09T07:44:03.000000Z',
    deleted_at: null,
  },
  {
    id: 9,
    branch_id: 1,
    branch_name: '本社',
    user_id: null,
    user: null,
    name: 'ID9',
    number: '999-9999',
    logger_id: 9,
    car_type: 6,
    manufacturer: '',
    model: '',
    inspection_expiration_date: null,
    ownership: 0,
    monthly_lease_fee: null,
    date_purchase: null,
    drive_recorder: 0,
    only_app: 0,
    created_at: '2020-08-29T04:11:27.000000Z',
    updated_at: '2021-07-12T04:21:07.000000Z',
    deleted_at: null,
  },
]
