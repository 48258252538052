import axios from 'axios'
import user from '../store/user'

const BASE_URL = process.env.VUE_APP_API_BASE_URL

export default {
  install: (Vue, options) => {
    Vue.prototype.$api = {
      send: async (method, path, params = null, responseRaw = null, responseType = '') => {
        return new Promise(function(resolve, reject) {
          const data = JSON.parse(JSON.stringify(params))
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.state.access_token
          axios.defaults.headers.common['Accept'] = 'application/json'
          let promise
          if (method === 'get') {
            promise = axios({
              method: method,
              url: BASE_URL + path,
              params: data,
              timeout: 1000 * 60 * 10,
              responseType: responseType,
            })
          } else {
            promise = axios({
              method: method,
              url: BASE_URL + path,
              data,
              timeout: 1000 * 60 * 10,
              responseType: responseType,
            })
          }
          promise.then(response => {
            // console.log(response)s
            if (responseRaw) resolve(response.data)
            else resolve(response.data.data)
          })
            .catch(error => {
              if (error.response.data.status === 'unavailable') {
                alert('こちらのアカウントは停止しています。')
              }
              console.log('transmit error')
              console.log(error.response)
              reject(error.response)
            })
        })
      },
    }
  },
}
