var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.footer }, [
    _c("div", { class: _vm.$style.copyright }, [
      _c("p", [_vm._v("Copyright ©  ジェネクスト株式会社")])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }