export default {
  get({ values }) {
    return [200, { data: [
      {
        id: 1,
        company_id: 1,
        company_name: 'Genext',
        email: 'satoshi_okazaki@genext.co.jp',
        bounce_flag: 0,
        created_at: '2022-03-26T04:10:22.000000Z',
        updated_at: '2022-03-26T04:10:22.000000Z',
      },
    ] }]
  },
}
