var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        class: _vm.settings.isLightTheme
          ? [_vm.$style.navigation, _vm.$style.light]
          : _vm.$style.navigation
      },
      [
        _c(
          "a-menu",
          {
            attrs: {
              slot: "overlay",
              theme: _vm.settings.isLightTheme ? "light" : "dark",
              mode: "vertical",
              selectedKeys: _vm.selectedKeys,
              openKeys: _vm.openKeys
            },
            on: {
              "update:openKeys": function($event) {
                _vm.openKeys = $event
              },
              "update:open-keys": function($event) {
                _vm.openKeys = $event
              },
              click: _vm.handleClick,
              openChange: _vm.handleOpenChange
            },
            slot: "overlay"
          },
          [
            _vm._l(_vm.menuData, function(item) {
              return [
                item.authority >= _vm.role &&
                !item.withoutPlan.includes(_vm.plan) &&
                !item.children &&
                !item.divider &&
                (!item.driveMonitor || _vm.showDriveMonitor) &&
                (!item.hideFleet || !_vm.fleet) &&
                (!item.showOperator || _vm.operator) &&
                (!item.showPlus || _vm.plus) &&
                (!item.showCautionDriver || _vm.showCautionDriver)
                  ? _c("item", {
                      key: item.key,
                      attrs: { "menu-info": item, styles: _vm.$style }
                    })
                  : _vm._e(),
                item.authority >= _vm.role && item.divider
                  ? _c("a-menu-divider", { key: item.key })
                  : _vm._e(),
                item.authority >= _vm.role && item.children
                  ? _c("sub-menu", {
                      key: item.key,
                      attrs: { "menu-info": item, styles: _vm.$style }
                    })
                  : _vm._e()
              ]
            })
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }