export default {
  get({ params }) {
    if (params.page) {
      return [200, {
        data: [
          {
            id: '31392',
            user_id: 35,
            user_name: 'Hajime Kasahara',
            date: '2021-12-11',
            driving_time: '1258',
            distance: '2680',
            violation_count: 0,
            regulation_num: '15',
            announce_count: 0,
            compliance_rate: 100,
          },
          {
            id: '31391',
            user_id: 40,
            user_name: '梅澤琢磨',
            date: '2021-12-09',
            driving_time: '24',
            distance: '0',
            violation_count: 0,
            regulation_num: '0',
            announce_count: 0,
            compliance_rate: 100,
          },
          {
            id: '31380,31379',
            user_id: 4,
            user_name: '佐野 岳史',
            date: '2021-12-06',
            driving_time: '65',
            distance: '0',
            violation_count: 0,
            regulation_num: '0',
            announce_count: 0,
            compliance_rate: 100,
          },
          {
            id: '31378',
            user_id: 36,
            user_name: '飯田 貫',
            date: '2021-12-05',
            driving_time: '3553',
            distance: '22712',
            violation_count: 2,
            regulation_num: '54',
            announce_count: 1,
            compliance_rate: 96.3,
          },
          {
            id: '31377',
            user_id: 4,
            user_name: '佐野 岳史',
            date: '2021-12-03',
            driving_time: '267',
            distance: '0',
            violation_count: 0,
            regulation_num: '0',
            announce_count: 0,
            compliance_rate: 100,
          },
          {
            id: '31376,31374',
            user_id: 6,
            user_name: 'テストドライバー2',
            date: '2021-12-02',
            driving_time: '6176',
            distance: '30540',
            violation_count: 5,
            regulation_num: '130',
            announce_count: 4,
            compliance_rate: 96.15,
          },
          {
            id: '31375,31373',
            user_id: 36,
            user_name: '飯田 貫',
            date: '2021-12-02',
            driving_time: '6186',
            distance: '29778',
            violation_count: 3,
            regulation_num: '133',
            announce_count: 5,
            compliance_rate: 97.74,
          },
          {
            id: '31371',
            user_id: 11,
            user_name: 'テストドライバー5',
            date: '2021-12-01',
            driving_time: '2072',
            distance: '16239',
            violation_count: 4,
            regulation_num: '60',
            announce_count: 2,
            compliance_rate: 93.33,
          },
          {
            id: '31372,31370',
            user_id: 36,
            user_name: '飯田 貫',
            date: '2021-12-01',
            driving_time: '1722',
            distance: '12187',
            violation_count: 1,
            regulation_num: '42',
            announce_count: 1,
            compliance_rate: 97.62,
          },
          {
            id: '31368,31365',
            user_id: 6,
            user_name: 'テストドライバー2',
            date: '2021-11-30',
            driving_time: '2517',
            distance: '19379',
            violation_count: 6,
            regulation_num: '74',
            announce_count: 1,
            compliance_rate: 91.89,
          },
          {
            id: '31369',
            user_id: 10,
            user_name: 'テストドライバー4',
            date: '2021-11-30',
            driving_time: '1323',
            distance: '8525',
            violation_count: 0,
            regulation_num: '27',
            announce_count: 0,
            compliance_rate: 100,
          },
          {
            id: '31366',
            user_id: 11,
            user_name: 'テストドライバー5',
            date: '2021-11-30',
            driving_time: '1191',
            distance: '11040',
            violation_count: 5,
            regulation_num: '42',
            announce_count: 1,
            compliance_rate: 88.1,
          },
          {
            id: '31364,31367',
            user_id: 36,
            user_name: '飯田 貫',
            date: '2021-11-30',
            driving_time: '2523',
            distance: '19671',
            violation_count: 5,
            regulation_num: '69',
            announce_count: 1,
            compliance_rate: 92.75,
          },
          {
            id: '31358,31356,31359,31357',
            user_id: 17,
            user_name: 'テストドライバー11',
            date: '2021-11-29',
            driving_time: null,
            distance: null,
            violation_count: 0,
            regulation_num: null,
            announce_count: 0,
            compliance_rate: 100,
          },
          {
            id: '31355,31362',
            user_id: 36,
            user_name: '飯田 貫',
            date: '2021-11-29',
            driving_time: '1633',
            distance: '2469',
            violation_count: 1,
            regulation_num: '10',
            announce_count: 0,
            compliance_rate: 90,
          },
          {
            id: '31361,31363,31360',
            user_id: 40,
            user_name: '梅澤琢磨',
            date: '2021-11-29',
            driving_time: '2439',
            distance: '909',
            violation_count: 0,
            regulation_num: '2',
            announce_count: 0,
            compliance_rate: 100,
          },
          {
            id: '31352,31354',
            user_id: 6,
            user_name: 'テストドライバー2',
            date: '2021-11-28',
            driving_time: '3758',
            distance: '18559',
            violation_count: 7,
            regulation_num: '102',
            announce_count: 5,
            compliance_rate: 93.14,
          },
          {
            id: '31348,31351,31347',
            user_id: 11,
            user_name: 'テストドライバー5',
            date: '2021-11-28',
            driving_time: '1679',
            distance: '4791',
            violation_count: 2,
            regulation_num: '40',
            announce_count: 0,
            compliance_rate: 95,
          },
          {
            id: '31345,31350,31346,31344,31353,31349',
            user_id: 36,
            user_name: '飯田 貫',
            date: '2021-11-28',
            driving_time: '7485',
            distance: '29136',
            violation_count: 4,
            regulation_num: '181',
            announce_count: 7,
            compliance_rate: 97.79,
          },
          {
            id: '31343,31342',
            user_id: 40,
            user_name: '梅澤琢磨',
            date: '2021-11-27',
            driving_time: '3844',
            distance: '867',
            violation_count: 0,
            regulation_num: '0',
            announce_count: 1,
            compliance_rate: 100,
          },
        ],
        links: {
          first: 'api/drive_data/user?page=1',
          last: 'api/drive_data/user?page=44',
          prev: null,
          next: 'api/drive_data/user?page=2',
        },
        meta: {
          current_page: 1,
          from: 1,
          last_page: 44,
          path: 'api/drive_data/user',
          per_page: 20,
          to: 20,
          total: 872,
        },
      }]
    }
    return [200, {
      data: {
        id: 77,
        company_id: 1,
        company_name: 'Genext',
        company_plan: 0,
        branches: [
          {
            id: 1,
            company_id: 1,
            name: '本社',
            address: '新横浜',
            comment: '',
            created_at: '2020-06-29 16:09:49',
            updated_at: '2020-06-29 16:09:49',
            deleted_at: null,
          },
          {
            id: 3,
            company_id: 1,
            name: '神奈川支店',
            address: '神奈川県横浜市',
            comment: null,
            created_at: '2020-06-29 16:16:07',
            updated_at: '2020-06-29 16:16:07',
            deleted_at: null,
          },
          {
            id: 4,
            company_id: 1,
            name: 'Genext 支店',
            address: null,
            comment: null,
            created_at: '2020-09-11 16:08:57',
            updated_at: '2020-09-11 16:08:57',
            deleted_at: null,
          },
          {
            id: 5,
            company_id: 1,
            name: 'Kenpal支店',
            address: null,
            comment: null,
            created_at: '2020-09-15 21:47:27',
            updated_at: '2020-09-15 21:47:27',
            deleted_at: null,
          },
          {
            id: 36,
            company_id: 1,
            name: 'UNCOVER_渋谷',
            address: '東京都渋谷区',
            comment: 'アンカバーテスト作成',
            created_at: '2021-11-10 17:56:12',
            updated_at: '2021-11-10 17:56:12',
            deleted_at: null,
          },
        ],
        vehicle: null,
        role: 0,
        name: 'Satoshi Okazaki',
        email: 'satoshi_okazaki@genext.co.jp',
        mobile_model: null,
        mobile_sim: null,
        tel: null,
        confirmation: 0,
        announcement_flag: 0,
        last_login: '2021-12-13 17:05:31',
        unavailable: 0,
        created_at: '2021-08-07T08:59:44.000000Z',
        updated_at: '2021-12-13T08:05:31.000000Z',
        deleted_at: null,
        show_drive_monitor: 1,
        show_trajectory: 1,
        use_announce: 1,
        use_analyze: 1,
        use_raw_download: 1,
      },
    }]
  },
}
