export default [
  [
    {
      id: 1,
      company_id: 1,
      company_name: 'Genext',
      company_plan: 0,
      branches: [
        {
          id: 1,
          company_id: 1,
          name: '本社',
          address: '新横浜',
          comment: '',
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:09:49',
          updated_at: '2020-06-29 16:09:49',
          deleted_at: null,
        },
        {
          id: 3,
          company_id: 1,
          name: '神奈川支店',
          address: '神奈川県横浜市',
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:16:07',
          updated_at: '2020-06-29 16:16:07',
          deleted_at: null,
        },
        {
          id: 4,
          company_id: 1,
          name: 'Genext 支店',
          address: null,
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-09-11 16:08:57',
          updated_at: '2020-09-11 16:08:57',
          deleted_at: null,
        },
        {
          id: 5,
          company_id: 1,
          name: 'Kenpal支店',
          address: null,
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-09-15 21:47:27',
          updated_at: '2020-09-15 21:47:27',
          deleted_at: null,
        },
        {
          id: 36,
          company_id: 1,
          name: 'UNCOVER_渋谷',
          address: '東京都渋谷区',
          comment: 'アンカバーテスト作成',
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2021-11-10 17:56:12',
          updated_at: '2021-11-10 17:56:12',
          deleted_at: null,
        },
      ],
      vehicle: {
        id: 1,
        branch_id: 1,
        branch_name: '本社',
        name: '1234567',
        number: '1234567',
        logger_id: 1,
      },
      role: 1,
      name: 'テスト太郎',
      email: 'test@test.com',
      mobile_model: null,
      mobile_sim: null,
      confirmation: 0,
      announcement_flag: 1,
      last_login: '2021-11-11 17:21:38',
      unavailable: 0,
      created_at: '2020-06-29T07:09:50.000000Z',
      updated_at: '2021-11-11T08:21:38.000000Z',
      deleted_at: null,
      show_drive_monitor: 1,
      show_trajectory: 1,
      use_announce: 1,
      use_analyze: 1,
      use_raw_download: 1,
    },
    {
      id: 35,
      company_id: 1,
      company_name: 'Genext',
      company_plan: 0,
      branches: [
        {
          id: 3,
          company_id: 1,
          name: '神奈川支店',
          address: '神奈川県横浜市',
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:16:07',
          updated_at: '2020-06-29 16:16:07',
          deleted_at: null,
          pivot: {
            user_id: 35,
            branch_id: 3,
          },
        },
        {
          id: 1,
          company_id: 1,
          name: '本社',
          address: '新横浜',
          comment: '',
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:09:49',
          updated_at: '2020-06-29 16:09:49',
          deleted_at: null,
          pivot: {
            user_id: 35,
            branch_id: 1,
          },
        },
        {
          id: 4,
          company_id: 1,
          name: 'Genext 支店',
          address: null,
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-09-11 16:08:57',
          updated_at: '2020-09-11 16:08:57',
          deleted_at: null,
          pivot: {
            user_id: 35,
            branch_id: 4,
          },
        },
      ],
      vehicle: {
        id: 17,
        branch_id: 4,
        branch_name: 'Genext 支店',
        name: '車両7_genext',
        number: '車両7_genext',
        logger_id: 16,
      },
      role: 3,
      name: 'Hajime Kasahara',
      email: 'test_genext9@gmail.com',
      mobile_model: null,
      mobile_sim: null,
      confirmation: 0,
      announcement_flag: 1,
      last_login: '2021-12-11 20:02:39',
      unavailable: 0,
      created_at: '2020-08-29T04:13:08.000000Z',
      updated_at: '2021-12-11T11:02:39.000000Z',
      deleted_at: null,
      show_drive_monitor: 1,
      show_trajectory: 1,
      use_announce: 1,
      use_analyze: 1,
      use_raw_download: 1,
    },
    {
      id: 34,
      company_id: 1,
      company_name: 'Genext',
      company_plan: 0,
      branches: [
        {
          id: 3,
          company_id: 1,
          name: '神奈川支店',
          address: '神奈川県横浜市',
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:16:07',
          updated_at: '2020-06-29 16:16:07',
          deleted_at: null,
          pivot: {
            user_id: 34,
            branch_id: 3,
          },
        },
        {
          id: 1,
          company_id: 1,
          name: '本社',
          address: '新横浜',
          comment: '',
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:09:49',
          updated_at: '2020-06-29 16:09:49',
          deleted_at: null,
          pivot: {
            user_id: 34,
            branch_id: 1,
          },
        },
        {
          id: 4,
          company_id: 1,
          name: 'Genext 支店',
          address: null,
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-09-11 16:08:57',
          updated_at: '2020-09-11 16:08:57',
          deleted_at: null,
          pivot: {
            user_id: 34,
            branch_id: 4,
          },
        },
      ],
      vehicle: null,
      role: 3,
      name: 'カサハラハジメ',
      email: 'test_genext8@gmail.com',
      mobile_model: null,
      mobile_sim: null,
      confirmation: 0,
      announcement_flag: 1,
      last_login: null,
      unavailable: 0,
      created_at: '2020-08-29T04:13:08.000000Z',
      updated_at: '2021-07-13T09:20:38.000000Z',
      deleted_at: null,
      show_drive_monitor: 1,
      show_trajectory: 1,
      use_announce: 1,
      use_analyze: 1,
      use_raw_download: 1,
    },
    {
      id: 33,
      company_id: 1,
      company_name: 'Genext',
      company_plan: 0,
      branches: [
        {
          id: 3,
          company_id: 1,
          name: '神奈川支店',
          address: '神奈川県横浜市',
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:16:07',
          updated_at: '2020-06-29 16:16:07',
          deleted_at: null,
          pivot: {
            user_id: 33,
            branch_id: 3,
          },
        },
        {
          id: 1,
          company_id: 1,
          name: '本社',
          address: '新横浜',
          comment: '',
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:09:49',
          updated_at: '2020-06-29 16:09:49',
          deleted_at: null,
          pivot: {
            user_id: 33,
            branch_id: 1,
          },
        },
        {
          id: 4,
          company_id: 1,
          name: 'Genext 支店',
          address: null,
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-09-11 16:08:57',
          updated_at: '2020-09-11 16:08:57',
          deleted_at: null,
          pivot: {
            user_id: 33,
            branch_id: 4,
          },
        },
      ],
      vehicle: null,
      role: 3,
      name: 'かさはらはじめ',
      email: 'test_genext7@gmail.com',
      mobile_model: null,
      mobile_sim: null,
      confirmation: 0,
      announcement_flag: 0,
      last_login: null,
      unavailable: 0,
      created_at: '2020-08-29T04:13:08.000000Z',
      updated_at: '2020-09-17T05:04:38.000000Z',
      deleted_at: null,
      show_drive_monitor: 1,
      show_trajectory: 1,
      use_announce: 1,
      use_analyze: 1,
      use_raw_download: 1,
    },
    {
      id: 32,
      company_id: 1,
      company_name: 'Genext',
      company_plan: 0,
      branches: [
        {
          id: 3,
          company_id: 1,
          name: '神奈川支店',
          address: '神奈川県横浜市',
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:16:07',
          updated_at: '2020-06-29 16:16:07',
          deleted_at: null,
          pivot: {
            user_id: 32,
            branch_id: 3,
          },
        },
        {
          id: 1,
          company_id: 1,
          name: '本社',
          address: '新横浜',
          comment: '',
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:09:49',
          updated_at: '2020-06-29 16:09:49',
          deleted_at: null,
          pivot: {
            user_id: 32,
            branch_id: 1,
          },
        },
        {
          id: 4,
          company_id: 1,
          name: 'Genext 支店',
          address: null,
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-09-11 16:08:57',
          updated_at: '2020-09-11 16:08:57',
          deleted_at: null,
          pivot: {
            user_id: 32,
            branch_id: 4,
          },
        },
      ],
      vehicle: null,
      role: 3,
      name: 'ジェネクストユーザ6',
      email: 'test_genext6@gmail.com',
      mobile_model: null,
      mobile_sim: null,
      confirmation: 0,
      announcement_flag: 0,
      last_login: null,
      unavailable: 0,
      created_at: '2020-08-29T04:13:59.000000Z',
      updated_at: '2020-08-29T04:13:59.000000Z',
      deleted_at: null,
      show_drive_monitor: 1,
      show_trajectory: 1,
      use_announce: 1,
      use_analyze: 1,
      use_raw_download: 1,
    },
    {
      id: 31,
      company_id: 1,
      company_name: 'Genext',
      company_plan: 0,
      branches: [
        {
          id: 3,
          company_id: 1,
          name: '神奈川支店',
          address: '神奈川県横浜市',
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:16:07',
          updated_at: '2020-06-29 16:16:07',
          deleted_at: null,
          pivot: {
            user_id: 31,
            branch_id: 3,
          },
        },
        {
          id: 1,
          company_id: 1,
          name: '本社',
          address: '新横浜',
          comment: '',
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:09:49',
          updated_at: '2020-06-29 16:09:49',
          deleted_at: null,
          pivot: {
            user_id: 31,
            branch_id: 1,
          },
        },
        {
          id: 4,
          company_id: 1,
          name: 'Genext 支店',
          address: null,
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-09-11 16:08:57',
          updated_at: '2020-09-11 16:08:57',
          deleted_at: null,
          pivot: {
            user_id: 31,
            branch_id: 4,
          },
        },
      ],
      vehicle: null,
      role: 3,
      name: 'ジェネクストユーザ5',
      email: 'test_genext5@gmail.com',
      mobile_model: null,
      mobile_sim: null,
      confirmation: 0,
      announcement_flag: 0,
      last_login: null,
      unavailable: 0,
      created_at: '2020-08-29T04:13:08.000000Z',
      updated_at: '2020-08-29T04:15:25.000000Z',
      deleted_at: null,
      show_drive_monitor: 1,
      show_trajectory: 1,
      use_announce: 1,
      use_analyze: 1,
      use_raw_download: 1,
    },
    {
      id: 30,
      company_id: 1,
      company_name: 'Genext',
      company_plan: 0,
      branches: [
        {
          id: 3,
          company_id: 1,
          name: '神奈川支店',
          address: '神奈川県横浜市',
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:16:07',
          updated_at: '2020-06-29 16:16:07',
          deleted_at: null,
          pivot: {
            user_id: 30,
            branch_id: 3,
          },
        },
        {
          id: 1,
          company_id: 1,
          name: '本社',
          address: '新横浜',
          comment: '',
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:09:49',
          updated_at: '2020-06-29 16:09:49',
          deleted_at: null,
          pivot: {
            user_id: 30,
            branch_id: 1,
          },
        },
        {
          id: 4,
          company_id: 1,
          name: 'Genext 支店',
          address: null,
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-09-11 16:08:57',
          updated_at: '2020-09-11 16:08:57',
          deleted_at: null,
          pivot: {
            user_id: 30,
            branch_id: 4,
          },
        },
      ],
      vehicle: null,
      role: 3,
      name: 'ジェネクストユーザ4',
      email: 'test_genext4@gmail.com',
      mobile_model: null,
      mobile_sim: null,
      confirmation: 0,
      announcement_flag: 0,
      last_login: null,
      unavailable: 0,
      created_at: '2020-08-29T04:13:59.000000Z',
      updated_at: '2020-08-29T04:13:59.000000Z',
      deleted_at: null,
      show_drive_monitor: 1,
      show_trajectory: 1,
      use_announce: 1,
      use_analyze: 1,
      use_raw_download: 1,
    },
    {
      id: 29,
      company_id: 1,
      company_name: 'Genext',
      company_plan: 0,
      branches: [
        {
          id: 3,
          company_id: 1,
          name: '神奈川支店',
          address: '神奈川県横浜市',
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:16:07',
          updated_at: '2020-06-29 16:16:07',
          deleted_at: null,
          pivot: {
            user_id: 29,
            branch_id: 3,
          },
        },
        {
          id: 1,
          company_id: 1,
          name: '本社',
          address: '新横浜',
          comment: '',
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:09:49',
          updated_at: '2020-06-29 16:09:49',
          deleted_at: null,
          pivot: {
            user_id: 29,
            branch_id: 1,
          },
        },
        {
          id: 4,
          company_id: 1,
          name: 'Genext 支店',
          address: null,
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-09-11 16:08:57',
          updated_at: '2020-09-11 16:08:57',
          deleted_at: null,
          pivot: {
            user_id: 29,
            branch_id: 4,
          },
        },
      ],
      vehicle: null,
      role: 3,
      name: 'ジェネクストユーザ3',
      email: 'test_genext3@gmail.com',
      mobile_model: null,
      mobile_sim: null,
      confirmation: 0,
      announcement_flag: 0,
      last_login: null,
      unavailable: 0,
      created_at: '2020-08-29T04:13:08.000000Z',
      updated_at: '2020-08-29T04:15:25.000000Z',
      deleted_at: null,
      show_drive_monitor: 1,
      show_trajectory: 1,
      use_announce: 1,
      use_analyze: 1,
      use_raw_download: 1,
    },
    {
      id: 28,
      company_id: 1,
      company_name: 'Genext',
      company_plan: 0,
      branches: [
        {
          id: 3,
          company_id: 1,
          name: '神奈川支店',
          address: '神奈川県横浜市',
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:16:07',
          updated_at: '2020-06-29 16:16:07',
          deleted_at: null,
          pivot: {
            user_id: 28,
            branch_id: 3,
          },
        },
        {
          id: 1,
          company_id: 1,
          name: '本社',
          address: '新横浜',
          comment: '',
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:09:49',
          updated_at: '2020-06-29 16:09:49',
          deleted_at: null,
          pivot: {
            user_id: 28,
            branch_id: 1,
          },
        },
        {
          id: 4,
          company_id: 1,
          name: 'Genext 支店',
          address: null,
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-09-11 16:08:57',
          updated_at: '2020-09-11 16:08:57',
          deleted_at: null,
          pivot: {
            user_id: 28,
            branch_id: 4,
          },
        },
      ],
      vehicle: null,
      role: 3,
      name: 'ジェネクストユーザ2',
      email: 'test_genext2@gmail.com',
      mobile_model: null,
      mobile_sim: null,
      confirmation: 0,
      announcement_flag: 0,
      last_login: null,
      unavailable: 0,
      created_at: '2020-08-29T04:13:59.000000Z',
      updated_at: '2020-08-29T04:18:09.000000Z',
      deleted_at: null,
      show_drive_monitor: 1,
      show_trajectory: 1,
      use_announce: 1,
      use_analyze: 1,
      use_raw_download: 1,
    },
    {
      id: 27,
      company_id: 1,
      company_name: 'Genext',
      company_plan: 0,
      branches: [
        {
          id: 3,
          company_id: 1,
          name: '神奈川支店',
          address: '神奈川県横浜市',
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:16:07',
          updated_at: '2020-06-29 16:16:07',
          deleted_at: null,
          pivot: {
            user_id: 27,
            branch_id: 3,
          },
        },
        {
          id: 1,
          company_id: 1,
          name: '本社',
          address: '新横浜',
          comment: '',
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:09:49',
          updated_at: '2020-06-29 16:09:49',
          deleted_at: null,
          pivot: {
            user_id: 27,
            branch_id: 1,
          },
        },
        {
          id: 4,
          company_id: 1,
          name: 'Genext 支店',
          address: null,
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-09-11 16:08:57',
          updated_at: '2020-09-11 16:08:57',
          deleted_at: null,
          pivot: {
            user_id: 27,
            branch_id: 4,
          },
        },
      ],
      vehicle: null,
      role: 3,
      name: 'ジェネクストユーザ1',
      email: 'test_genext1@gmail.com',
      mobile_model: null,
      mobile_sim: null,
      confirmation: 0,
      announcement_flag: 0,
      last_login: null,
      unavailable: 0,
      created_at: '2020-08-29T04:13:08.000000Z',
      updated_at: '2020-08-29T04:15:25.000000Z',
      deleted_at: null,
      show_drive_monitor: 1,
      show_trajectory: 1,
      use_announce: 1,
      use_analyze: 1,
      use_raw_download: 1,
    },
    {
      id: 69,
      company_id: 1,
      company_name: 'Genext',
      company_plan: 0,
      branches: [
        {
          id: 1,
          company_id: 1,
          name: '本社',
          address: '新横浜',
          comment: '',
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:09:49',
          updated_at: '2020-06-29 16:09:49',
          deleted_at: null,
          pivot: {
            user_id: 69,
            branch_id: 1,
          },
        },
      ],
      vehicle: null,
      role: 3,
      name: '山地ドライバー',
      email: 'ryo.yamaji@studi-ol.com',
      mobile_model: null,
      mobile_sim: null,
      confirmation: 0,
      announcement_flag: 0,
      last_login: null,
      unavailable: 0,
      created_at: '2021-04-13T03:44:47.000000Z',
      updated_at: '2021-04-13T03:45:05.000000Z',
      deleted_at: null,
      show_drive_monitor: 1,
      show_trajectory: 1,
      use_announce: 1,
      use_analyze: 1,
      use_raw_download: 1,
    },
    {
      id: 76,
      company_id: 1,
      company_name: 'Genext',
      company_plan: 0,
      branches: [
        {
          id: 1,
          company_id: 1,
          name: '本社',
          address: '新横浜',
          comment: '',
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:09:49',
          updated_at: '2020-06-29 16:09:49',
          deleted_at: null,
          pivot: {
            user_id: 76,
            branch_id: 1,
          },
        },
        {
          id: 3,
          company_id: 1,
          name: '神奈川支店',
          address: '神奈川県横浜市',
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:16:07',
          updated_at: '2020-06-29 16:16:07',
          deleted_at: null,
          pivot: {
            user_id: 76,
            branch_id: 3,
          },
        },
        {
          id: 4,
          company_id: 1,
          name: 'Genext 支店',
          address: null,
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-09-11 16:08:57',
          updated_at: '2020-09-11 16:08:57',
          deleted_at: null,
          pivot: {
            user_id: 76,
            branch_id: 4,
          },
        },
      ],
      vehicle: null,
      role: 3,
      name: 'NOW走行テスト2号',
      email: 'genext.test11@gmail.com',
      mobile_model: null,
      mobile_sim: null,
      confirmation: 0,
      announcement_flag: 1,
      last_login: null,
      unavailable: 0,
      created_at: '2021-07-14T00:31:50.000000Z',
      updated_at: '2021-07-14T00:32:40.000000Z',
      deleted_at: null,
      show_drive_monitor: 1,
      show_trajectory: 1,
      use_announce: 1,
      use_analyze: 1,
      use_raw_download: 1,
    },
    {
      id: 74,
      company_id: 1,
      company_name: 'Genext',
      company_plan: 0,
      branches: [
        {
          id: 4,
          company_id: 1,
          name: 'Genext 支店',
          address: null,
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-09-11 16:08:57',
          updated_at: '2020-09-11 16:08:57',
          deleted_at: null,
          pivot: {
            user_id: 74,
            branch_id: 4,
          },
        },
        {
          id: 1,
          company_id: 1,
          name: '本社',
          address: '新横浜',
          comment: '',
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:09:49',
          updated_at: '2020-06-29 16:09:49',
          deleted_at: null,
          pivot: {
            user_id: 74,
            branch_id: 1,
          },
        },
        {
          id: 3,
          company_id: 1,
          name: '神奈川支店',
          address: '神奈川県横浜市',
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:16:07',
          updated_at: '2020-06-29 16:16:07',
          deleted_at: null,
          pivot: {
            user_id: 74,
            branch_id: 3,
          },
        },
      ],
      vehicle: null,
      role: 3,
      name: 'NOW走行テスト1号',
      email: 'genext.test10@gmail.com',
      mobile_model: null,
      mobile_sim: null,
      confirmation: 0,
      announcement_flag: 1,
      last_login: null,
      unavailable: 0,
      created_at: '2021-07-13T06:31:08.000000Z',
      updated_at: '2021-07-13T09:15:28.000000Z',
      deleted_at: null,
      show_drive_monitor: 1,
      show_trajectory: 1,
      use_announce: 1,
      use_analyze: 1,
      use_raw_download: 1,
    },
    {
      id: 4,
      company_id: 1,
      company_name: 'Genext',
      company_plan: 0,
      branches: [
        {
          id: 1,
          company_id: 1,
          name: '本社',
          address: '新横浜',
          comment: '',
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-06-29 16:09:49',
          updated_at: '2020-06-29 16:09:49',
          deleted_at: null,
          pivot: {
            user_id: 4,
            branch_id: 1,
          },
        },
        {
          id: 4,
          company_id: 1,
          name: 'Genext 支店',
          address: null,
          comment: null,
          alcohol_check_type: 0,
          alcohol_check_default_alcohol_detector: 0,
          alcohol_check_default_drinking: 0,
          alcohol_check_default_roll_call_type: 0,
          created_at: '2020-09-11 16:08:57',
          updated_at: '2020-09-11 16:08:57',
          deleted_at: null,
          pivot: {
            user_id: 4,
            branch_id: 4,
          },
        },
      ],
      vehicle: {
        id: 12,
        branch_id: 4,
        branch_name: 'Genext 支店',
        name: '車両2_genext',
        number: '車両2_genext',
        logger_id: 11,
      },
      role: 3,
      name: '佐野 岳史',
      email: 'takeshi_sano@genext.co.jp',
      mobile_model: null,
      mobile_sim: null,
      confirmation: 0,
      announcement_flag: 0,
      last_login: '2021-12-23 18:47:48',
      unavailable: 0,
      created_at: '2020-06-29T07:12:40.000000Z',
      updated_at: '2021-12-23T09:47:48.000000Z',
      deleted_at: null,
      show_drive_monitor: 1,
      show_trajectory: 1,
      use_announce: 1,
      use_analyze: 1,
      use_raw_download: 1,
    },
  ],
]
