export default {
  drive_data: [
    {
      id: 11747,
      vehicle_id: 1,
      vehicle_name: '1234567',
      branch_id: 1,
      branch_name: '本社',
      logger_id: 1,
      user_id: 4,
      user_name: '佐野 岳史',
      date: '2021-07-10',
      time_start: '2021-07-10 03:53:00',
      time_end: '2021-07-10 03:53:19',
      analyze_status: 0,
      driving_time: '8',
      distance: '0',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 0,
      compliance_rate: 100,
      deleted_at: null,
      created_at: '2021-07-10 03:53:00',
      updated_at: '2021-07-10 03:53:29',
    },
    {
      id: 3963,
      vehicle_id: 1,
      vehicle_name: '1234567',
      branch_id: 1,
      branch_name: '本社',
      logger_id: 1,
      user_id: 7,
      user_name: '山地 瞭',
      date: '2021-07-01',
      time_start: '2021-07-01 16:12:29',
      time_end: '2021-07-01 16:13:13',
      analyze_status: 0,
      driving_time: '16',
      distance: '1',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 0,
      compliance_rate: 100,
      deleted_at: null,
      created_at: '2021-07-01 16:12:39',
      updated_at: '2021-07-01 16:13:17',
    },
    {
      id: 3300,
      vehicle_id: 1,
      vehicle_name: '1234567',
      branch_id: 1,
      branch_name: '本社',
      logger_id: 1,
      user_id: 7,
      user_name: '山地 瞭',
      date: '2021-06-25',
      time_start: '2021-06-25 19:09:11',
      time_end: '2021-06-26 14:51:22',
      analyze_status: 0,
      driving_time: '70859',
      distance: '34',
      violation_count: 0,
      regulation_num: '1',
      announce_count: 0,
      compliance_rate: 100,
      deleted_at: null,
      created_at: '2021-06-25 19:09:22',
      updated_at: '2021-06-26 14:57:02',
    },
    {
      id: 3299,
      vehicle_id: 1,
      vehicle_name: '1234567',
      branch_id: 1,
      branch_name: '本社',
      logger_id: 1,
      user_id: 7,
      user_name: '山地 瞭',
      date: '2021-06-25',
      time_start: '2021-06-25 15:17:29',
      time_end: '2021-06-25 15:17:59',
      analyze_status: 0,
      driving_time: '10',
      distance: '2',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 0,
      compliance_rate: 100,
      deleted_at: null,
      created_at: '2021-06-25 15:17:39',
      updated_at: '2021-06-25 15:18:02',
    },
    {
      id: 3298,
      vehicle_id: 1,
      vehicle_name: '1234567',
      branch_id: 1,
      branch_name: '本社',
      logger_id: 1,
      user_id: 7,
      user_name: '山地 瞭',
      date: '2021-06-25',
      time_start: '2021-06-25 15:16:47',
      time_end: '2021-06-25 15:17:11',
      analyze_status: 0,
      driving_time: '9',
      distance: '1',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 0,
      compliance_rate: 100,
      deleted_at: null,
      created_at: '2021-06-25 15:16:56',
      updated_at: '2021-06-25 15:17:17',
    },
    {
      id: 3297,
      vehicle_id: 1,
      vehicle_name: '1234567',
      branch_id: 1,
      branch_name: '本社',
      logger_id: 1,
      user_id: 7,
      user_name: '山地 瞭',
      date: '2021-06-25',
      time_start: '2021-06-25 15:15:08',
      time_end: '2021-06-25 15:16:39',
      analyze_status: 0,
      driving_time: '56',
      distance: '2',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 0,
      compliance_rate: 100,
      deleted_at: null,
      created_at: '2021-06-25 15:15:18',
      updated_at: '2021-06-25 15:16:43',
    },
    {
      id: 3292,
      vehicle_id: 1,
      vehicle_name: '1234567',
      branch_id: 1,
      branch_name: '本社',
      logger_id: 1,
      user_id: 7,
      user_name: '山地 瞭',
      date: '2021-06-25',
      time_start: '2021-06-25 14:45:53',
      time_end: '2021-06-25 14:47:16',
      analyze_status: 0,
      driving_time: '84',
      distance: '0',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 0,
      compliance_rate: 100,
      deleted_at: null,
      created_at: '2021-06-25 14:46:02',
      updated_at: '2021-06-25 14:47:17',
    },
    {
      id: 3291,
      vehicle_id: 1,
      vehicle_name: '1234567',
      branch_id: 1,
      branch_name: '本社',
      logger_id: 1,
      user_id: 7,
      user_name: '山地 瞭',
      date: '2021-06-25',
      time_start: '2021-06-25 14:45:21',
      time_end: '2021-06-25 14:45:31',
      analyze_status: 0,
      driving_time: '11',
      distance: '0',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 0,
      compliance_rate: 100,
      deleted_at: null,
      created_at: '2021-06-25 14:45:30',
      updated_at: '2021-06-25 14:45:32',
    },
    {
      id: 3289,
      vehicle_id: 1,
      vehicle_name: '1234567',
      branch_id: 1,
      branch_name: '本社',
      logger_id: 1,
      user_id: 7,
      user_name: '山地 瞭',
      date: '2021-06-25',
      time_start: '2021-06-25 14:42:49',
      time_end: '2021-06-25 14:42:52',
      analyze_status: 0,
      driving_time: '4',
      distance: '0',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 0,
      compliance_rate: 100,
      deleted_at: null,
      created_at: '2021-06-25 14:42:53',
      updated_at: '2021-06-25 14:42:53',
    },
    {
      id: 3288,
      vehicle_id: 1,
      vehicle_name: '1234567',
      branch_id: 1,
      branch_name: '本社',
      logger_id: 1,
      user_id: 7,
      user_name: '山地 瞭',
      date: '2021-06-25',
      time_start: '2021-06-25 14:29:55',
      time_end: '2021-06-25 14:37:42',
      analyze_status: 0,
      driving_time: '464',
      distance: '1',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 0,
      compliance_rate: 100,
      deleted_at: null,
      created_at: '2021-06-25 14:30:04',
      updated_at: '2021-06-25 14:37:43',
    },
    {
      id: 3287,
      vehicle_id: 1,
      vehicle_name: '1234567',
      branch_id: 1,
      branch_name: '本社',
      logger_id: 1,
      user_id: 7,
      user_name: '山地 瞭',
      date: '2021-06-25',
      time_start: '2021-06-25 14:29:07',
      time_end: '2021-06-25 14:29:47',
      analyze_status: 0,
      driving_time: '41',
      distance: '0',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 0,
      compliance_rate: 100,
      deleted_at: null,
      created_at: '2021-06-25 14:29:16',
      updated_at: '2021-06-25 14:29:48',
    },
    {
      id: 3286,
      vehicle_id: 1,
      vehicle_name: '1234567',
      branch_id: 1,
      branch_name: '本社',
      logger_id: 1,
      user_id: 7,
      user_name: '山地 瞭',
      date: '2021-06-25',
      time_start: '2021-06-25 14:06:58',
      time_end: '2021-06-25 14:15:20',
      analyze_status: 0,
      driving_time: '497',
      distance: '5',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 0,
      compliance_rate: 100,
      deleted_at: null,
      created_at: '2021-06-25 14:07:09',
      updated_at: '2021-06-25 14:15:20',
    },
    {
      id: 3285,
      vehicle_id: 1,
      vehicle_name: '1234567',
      branch_id: 1,
      branch_name: '本社',
      logger_id: 1,
      user_id: 7,
      user_name: '山地 瞭',
      date: '2021-06-25',
      time_start: '2021-06-25 13:55:50',
      time_end: '2021-06-25 13:56:15',
      analyze_status: 0,
      driving_time: '9',
      distance: '1',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 0,
      compliance_rate: 100,
      deleted_at: null,
      created_at: '2021-06-25 13:56:01',
      updated_at: '2021-06-25 13:56:16',
    },
    {
      id: 3280,
      vehicle_id: 1,
      vehicle_name: '1234567',
      branch_id: 1,
      branch_name: '本社',
      logger_id: 1,
      user_id: 7,
      user_name: '山地 瞭',
      date: '2021-06-25',
      time_start: '2021-06-25 13:03:31',
      time_end: '2021-06-25 13:14:25',
      analyze_status: 0,
      driving_time: '578',
      distance: '93',
      violation_count: 0,
      regulation_num: '11',
      announce_count: 0,
      compliance_rate: 100,
      deleted_at: null,
      created_at: '2021-06-25 13:03:40',
      updated_at: '2021-06-25 13:14:29',
    },
    {
      id: 3279,
      vehicle_id: 1,
      vehicle_name: '1234567',
      branch_id: 1,
      branch_name: '本社',
      logger_id: 1,
      user_id: 7,
      user_name: '山地 瞭',
      date: '2021-06-25',
      time_start: '2021-06-25 12:41:53',
      time_end: '2021-06-25 13:03:24',
      analyze_status: 0,
      driving_time: '1281',
      distance: '41',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 0,
      compliance_rate: 100,
      deleted_at: null,
      created_at: '2021-06-25 12:42:03',
      updated_at: '2021-06-25 13:03:25',
    },
    {
      id: 3272,
      vehicle_id: 1,
      vehicle_name: '1234567',
      branch_id: 1,
      branch_name: '本社',
      logger_id: 1,
      user_id: 7,
      user_name: '山地 瞭',
      date: '2021-06-25',
      time_start: '2021-06-25 11:59:48',
      time_end: '2021-06-25 12:00:13',
      analyze_status: 0,
      driving_time: '10',
      distance: '5',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 0,
      compliance_rate: 100,
      deleted_at: null,
      created_at: '2021-06-25 11:59:59',
      updated_at: '2021-06-25 12:00:17',
    },
    {
      id: 3271,
      vehicle_id: 1,
      vehicle_name: '1234567',
      branch_id: 1,
      branch_name: '本社',
      logger_id: 1,
      user_id: 7,
      user_name: '山地 瞭',
      date: '2021-06-25',
      time_start: '2021-06-25 11:57:50',
      time_end: '2021-06-25 11:58:13',
      analyze_status: 0,
      driving_time: '24',
      distance: '0',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 0,
      compliance_rate: 100,
      deleted_at: null,
      created_at: '2021-06-25 11:58:00',
      updated_at: '2021-06-25 11:58:14',
    },
    {
      id: 3270,
      vehicle_id: 1,
      vehicle_name: '1234567',
      branch_id: 1,
      branch_name: '本社',
      logger_id: 1,
      user_id: 7,
      user_name: '山地 瞭',
      date: '2021-06-25',
      time_start: '2021-06-25 11:57:06',
      time_end: '2021-06-25 11:57:23',
      analyze_status: 0,
      driving_time: '18',
      distance: '2',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 0,
      compliance_rate: 100,
      deleted_at: null,
      created_at: '2021-06-25 11:57:15',
      updated_at: '2021-06-25 11:57:24',
    },
    {
      id: 3269,
      vehicle_id: 1,
      vehicle_name: '1234567',
      branch_id: 1,
      branch_name: '本社',
      logger_id: 1,
      user_id: 7,
      user_name: '山地 瞭',
      date: '2021-06-25',
      time_start: '2021-06-25 11:53:58',
      time_end: '2021-06-25 11:55:25',
      analyze_status: 0,
      driving_time: '61',
      distance: '30',
      violation_count: 0,
      regulation_num: '2',
      announce_count: 0,
      compliance_rate: 100,
      deleted_at: null,
      created_at: '2021-06-25 11:54:08',
      updated_at: '2021-06-25 11:55:27',
    },
    {
      id: 3267,
      vehicle_id: 1,
      vehicle_name: '1234567',
      branch_id: 1,
      branch_name: '本社',
      logger_id: 1,
      user_id: 7,
      user_name: '山地 瞭',
      date: '2021-06-25',
      time_start: '2021-06-25 11:49:10',
      time_end: '2021-06-25 11:53:36',
      analyze_status: 0,
      driving_time: '242',
      distance: '7',
      violation_count: 0,
      regulation_num: '0',
      announce_count: 0,
      compliance_rate: 100,
      deleted_at: null,
      created_at: '2021-06-25 11:49:20',
      updated_at: '2021-06-25 11:53:42',
    },
  ],
}
