import store from '@/store'
import router from '@/router'
import axios from 'axios'
import moment from 'moment'

export default {
  install: (Vue, options) => {
    Vue.prototype.$auth = {
      login: (username, pass) => {
        return new Promise(function(resolve, reject) {
          axios
            .post(process.env.VUE_APP_OAUTH_URL, {
              grant_type: 'password',
              client_id: process.env.VUE_APP_CLIENT_ID,
              client_secret: process.env.VUE_APP_CLIENT_SECRET,
              username: username,
              password: pass,
              scope: '',
            })
            .then(response => {
              if (response.data.user_id) {
                router.push('/2fa/' + response.data.user_id)
                resolve(1)
              } else {
                const accessToken = response.data.access_token
                const refreshToken = response.data.refresh_token
                localStorage.setItem('app.user.accessToken', accessToken)
                localStorage.setItem('app.user.refreshToken', refreshToken)
                localStorage.setItem('app.user.lastGetAccessToken', moment().format('YYYY-MM-DD'))
                store.commit('UPDATE_ACCESS_TOKEN', { accessToken, refreshToken })
                Vue.prototype.$auth.userInfo()
                router.push('/')
                resolve(1)
              }
            })
            .catch(error => {
              reject(error.response)
            })
        })
      },
      two_fa: (userId, code) => {
        return new Promise(function(resolve, reject) {
          axios
            .post(process.env.VUE_APP_2FA_URL, {
              user_id: userId,
              code: code,
            })
            .then(response => {
              if (response.data.code !== 0) {
                router.push('/2fa/' + userId)
                resolve(1)
              } else {
                const accessToken = response.data.data.access_token
                const refreshToken = response.data.data.refresh_token
                localStorage.setItem('app.user.accessToken', accessToken)
                localStorage.setItem('app.user.refreshToken', refreshToken)
                localStorage.setItem('app.user.lastGetAccessToken', moment().format('YYYY-MM-DD'))
                store.commit('UPDATE_ACCESS_TOKEN', { accessToken, refreshToken })
                Vue.prototype.$auth.userInfo()
                router.push('/')
                resolve(1)
              }
            })
            .catch(error => {
              reject(error.response)
            })
        })
      },
      refreshToken: () => {
        let refreshToken = store.getters.refresh_token
        if (!refreshToken) {
          refreshToken = localStorage.getItem('app.user.refreshToken')
        }
        axios
          .post(process.env.VUE_APP_REFRESH_URL, {
            grant_type: 'refresh_token',
            client_id: process.env.VUE_APP_CLIENT_ID,
            client_secret: process.env.VUE_APP_CLIENT_SECRET,
            refresh_token: refreshToken,
            scope: '',
          })
          .then(response => {
            const accessToken = response.data.access_token
            const refreshToken = response.data.refresh_token
            localStorage.setItem('app.user.accessToken', accessToken)
            localStorage.setItem('app.user.refreshToken', refreshToken)
            localStorage.setItem('app.user.lastGetAccessToken', moment().format('YYYY-MM-DD'))
            store.commit('UPDATE_ACCESS_TOKEN', { accessToken, refreshToken })
            router.go({ path: router.currentRoute.path, force: true })
            console.log(response.data)
          })
      },
      logout: () => {
        return new Promise(function(resolve, reject) {
          const result = Vue.prototype.$api.send('post', 'logout')
          result.then(function(res) {
            localStorage.removeItem('app.user.accessToken')
            localStorage.removeItem('app.user.refreshToken')
            localStorage.removeItem('app.user.lastGetAccessToken')
            store.commit('INITIALIZE_USER')
            resolve(1)
          })
            // eslint-disable-next-line
            .catch(error => {
              Vue.prototype.$notification['error']({
                message: 'ログイン情報をうまく取得できなかったためログアウトします。',
              })
              localStorage.removeItem('app.user.accessToken')
              localStorage.removeItem('app.user.refreshToken')
              localStorage.removeItem('app.user.lastGetAccessToken')
              store.commit('INITIALIZE_USER')
              router.push('/user/login')
            })
        })
      },
      userInfo: () => {
        // console.log('method call: userInfo')
        const result = Vue.prototype.$api.send('get', 'user')
        result.then(function(res) {
          console.log('user info updated', res)
          const id = res.id
          const name = res.name
          const email = res.email
          const role = res.role
          const companyId = res.company_id
          const companyPlan = res.company_plan
          const showDriveMonitor = res.show_drive_monitor
          const showTrajectory = res.show_trajectory
          const useAnnounce = res.use_announce
          const useAnalyze = res.use_analyze
          const is2FA = res.is_google2fa
          const plusFlag = res.plus_flag
          const operator = res.operator_flag
          const fleet = res.fleet_flag
          const violationMap = res.use_violation_map
          const hideMenu = res.hide_menu
          store.commit('UPDATE_USER_INFO', { id, name, email, role, companyId, companyPlan, showDriveMonitor, showTrajectory, useAnnounce, useAnalyze, is2FA, plusFlag, operator, fleet, violationMap, hideMenu })
          if (res.role === 0) {
            // Vue.prototype.$message.destroy()
            // Vue.prototype.$message.info({
            //   content: res.company_id + ': 所属会社を' + res.company_name + 'にしています。',
            //   duration: 0,
            // })
          }
          if (res.role <= 2) {
            // メニュー項目に表示する通知バッジの更新
            store.dispatch('notification/fetchAll')
          }
          console.log(res)
        })
          .catch(error => {
            if (error.status === 401) {
              Vue.prototype.$notification['error']({
                message: 'ログイン情報をうまく取得できなかったためログアウトします。',
                description: '再度ログインし直してください。',
              })
              localStorage.removeItem('app.user.accessToken')
              localStorage.removeItem('app.user.refreshToken')
              localStorage.removeItem('app.user.lastGetAccessToken')
              store.commit('INITIALIZE_USER')
              router.push('/user/login')
            }
          })
      },
      // vue router に使おうと思ったが、promise の使い勝手が悪いので今は使っていない
      authCheck: () => {
        return new Promise(function(resolve, reject) {
          console.log('authCheck called')
          if (store.state.user.user) {
            resolve(1)
          } else if (localStorage.getItem('app.user.accessToken') && localStorage.getItem('app.user.refreshToken')) {
            const accessToken = localStorage.getItem('app.user.accessToken')
            const refreshToken = localStorage.getItem('app.user.refreshToken')
            store.commit('UPDATE_ACCESS_TOKEN', { accessToken, refreshToken })
            const userData = Vue.prototype.$api.send('get', 'user')
            userData.then(function(res) {
              const name = res.name
              if (name) {
                store.commit('UPDATE_USER', name)
                resolve(1)
              } else {
                store.commit('INITIALIZE_USER')
                localStorage.removeItem('app.user.accessToken')
                localStorage.removeItem('app.user.refreshToken')
                resolve(0)
              }
            })
          } else {
            resolve(0)
          }
        })
      },
      // logout: async () => {
      //   router.push('/user/login')
      //   await auth.signOut()
      // },
    }
    // auth.onAuthStateChanged(user => {
    //   store.commit('UPDATE_USER', { user })
    // })
  },
}
