export default {
  get({ values }) {
    return [200, {
      data: [
        {
          id: 1,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: {
            id: 1,
            branch_id: 1,
            branch_name: '本社',
            name: '1234567',
            number: '1234567',
            logger_id: 1,
          },
          role: 1,
          name: 'テスト太郎',
          email: 'test@test.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: '2021-11-11 17:21:38',
          unavailable: 0,
          created_at: '2020-06-29T07:09:50.000000Z',
          updated_at: '2021-11-11T08:21:38.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 4,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: {
            id: 12,
            branch_id: 4,
            branch_name: 'Genext 支店',
            name: '車両2_genext',
            number: '車両2_genext',
            logger_id: 11,
          },
          role: 0,
          name: '佐野 岳史',
          email: 'takeshi_sano@genext.co.jp',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: '2021-12-09 15:08:42',
          unavailable: 0,
          created_at: '2020-06-29T07:12:40.000000Z',
          updated_at: '2021-12-09T06:08:42.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 5,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
              pivot: {
                user_id: 5,
                branch_id: 5,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'テストドライバー',
          email: 'test_user@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: '2021-10-28 13:42:15',
          unavailable: 0,
          created_at: '2020-06-29T07:17:50.000000Z',
          updated_at: '2021-10-28T04:42:15.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 6,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
              pivot: {
                user_id: 6,
                branch_id: 5,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'テストドライバー2',
          email: 'test_user2@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: '2021-12-02 13:43:37',
          unavailable: 0,
          created_at: '2020-06-29T07:25:52.000000Z',
          updated_at: '2021-12-02T04:43:37.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 7,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: null,
          role: 0,
          name: '山地 瞭',
          email: 'ryo_yamaji@genext.co.jp',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: '2021-11-25 19:09:25',
          unavailable: 0,
          created_at: '2020-07-07T10:43:06.000000Z',
          updated_at: '2021-11-25T10:09:25.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 8,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: null,
          role: 0,
          name: '笠原 一',
          email: 'hajime_kasahara@genext.co.jp',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: '2021-12-11 20:37:16',
          unavailable: 0,
          created_at: '2020-08-21T07:44:04.000000Z',
          updated_at: '2021-12-11T11:37:16.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 9,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
              pivot: {
                user_id: 9,
                branch_id: 5,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'テストドライバー3',
          email: 'test_user3@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: null,
          unavailable: 0,
          created_at: '2020-08-29T04:13:08.000000Z',
          updated_at: '2021-07-10T08:39:00.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 10,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
              pivot: {
                user_id: 10,
                branch_id: 5,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'テストドライバー4',
          email: 'test_user4@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: '2021-11-30 21:24:20',
          unavailable: 0,
          created_at: '2020-08-29T04:13:59.000000Z',
          updated_at: '2021-11-30T12:24:20.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 11,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
              pivot: {
                user_id: 11,
                branch_id: 5,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'テストドライバー5',
          email: 'test_user5@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: '2021-12-01 21:38:33',
          unavailable: 0,
          created_at: '2020-08-29T04:13:08.000000Z',
          updated_at: '2021-12-01T12:38:33.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 12,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
              pivot: {
                user_id: 12,
                branch_id: 5,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'テストドライバー6',
          email: 'test_user6@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: null,
          unavailable: 0,
          created_at: '2020-08-29T04:13:59.000000Z',
          updated_at: '2021-07-13T11:26:28.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 13,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
              pivot: {
                user_id: 13,
                branch_id: 5,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'テストドライバー7',
          email: 'test_user7@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: null,
          unavailable: 0,
          created_at: '2020-08-29T04:13:08.000000Z',
          updated_at: '2021-07-13T11:26:55.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 14,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
              pivot: {
                user_id: 14,
                branch_id: 5,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'テストドライバー8',
          email: 'test_user8@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-08-29T04:13:59.000000Z',
          updated_at: '2020-08-29T04:13:59.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 15,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
              pivot: {
                user_id: 15,
                branch_id: 5,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'テストドライバー9',
          email: 'test_user9@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-08-29T04:13:08.000000Z',
          updated_at: '2020-08-29T04:15:25.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 16,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
              pivot: {
                user_id: 16,
                branch_id: 5,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'テストドライバー10',
          email: 'test_user10@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-08-29T04:13:08.000000Z',
          updated_at: '2020-08-29T04:15:25.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 17,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
              pivot: {
                user_id: 17,
                branch_id: 5,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'テストドライバー11',
          email: 'test_user11@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: '2021-11-29 16:35:05',
          unavailable: 0,
          created_at: '2020-08-29T04:13:59.000000Z',
          updated_at: '2021-11-29T07:35:05.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 18,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
              pivot: {
                user_id: 18,
                branch_id: 5,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'テストドライバー12',
          email: 'test_user12@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: '2021-11-24 18:11:57',
          unavailable: 0,
          created_at: '2020-08-29T04:13:08.000000Z',
          updated_at: '2021-11-24T09:11:57.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 19,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
              pivot: {
                user_id: 19,
                branch_id: 5,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'テストドライバー13',
          email: 'test_user13@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: '2021-11-24 18:33:07',
          unavailable: 0,
          created_at: '2020-08-29T04:13:59.000000Z',
          updated_at: '2021-11-24T09:33:07.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 20,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
              pivot: {
                user_id: 20,
                branch_id: 5,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'テストドライバー14',
          email: 'test_user14@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: '2021-11-19 16:56:49',
          unavailable: 0,
          created_at: '2020-08-29T04:13:08.000000Z',
          updated_at: '2021-11-19T07:56:49.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 21,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
              pivot: {
                user_id: 21,
                branch_id: 5,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'テストドライバー15',
          email: 'test_user15@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-08-29T04:13:59.000000Z',
          updated_at: '2020-08-29T04:13:59.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 22,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
              pivot: {
                user_id: 22,
                branch_id: 5,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'テストドライバー16',
          email: 'test_user16@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-08-29T04:13:59.000000Z',
          updated_at: '2020-08-29T04:13:59.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 23,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
              pivot: {
                user_id: 23,
                branch_id: 5,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'テストドライバー17',
          email: 'test_user17@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-08-29T04:13:59.000000Z',
          updated_at: '2020-08-29T04:13:59.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 24,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
              pivot: {
                user_id: 24,
                branch_id: 5,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'テストドライバー18',
          email: 'test_user18@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-08-29T04:13:59.000000Z',
          updated_at: '2020-08-29T04:13:59.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 25,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
              pivot: {
                user_id: 25,
                branch_id: 5,
              },
            },
          ],
          vehicle: {
            id: 39,
            branch_id: 5,
            branch_name: 'Kenpal支店',
            name: '車両919_Kenpal',
            number: '919-1001',
            logger_id: 919,
          },
          role: 3,
          name: 'テストドライバー19',
          email: 'test_user19@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-08-29T04:13:59.000000Z',
          updated_at: '2020-08-29T04:13:59.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 26,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
              pivot: {
                user_id: 26,
                branch_id: 5,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'テストドライバー20',
          email: 'test_user20@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: null,
          unavailable: 0,
          created_at: '2020-08-29T04:13:59.000000Z',
          updated_at: '2021-08-02T01:53:45.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 27,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
              pivot: {
                user_id: 27,
                branch_id: 3,
              },
            },
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
              pivot: {
                user_id: 27,
                branch_id: 1,
              },
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
              pivot: {
                user_id: 27,
                branch_id: 4,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'ジェネクストユーザ1',
          email: 'test_genext1@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-08-29T04:13:08.000000Z',
          updated_at: '2020-08-29T04:15:25.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 28,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
              pivot: {
                user_id: 28,
                branch_id: 3,
              },
            },
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
              pivot: {
                user_id: 28,
                branch_id: 1,
              },
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
              pivot: {
                user_id: 28,
                branch_id: 4,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'ジェネクストユーザ2',
          email: 'test_genext2@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-08-29T04:13:59.000000Z',
          updated_at: '2020-08-29T04:18:09.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 29,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
              pivot: {
                user_id: 29,
                branch_id: 3,
              },
            },
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
              pivot: {
                user_id: 29,
                branch_id: 1,
              },
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
              pivot: {
                user_id: 29,
                branch_id: 4,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'ジェネクストユーザ3',
          email: 'test_genext3@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-08-29T04:13:08.000000Z',
          updated_at: '2020-08-29T04:15:25.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 30,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
              pivot: {
                user_id: 30,
                branch_id: 3,
              },
            },
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
              pivot: {
                user_id: 30,
                branch_id: 1,
              },
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
              pivot: {
                user_id: 30,
                branch_id: 4,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'ジェネクストユーザ4',
          email: 'test_genext4@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-08-29T04:13:59.000000Z',
          updated_at: '2020-08-29T04:13:59.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 31,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
              pivot: {
                user_id: 31,
                branch_id: 3,
              },
            },
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
              pivot: {
                user_id: 31,
                branch_id: 1,
              },
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
              pivot: {
                user_id: 31,
                branch_id: 4,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'ジェネクストユーザ5',
          email: 'test_genext5@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-08-29T04:13:08.000000Z',
          updated_at: '2020-08-29T04:15:25.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 32,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
              pivot: {
                user_id: 32,
                branch_id: 3,
              },
            },
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
              pivot: {
                user_id: 32,
                branch_id: 1,
              },
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
              pivot: {
                user_id: 32,
                branch_id: 4,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'ジェネクストユーザ6',
          email: 'test_genext6@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-08-29T04:13:59.000000Z',
          updated_at: '2020-08-29T04:13:59.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 33,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
              pivot: {
                user_id: 33,
                branch_id: 3,
              },
            },
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
              pivot: {
                user_id: 33,
                branch_id: 1,
              },
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
              pivot: {
                user_id: 33,
                branch_id: 4,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'かさはらはじめ',
          email: 'test_genext7@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-08-29T04:13:08.000000Z',
          updated_at: '2020-09-17T05:04:38.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 34,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
              pivot: {
                user_id: 34,
                branch_id: 3,
              },
            },
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
              pivot: {
                user_id: 34,
                branch_id: 1,
              },
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
              pivot: {
                user_id: 34,
                branch_id: 4,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'カサハラハジメ',
          email: 'test_genext8@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: null,
          unavailable: 0,
          created_at: '2020-08-29T04:13:08.000000Z',
          updated_at: '2021-07-13T09:20:38.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 35,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
              pivot: {
                user_id: 35,
                branch_id: 3,
              },
            },
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
              pivot: {
                user_id: 35,
                branch_id: 1,
              },
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
              pivot: {
                user_id: 35,
                branch_id: 4,
              },
            },
          ],
          vehicle: {
            id: 17,
            branch_id: 4,
            branch_name: 'Genext 支店',
            name: '車両7_genext',
            number: '車両7_genext',
            logger_id: 16,
          },
          role: 3,
          name: 'Hajime Kasahara',
          email: 'test_genext9@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: '2021-12-11 20:02:39',
          unavailable: 0,
          created_at: '2020-08-29T04:13:08.000000Z',
          updated_at: '2021-12-11T11:02:39.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 36,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: {
            id: 88,
            branch_id: 3,
            branch_name: '神奈川支店',
            name: 'ロガー番号テスト',
            number: 'hoge',
            logger_id: 10000000080,
          },
          role: 0,
          name: '飯田 貫',
          email: 'iida@kenpalinc.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: '2021-12-05 13:49:11',
          unavailable: 0,
          created_at: '2020-09-07T13:14:01.000000Z',
          updated_at: '2021-12-05T04:49:11.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 37,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: null,
          role: 1,
          name: '新井 猛司',
          email: 'arai@kenpalinc.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-09-07T13:27:21.000000Z',
          updated_at: '2020-09-10T10:44:24.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 38,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: null,
          role: 0,
          name: '渡邊 俊樹',
          email: 'toshiki_watanabe@genext.co.jp',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: '2021-11-25 14:20:15',
          unavailable: 0,
          created_at: '2020-09-11T07:04:39.000000Z',
          updated_at: '2021-11-25T05:20:15.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 40,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: null,
          role: 0,
          name: '梅澤琢磨',
          email: 'umezawa@kenpalinc.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 1,
          announcement_flag: 1,
          last_login: '2021-12-09 13:37:22',
          unavailable: 0,
          created_at: '2020-09-16T01:37:45.000000Z',
          updated_at: '2021-12-09T04:37:22.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 42,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: null,
          role: 1,
          name: '角田 皓一',
          email: 'koichi.sumida@tohmatsu.co.jp',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-09-25T09:53:23.000000Z',
          updated_at: '2020-09-26T22:24:46.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 46,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: null,
          role: 0,
          name: 'Aki Yasuda',
          email: 'aki_yasuda@genext.co.jp',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-10-02T08:57:33.000000Z',
          updated_at: '2020-10-02T08:59:06.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 48,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: null,
          role: 1,
          name: '会社管理者',
          email: 'screen@shot.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-06-29T07:09:50.000000Z',
          updated_at: '2020-06-29T07:09:50.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 53,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: null,
          role: 0,
          name: 'test',
          email: 'takeshisano5000@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 1,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-10-02T11:39:01.000000Z',
          updated_at: '2020-10-02T11:39:01.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 54,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: null,
          role: 0,
          name: '粟野',
          email: 'takashi_awano@genext.co.jp',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: '2021-12-13 16:38:47',
          unavailable: 0,
          created_at: '2020-11-11T06:14:24.000000Z',
          updated_at: '2021-12-13T07:38:47.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 55,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [],
          vehicle: null,
          role: 3,
          name: '横村テスト',
          email: 'ainaka38@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2020-12-09T07:42:26.000000Z',
          updated_at: '2020-12-21T02:13:15.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 69,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
              pivot: {
                user_id: 69,
                branch_id: 1,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: '山地ドライバー',
          email: 'ryo.yamaji@studi-ol.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2021-04-13T03:44:47.000000Z',
          updated_at: '2021-04-13T03:45:05.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 71,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: null,
          role: 0,
          name: 'Adam Azmi',
          email: 'azmi@kenpalinc.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: '2021-11-12 11:13:54',
          unavailable: 0,
          created_at: '2021-06-01T04:50:42.000000Z',
          updated_at: '2021-11-12T02:13:54.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 73,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: null,
          role: 0,
          name: '島田 遥香',
          email: 'haruka_shimada@genext.co.jp',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2021-07-01T07:34:09.000000Z',
          updated_at: '2021-07-01T08:21:48.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 74,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
              pivot: {
                user_id: 74,
                branch_id: 4,
              },
            },
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
              pivot: {
                user_id: 74,
                branch_id: 1,
              },
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
              pivot: {
                user_id: 74,
                branch_id: 3,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'NOW走行テスト1号',
          email: 'genext.test10@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: null,
          unavailable: 0,
          created_at: '2021-07-13T06:31:08.000000Z',
          updated_at: '2021-07-13T09:15:28.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 76,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
              pivot: {
                user_id: 76,
                branch_id: 1,
              },
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
              pivot: {
                user_id: 76,
                branch_id: 3,
              },
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
              pivot: {
                user_id: 76,
                branch_id: 4,
              },
            },
          ],
          vehicle: null,
          role: 3,
          name: 'NOW走行テスト2号',
          email: 'genext.test11@gmail.com',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: null,
          unavailable: 0,
          created_at: '2021-07-14T00:31:50.000000Z',
          updated_at: '2021-07-14T00:32:40.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 77,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: null,
          role: 0,
          name: 'Satoshi Okazaki',
          email: 'satoshi_okazaki@genext.co.jp',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: '2021-12-13 17:05:32',
          unavailable: 0,
          created_at: '2021-08-07T08:59:44.000000Z',
          updated_at: '2021-12-13T08:05:32.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 89,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: {
            id: 133,
            branch_id: 4,
            branch_name: 'Genext 支店',
            name: 'ゼンリン社検証車両',
            number: 'ゼンリン社検証車両',
            logger_id: 10000000110,
          },
          role: 1,
          name: 'ゼンリン 生駒',
          email: 'takashiik3952@zenrin.co.jp',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: '2021-11-17 17:21:29',
          unavailable: 0,
          created_at: '2021-10-12T04:07:29.000000Z',
          updated_at: '2021-11-17T08:21:29.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 90,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: null,
          role: 1,
          name: 'Hironori Oshiro',
          email: 'oshiro@uncover.jp',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: '2021-11-24 15:14:45',
          unavailable: 0,
          created_at: '2021-11-10T01:34:58.000000Z',
          updated_at: '2021-11-24T06:14:45.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 91,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: null,
          role: 1,
          name: 'UN_山城',
          email: 'yamashiro@uncover.jp',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: '2021-11-24 15:05:12',
          unavailable: 0,
          created_at: '2021-11-18T04:47:01.000000Z',
          updated_at: '2021-11-24T06:05:12.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 92,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: null,
          role: 1,
          name: 'UN_新城',
          email: 'r.shinjo@uncover.jp',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: '2021-11-18 14:40:07',
          unavailable: 0,
          created_at: '2021-11-18T05:04:21.000000Z',
          updated_at: '2021-11-18T05:40:07.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 93,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: null,
          role: 1,
          name: 'UN_栗木',
          email: 'kuriki@uncover.jp',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 0,
          last_login: '2021-11-19 20:35:14',
          unavailable: 0,
          created_at: '2021-11-18T05:05:05.000000Z',
          updated_at: '2021-11-19T11:35:14.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 94,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: null,
          role: 0,
          name: '増渕実咲（開発環境）',
          email: 'misaki_masubuchi@genext.co.jp',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 0,
          announcement_flag: 1,
          last_login: '2021-12-13 16:47:18',
          unavailable: 0,
          created_at: '2021-11-25T04:32:07.000000Z',
          updated_at: '2021-12-13T07:47:18.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
        {
          id: 95,
          company_id: 1,
          company_name: 'Genext',
          company_plan: 0,
          branches: [
            {
              id: 1,
              company_id: 1,
              name: '本社',
              address: '新横浜',
              comment: '',
              created_at: '2020-06-29 16:09:49',
              updated_at: '2020-06-29 16:09:49',
              deleted_at: null,
            },
            {
              id: 3,
              company_id: 1,
              name: '神奈川支店',
              address: '神奈川県横浜市',
              comment: null,
              created_at: '2020-06-29 16:16:07',
              updated_at: '2020-06-29 16:16:07',
              deleted_at: null,
            },
            {
              id: 4,
              company_id: 1,
              name: 'Genext 支店',
              address: null,
              comment: null,
              created_at: '2020-09-11 16:08:57',
              updated_at: '2020-09-11 16:08:57',
              deleted_at: null,
            },
            {
              id: 5,
              company_id: 1,
              name: 'Kenpal支店',
              address: null,
              comment: null,
              created_at: '2020-09-15 21:47:27',
              updated_at: '2020-09-15 21:47:27',
              deleted_at: null,
            },
            {
              id: 36,
              company_id: 1,
              name: 'UNCOVER_渋谷',
              address: '東京都渋谷区',
              comment: 'アンカバーテスト作成',
              created_at: '2021-11-10 17:56:12',
              updated_at: '2021-11-10 17:56:12',
              deleted_at: null,
            },
          ],
          vehicle: null,
          role: 1,
          name: 'test sano',
          email: 'takeshi.sano@studi-ol.co.jp',
          mobile_model: null,
          mobile_sim: null,
          confirmation: 1,
          announcement_flag: 0,
          last_login: null,
          unavailable: 0,
          created_at: '2021-11-30T06:39:12.000000Z',
          updated_at: '2021-11-30T06:39:12.000000Z',
          deleted_at: null,
          show_drive_monitor: 1,
          show_trajectory: 1,
          use_announce: 1,
          use_analyze: 1,
          use_raw_download: 1,
        },
      ],
      links: [],
    }]
  },
}
