import { NotificationKey } from '@/store/notification'

export const getMenuData = [
  // マスター項目
  {
    title: '会社一覧',
    key: 'companies',
    url: '/master/companies',
    icon: 'icmn icmn-list',
    authority: 0,
    withoutPlan: [],
    hideFleet: 0,
  },
  {
    divider: true,
  },
  {
    title: 'お知らせ作成',
    key: 'masterNotifications',
    url: '/master/notifications/list',
    icon: 'icmn icmn-volume-increase',
    authority: 0,
    withoutPlan: [],
    hideFleet: 0,
  },
  {
    title: '統計情報',
    key: 'masterStatistics',
    url: '/master/statistics',
    icon: 'fa fa-signal',
    authority: 0,
    withoutPlan: [],
    hideFleet: 0,
  },
  {
    title: '月別ダッシュボード',
    key: 'monthlyDashboard',
    url: '/master/monthly_dashboard',
    icon: 'icmn icmn-pie-chart',
    authority: 0,
    withoutPlan: [],
    hideFleet: 0,
  },
  {
    title: 'ユーザー作成',
    key: 'userStoreForce',
    url: '/users/store/force',
    icon: 'icmn icmn-user',
    authority: 0,
    withoutPlan: [],
    hideFleet: 0,
  },
  {
    title: 'AI-Contact 設定',
    key: 'masterSetting',
    url: '/master/setting',
    icon: 'fa fa-cogs',
    authority: 0,
    withoutPlan: [],
    hideFleet: 0,
  },
  {
    title: 'ログ検索',
    key: 'logSearch',
    url: '/master/history',
    icon: 'fa fa-clock-o',
    authority: 0,
    withoutPlan: [],
    hideFleet: 0,
  },
  {
    title: 'ツール',
    key: 'masterTool',
    url: '/master/tools',
    icon: 'fa fa-magic',
    authority: 0,
    withoutPlan: [],
    hideFleet: 0,
  },
  {
    divider: true,
    authority: 0,
    withoutPlan: [],
    hideFleet: 0,
  },
  // 点呼代行項目
  {
    title: '点呼代行',
    key: 'operatorRollCall',
    url: '/operator/roll_call',
    icon: 'fa fa-phone-square',
    authority: 3,
    withoutPlan: [],
    hideFleet: 0,
    showOperator: 1,
  },
  {
    divider: true,
    authority: 3,
    withoutPlan: [],
    hideFleet: 0,
    showOperator: 1,
  },
  // アドミン項目
  {
    title: 'ダッシュボード',
    key: 'dashboardBusiness',
    url: '/dashboard/business',
    icon: 'icmn icmn-home',
    authority: 2,
    withoutPlan: [],
    hideFleet: 0,
  },
  {
    title: 'お知らせ',
    key: 'notifications',
    url: '/notifications/list',
    icon: 'icmn icmn-bullhorn',
    authority: 3,
    withoutPlan: [],
    hideFleet: 0,
    showAllMenu: 1,
  },
  // 違反マップ
  {
    title: '違反マップ',
    key: 'violationMap',
    url: '/master/trial2',
    icon: 'icmn icmn-map',
    authority: 3,
    withoutPlan: [],
    hideFleet: 0,
    showViolationMap: 1,
  },

  {
    title: '遵守状況確認',
    key: 'dashboard',
    url: '/dashboard',
    icon: 'icmn icmn-trophy',
    authority: 3,
    withoutPlan: [6],
    hideFleet: 1,
  },
  {
    title: '会社情報',
    key: 'company',
    url: '/company',
    icon: 'icmn icmn-office',
    authority: 2,
    withoutPlan: [],
    hideFleet: 0,
  },
  {
    title: 'ユーザー一覧',
    key: 'users',
    url: '/users',
    icon: 'icmn icmn-users',
    authority: 2,
    withoutPlan: [],
    hideFleet: 0,
  },
  {
    title: '車両一覧',
    key: 'vehiclesList',
    url: '/vehicles/list',
    icon: 'fa fa-car',
    authority: 2,
    withoutPlan: [3, 4],
    hideFleet: 0,
  },
  {
    title: '点呼記録簿',
    key: 'alcoholCheck',
    url: '/alcohol_check',
    icon: 'icmn icmn-clipboard',
    authority: 3,
    withoutPlan: [],
    hideFleet: 0,
  },
  {
    title: '運行前点検',
    key: 'inspection',
    url: '/inspection/unconfirmed',
    icon: 'icmn icmn-wrench',
    authority: 3,
    withoutPlan: [],
    hideFleet: 0,
    showPlus: 1,
    notificationKey: NotificationKey.INSPECTION,
  },
  {
    title: '運行計画表',
    key: 'driving_schedule',
    url: '/driving_schedule',
    icon: 'fa fa-calendar-plus-o',
    authority: 3,
    withoutPlan: [],
    hideFleet: 0,
    showPlus: 1,
  },
  {
    title: '車両予約',
    key: 'vehicleReservations',
    url: '/vehicle/reservations',
    icon: 'icmn icmn-truck',
    authority: 3,
    withoutPlan: [],
    hideFleet: 0,
    showPlus: 1,
  },
  {
    title: '過労運転管理',
    key: 'cautionDrivers',
    url: '/caution/drivers/unconfirmed',
    icon: 'icmn icmn-sad',
    authority: 2,
    withoutPlan: [],
    hideFleet: 0,
    showPlus: 1,
    showCautionDriver: 1,
    notificationKey: NotificationKey.CAUTION_DRIVERS,
  },
  {
    title: '運行状況',
    key: 'driveMonitor',
    url: '/drive_monitor',
    icon: 'icmn icmn-map',
    authority: 2,
    withoutPlan: [],
    driveMonitor: 1,
  },
  // ドライバー項目
  {
    title: '乗車',
    key: 'userVehicles',
    url: '/user/vehicles',
    icon: 'fa fa-car',
    authority: 3,
    withoutPlan: [0, 1, 2, 4, 5, 6],
    hideFleet: 0,
  },
  {
    title: '運行履歴',
    key: 'drive_data',
    url: '/drive_data',
    icon: 'icmn icmn-calendar',
    authority: 3,
    withoutPlan: [],
    hideFleet: 0,
  },
  {
    title: 'ドライブリスト',
    key: 'drive_list',
    url: '/drive_list',
    icon: 'icmn icmn-file-text2',
    authority: 3,
    withoutPlan: [],
    hideFleet: 0,
  },
  {
    divider: true,
    authority: 3,
    withoutPlan: [],
    hideFleet: 0,
  },
  {
    title: '設定',
    key: 'cog',
    url: '/setting',
    icon: 'icmn icmn-cog',
    authority: 1,
    withoutPlan: [],
    hideFleet: 0,
  },
  {
    title: 'ヘルプ',
    key: 'manual',
    url: '/manual',
    icon: 'fa fa-question-circle-o',
    authority: 3,
    withoutPlan: [],
    hideFleet: 0,
  },
]
