export default [
  {
    id: 1,
    name: 'Genext',
    comment: '管理用',
    plan: 0,
    max_user: 0,
    owned_number: 30,
    separation_of_days: '00:00:00',
    username: 'testuser',
    password: 'testpass',
    api_vehicle: 1,
    exclude_violations: [
      {
        id: 49,
        company_id: 1,
        violation_type: 1,
        created_at: '2021-11-19 20:15:21',
        updated_at: '2021-11-19 20:15:21',
      },
    ],
    created_at: '2020-06-29T07:09:49.000000Z',
    updated_at: '2021-12-02T08:11:19.000000Z',
    deleted_at: null,
    show_drive_monitor: 1,
    show_trajectory: 1,
    use_analyze: 1,
    use_announce: 1,
    use_raw_download: 1,
    analysis_parameters: {
      id: 14,
      company_id: 1,
      car_type: 6,
      temporary_stop: 7,
      railroad_stop: 15,
      signal: 10,
      national_expressway: 2,
      urban_expressway: 2,
      national_road: 2,
      termination_rate: 15,
      overspeed: 10,
      created_at: '2021-11-11 14:41:16',
      updated_at: '2021-11-12 16:06:24',
    },
  },
]
