export default [{
  id: 1,
  company_id: 1,
  company_name: 'Genext',
  company_plan: 0,
  branches: [
    {
      id: 1,
      company_id: 1,
      name: '本社',
      address: '新横浜',
      comment: '',
      created_at: '2020-06-29 16:09:49',
      updated_at: '2020-06-29 16:09:49',
      deleted_at: null,
    },
    {
      id: 3,
      company_id: 1,
      name: '神奈川支店',
      address: '神奈川県横浜市',
      comment: null,
      created_at: '2020-06-29 16:16:07',
      updated_at: '2020-06-29 16:16:07',
      deleted_at: null,
    },
    {
      id: 4,
      company_id: 1,
      name: 'Genext 支店',
      address: null,
      comment: null,
      created_at: '2020-09-11 16:08:57',
      updated_at: '2020-09-11 16:08:57',
      deleted_at: null,
    },
    {
      id: 5,
      company_id: 1,
      name: 'Kenpal支店',
      address: null,
      comment: null,
      created_at: '2020-09-15 21:47:27',
      updated_at: '2020-09-15 21:47:27',
      deleted_at: null,
    },
    {
      id: 36,
      company_id: 1,
      name: 'UNCOVER_渋谷',
      address: '東京都渋谷区',
      comment: 'アンカバーテスト作成',
      created_at: '2021-11-10 17:56:12',
      updated_at: '2021-11-10 17:56:12',
      deleted_at: null,
    },
  ],
  vehicle: {
    id: 1,
    branch_id: 1,
    branch_name: '本社',
    name: '1234567',
    number: '1234567',
    logger_id: 1,
  },
  role: 1,
  name: 'テスト太郎',
  email: 'test@test.com',
  mobile_model: null,
  mobile_sim: null,
  tel: null,
  confirmation: 0,
  announcement_flag: 1,
  last_login: '2021-11-11 17:21:38',
  unavailable: 0,
  created_at: '2020-06-29T07:09:50.000000Z',
  updated_at: '2021-11-11T08:21:38.000000Z',
  deleted_at: null,
  show_drive_monitor: 1,
  show_trajectory: 1,
  use_announce: 1,
  use_analyze: 1,
  use_raw_download: 1,
}]
