var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-sub-menu",
    _vm._g(
      _vm._b({ key: _vm.menuInfo.key }, "a-sub-menu", _vm.$props, false),
      _vm.$listeners
    ),
    [
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _c("span", { class: _vm.styles.title }, [
          _vm._v(_vm._s(_vm.menuInfo.title))
        ]),
        _vm.menuInfo.icon
          ? _c("i", { class: [_vm.styles.icon, _vm.menuInfo.icon] })
          : _vm._e()
      ]),
      _vm._l(_vm.menuInfo.children, function(item, index) {
        return [
          !item.children && !item.divider
            ? _c("item", {
                key: item.key,
                attrs: { "menu-info": item, styles: _vm.styles }
              })
            : _vm._e(),
          item.divider ? _c("a-menu-divider", { key: index }) : _vm._e(),
          item.children
            ? _c("sub-menu", {
                key: item.key,
                attrs: { "menu-info": item, styles: _vm.styles }
              })
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }