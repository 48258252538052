export default {
  get({ values }) {
    return [200, {
      data: [
        {
          vehicle_id: 21,
          branch_id: 5,
          user_id: 36,
          date: '2021-12-01',
          driving_time: 806,
          distance: 7998,
          r_total: 22,
          r_m_s: 20,
          r_t_s: 2,
          r_r_s: 0,
          r_o: 0,
          r_s_d: 0,
          r_g_total: 12,
          r_g_m_s: 12,
          r_g_t_s: 0,
          r_g_r_s: 0,
          r_g_o: 0,
          r_g_s_d: 0,
          r_n_total: 10,
          r_n_m_s: 8,
          r_n_t_s: 2,
          r_n_r_s: 0,
          r_n_o: 0,
          r_n_s_d: 0,
          violations: {
            data: [
              {
                type: 3,
                date: '2021-12-01',
                time: '12:51:10',
                place: 1,
                hide: 0,
                announced_flag: 0,
              },
            ],
            links: [],
          },
          announcements: {
            data: [
              {
                code: 1,
                code_sub: 2,
              },
            ],
            links: [],
          },
        },
        {
          vehicle_id: 25,
          branch_id: 5,
          user_id: 11,
          date: '2021-12-01',
          driving_time: 2072,
          distance: 16239,
          r_total: 60,
          r_m_s: 53,
          r_t_s: 3,
          r_r_s: 0,
          r_o: 3,
          r_s_d: 1,
          r_g_total: 43,
          r_g_m_s: 42,
          r_g_t_s: 1,
          r_g_r_s: 0,
          r_g_o: 0,
          r_g_s_d: 0,
          r_n_total: 16,
          r_n_m_s: 11,
          r_n_t_s: 2,
          r_n_r_s: 0,
          r_n_o: 3,
          r_n_s_d: 0,
          violations: {
            data: [
              {
                type: 3,
                date: '2021-12-01',
                time: '12:51:10',
                place: 1,
                hide: 0,
                announced_flag: 0,
              },
              {
                type: 3,
                date: '2021-12-01',
                time: '12:52:54',
                place: 1,
                hide: 0,
                announced_flag: 0,
              },
              {
                type: 4,
                date: '2021-12-01',
                time: '12:53:29',
                place: 1,
                hide: 0,
                announced_flag: 0,
              },
              {
                type: 6,
                date: '2021-12-01',
                time: '12:55:14',
                place: 4,
                hide: 0,
                announced_flag: 0,
              },
            ],
            links: [],
          },
          announcements: {
            data: [
              {
                code: 1,
                code_sub: 2,
              },
              {
                code: 1,
                code_sub: 1,
              },
            ],
            links: [],
          },
        },
        {
          vehicle_id: 21,
          branch_id: 5,
          user_id: 36,
          date: '2021-12-01',
          driving_time: 916,
          distance: 4189,
          r_total: 20,
          r_m_s: 20,
          r_t_s: 0,
          r_r_s: 0,
          r_o: 0,
          r_s_d: 0,
          r_g_total: 20,
          r_g_m_s: 20,
          r_g_t_s: 0,
          r_g_r_s: 0,
          r_g_o: 0,
          r_g_s_d: 0,
          r_n_total: 0,
          r_n_m_s: 0,
          r_n_t_s: 0,
          r_n_r_s: 0,
          r_n_o: 0,
          r_n_s_d: 0,
          violations: {
            data: [],
            links: [],
          },
          announcements: {
            data: [],
            links: [],
          },
        },
        {
          vehicle_id: 21,
          branch_id: 5,
          user_id: 36,
          date: '2021-12-02',
          driving_time: 3645,
          distance: 14640,
          r_total: 84,
          r_m_s: 72,
          r_t_s: 5,
          r_r_s: 0,
          r_o: 6,
          r_s_d: 1,
          r_g_total: 63,
          r_g_m_s: 62,
          r_g_t_s: 1,
          r_g_r_s: 0,
          r_g_o: 0,
          r_g_s_d: 0,
          r_n_total: 20,
          r_n_m_s: 10,
          r_n_t_s: 4,
          r_n_r_s: 0,
          r_n_o: 6,
          r_n_s_d: 0,
          violations: {
            data: [
              {
                type: 4,
                date: '2021-12-01',
                time: '23:49:26',
                place: 4,
                hide: 0,
                announced_flag: 0,
              },
            ],
            links: [],
          },
          announcements: {
            data: [
              {
                code: 1,
                code_sub: 1,
              },
              {
                code: 1,
                code_sub: 2,
              },
              {
                code: 1,
                code_sub: 2,
              },
              {
                code: 1,
                code_sub: 2,
              },
              {
                code: 1,
                code_sub: 2,
              },
            ],
            links: [],
          },
        },
        {
          vehicle_id: 22,
          branch_id: 5,
          user_id: 6,
          date: '2021-12-02',
          driving_time: 3644,
          distance: 14195,
          r_total: 74,
          r_m_s: 66,
          r_t_s: 4,
          r_r_s: 0,
          r_o: 3,
          r_s_d: 1,
          r_g_total: 57,
          r_g_m_s: 56,
          r_g_t_s: 1,
          r_g_r_s: 0,
          r_g_o: 0,
          r_g_s_d: 0,
          r_n_total: 16,
          r_n_m_s: 10,
          r_n_t_s: 3,
          r_n_r_s: 0,
          r_n_o: 3,
          r_n_s_d: 0,
          violations: {
            data: [
              {
                type: 2,
                date: '2021-12-01',
                time: '23:48:17',
                place: 2,
                hide: 0,
                announced_flag: 0,
              },
              {
                type: 4,
                date: '2021-12-01',
                time: '23:49:26',
                place: 4,
                hide: 0,
                announced_flag: 0,
              },
            ],
            links: [],
          },
          announcements: {
            data: [
              {
                code: 1,
                code_sub: 2,
              },
              {
                code: 1,
                code_sub: 2,
              },
              {
                code: 1,
                code_sub: 2,
              },
            ],
            links: [],
          },
        },
        {
          vehicle_id: 21,
          branch_id: 5,
          user_id: 36,
          date: '2021-12-02',
          driving_time: 2541,
          distance: 15138,
          r_total: 49,
          r_m_s: 49,
          r_t_s: 0,
          r_r_s: 0,
          r_o: 0,
          r_s_d: 0,
          r_g_total: 46,
          r_g_m_s: 46,
          r_g_t_s: 0,
          r_g_r_s: 0,
          r_g_o: 0,
          r_g_s_d: 0,
          r_n_total: 3,
          r_n_m_s: 3,
          r_n_t_s: 0,
          r_n_r_s: 0,
          r_n_o: 0,
          r_n_s_d: 0,
          violations: {
            data: [
              {
                type: 4,
                date: '2021-12-02',
                time: '04:54:22',
                place: 2,
                hide: 0,
                announced_flag: 0,
              },
              {
                type: 2,
                date: '2021-12-02',
                time: '04:58:05',
                place: 2,
                hide: 0,
                announced_flag: 0,
              },
            ],
            links: [],
          },
          announcements: {
            data: [],
            links: [],
          },
        },
        {
          vehicle_id: 22,
          branch_id: 5,
          user_id: 6,
          date: '2021-12-02',
          driving_time: 2532,
          distance: 16345,
          r_total: 56,
          r_m_s: 56,
          r_t_s: 0,
          r_r_s: 0,
          r_o: 0,
          r_s_d: 0,
          r_g_total: 54,
          r_g_m_s: 54,
          r_g_t_s: 0,
          r_g_r_s: 0,
          r_g_o: 0,
          r_g_s_d: 0,
          r_n_total: 2,
          r_n_m_s: 2,
          r_n_t_s: 0,
          r_n_r_s: 0,
          r_n_o: 0,
          r_n_s_d: 0,
          violations: {
            data: [
              {
                type: 3,
                date: '2021-12-02',
                time: '04:54:03',
                place: 3,
                hide: 0,
                announced_flag: 0,
              },
              {
                type: 5,
                date: '2021-12-02',
                time: '04:54:12',
                place: 2,
                hide: 0,
                announced_flag: 0,
              },
              {
                type: 4,
                date: '2021-12-02',
                time: '04:54:23',
                place: 2,
                hide: 0,
                announced_flag: 0,
              },
            ],
            links: [],
          },
          announcements: {
            data: [
              {
                code: 1,
                code_sub: 2,
              },
            ],
            links: [],
          },
        },
        {
          vehicle_id: 11,
          branch_id: 4,
          user_id: 4,
          date: '2021-12-03',
          driving_time: 267,
          distance: 0,
          r_total: 0,
          r_m_s: 0,
          r_t_s: 0,
          r_r_s: 0,
          r_o: 0,
          r_s_d: 0,
          r_g_total: 0,
          r_g_m_s: 0,
          r_g_t_s: 0,
          r_g_r_s: 0,
          r_g_o: 0,
          r_g_s_d: 0,
          r_n_total: 0,
          r_n_m_s: 0,
          r_n_t_s: 0,
          r_n_r_s: 0,
          r_n_o: 0,
          r_n_s_d: 0,
          violations: {
            data: [],
            links: [],
          },
          announcements: {
            data: [],
            links: [],
          },
        },
        {
          vehicle_id: 21,
          branch_id: 5,
          user_id: 36,
          date: '2021-12-05',
          driving_time: 3553,
          distance: 22712,
          r_total: 54,
          r_m_s: 52,
          r_t_s: 0,
          r_r_s: 1,
          r_o: 0,
          r_s_d: 1,
          r_g_total: 50,
          r_g_m_s: 49,
          r_g_t_s: 0,
          r_g_r_s: 1,
          r_g_o: 0,
          r_g_s_d: 0,
          r_n_total: 3,
          r_n_m_s: 3,
          r_n_t_s: 0,
          r_n_r_s: 0,
          r_n_o: 0,
          r_n_s_d: 0,
          violations: {
            data: [
              {
                type: 2,
                date: '2021-12-05',
                time: '05:32:49',
                place: 3,
                hide: 0,
                announced_flag: 0,
              },
              {
                type: 2,
                date: '2021-12-05',
                time: '05:36:08',
                place: 3,
                hide: 0,
                announced_flag: 0,
              },
            ],
            links: [],
          },
          announcements: {
            data: [
              {
                code: 2,
                code_sub: 2,
              },
              {
                code: 5,
                code_sub: 1,
              },
            ],
            links: [],
          },
        },
        {
          vehicle_id: 11,
          branch_id: 4,
          user_id: 4,
          date: '2021-12-06',
          driving_time: 29,
          distance: 0,
          r_total: 0,
          r_m_s: 0,
          r_t_s: 0,
          r_r_s: 0,
          r_o: 0,
          r_s_d: 0,
          r_g_total: 0,
          r_g_m_s: 0,
          r_g_t_s: 0,
          r_g_r_s: 0,
          r_g_o: 0,
          r_g_s_d: 0,
          r_n_total: 0,
          r_n_m_s: 0,
          r_n_t_s: 0,
          r_n_r_s: 0,
          r_n_o: 0,
          r_n_s_d: 0,
          violations: {
            data: [],
            links: [],
          },
          announcements: {
            data: [],
            links: [],
          },
        },
        {
          vehicle_id: 11,
          branch_id: 4,
          user_id: 4,
          date: '2021-12-06',
          driving_time: 36,
          distance: 0,
          r_total: 0,
          r_m_s: 0,
          r_t_s: 0,
          r_r_s: 0,
          r_o: 0,
          r_s_d: 0,
          r_g_total: 0,
          r_g_m_s: 0,
          r_g_t_s: 0,
          r_g_r_s: 0,
          r_g_o: 0,
          r_g_s_d: 0,
          r_n_total: 0,
          r_n_m_s: 0,
          r_n_t_s: 0,
          r_n_r_s: 0,
          r_n_o: 0,
          r_n_s_d: 0,
          violations: {
            data: [],
            links: [],
          },
          announcements: {
            data: [],
            links: [],
          },
        },
        {
          vehicle_id: 35,
          branch_id: 5,
          user_id: 40,
          date: '2021-12-09',
          driving_time: 24,
          distance: 0,
          r_total: 0,
          r_m_s: 0,
          r_t_s: 0,
          r_r_s: 0,
          r_o: 0,
          r_s_d: 0,
          r_g_total: 0,
          r_g_m_s: 0,
          r_g_t_s: 0,
          r_g_r_s: 0,
          r_g_o: 0,
          r_g_s_d: 0,
          r_n_total: 0,
          r_n_m_s: 0,
          r_n_t_s: 0,
          r_n_r_s: 0,
          r_n_o: 0,
          r_n_s_d: 0,
          violations: {
            data: [],
            links: [],
          },
          announcements: {
            data: [],
            links: [],
          },
        },
        {
          vehicle_id: 17,
          branch_id: 4,
          user_id: 35,
          date: '2021-12-11',
          driving_time: 1258,
          distance: 2680,
          r_total: 15,
          r_m_s: 14,
          r_t_s: 0,
          r_r_s: 1,
          r_o: 0,
          r_s_d: 0,
          r_g_total: 15,
          r_g_m_s: 14,
          r_g_t_s: 0,
          r_g_r_s: 1,
          r_g_o: 0,
          r_g_s_d: 0,
          r_n_total: 0,
          r_n_m_s: 0,
          r_n_t_s: 0,
          r_n_r_s: 0,
          r_n_o: 0,
          r_n_s_d: 0,
          violations: {
            data: [],
            links: [],
          },
          announcements: {
            data: [],
            links: [],
          },
        },
      ],
      links: [],
    }]
  },
}
