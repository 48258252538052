import datastore from './datastore'
export default {
  get({ values, params }) {
    if (values.userId) {
      if (params.date) {
        return [200, { data: datastore[0] }]
      }
      return [501, {}]
    }
    return [501, {}]
  },
}
