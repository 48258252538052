// メニュー項目のバッジに表示する通知件数を管理するVuex Storeモジュール

import Vue from 'vue'

// 通知種別
export const NotificationKey = {
  // 過労運転管理
  CAUTION_DRIVERS: 'caution_drivers',
  // 運行前点検
  INSPECTION: 'inspection',
}

export default {
  namespaced: true,
  state: () => ({
    // 通知有無マップ
    exists: {
      [NotificationKey.CAUTION_DRIVERS]: false,
      [NotificationKey.INSPECTION]: false,
    },
  }),
  mutations: {
    // 通知有無を更新する
    updateExist(state, { key, value }) {
      state.exists[key] = value
    },
  },
  actions: {
    // 通知有無をAPIで取得し、ストアを更新する
    fetch({ commit }, key) {
      const updateExist = (exist) => {
        commit('updateExist', { key, value: exist })
      }

      let path
      switch (key) {
        case NotificationKey.CAUTION_DRIVERS:
          path = 'caution_drivers/unconfirmed/exist'
          break
        case NotificationKey.INSPECTION:
          path = 'inspections/unconfirmed/exist'
          break
        default:
          return
      }

      Vue.prototype.$api.send('get', path, null, 1)
        .then(response => {
          updateExist(response === 1)
        })
        .catch(error => {
          console.warn(`通知有無取得失敗 (${key})`, error)
          updateExist(false)
        })
    },
    // すべての通知をdispatchする
    fetchAll({ dispatch }) {
      Object.values(NotificationKey).forEach(key => {
        dispatch('fetch', key)
      })
    },
  },
  getters: {
    // 通知有無を取得する
    getExist: (state) => (key) => {
      return state.exists[key] || false
    },
  },
}
