var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    {
      class: {
        settings__borderLess: _vm.settings.isBorderless,
        settings__squaredBorders: _vm.settings.isSquaredBorders,
        settings__fixedWidth: _vm.settings.isFixedWidth,
        settings__menuShadow: _vm.settings.isMenuShadow,
        settings__menuTop: _vm.settings.isMenuTop
      }
    },
    [
      !_vm.settings.isMobileView
        ? _c(
            "a-layout-sider",
            {
              staticClass: "no-print",
              class: _vm.settings.isLightTheme
                ? [_vm.$style.sider, _vm.$style.light]
                : _vm.$style.sider,
              attrs: {
                width: 256,
                collapsible: "",
                collapsed: _vm.settings.isMenuCollapsed
              },
              on: { collapse: _vm.onCollapse }
            },
            [_c("cui-menu-left", { attrs: { settings: _vm.settings } })],
            1
          )
        : _vm._e(),
      _c("cui-settings", { attrs: { settings: _vm.settings } }),
      _c(
        "a-layout",
        [
          _c(
            "a-layout-header",
            { staticClass: "no-print" },
            [_c("cui-topbar", { attrs: { settings: _vm.settings } })],
            1
          ),
          _c("a-layout-content", [
            _c(
              "div",
              {
                staticClass: "utils__content print-padding-bottom-0",
                staticStyle: { "max-width": "initial", padding: "0 32px" }
              },
              [_c("router-view")],
              1
            )
          ]),
          _c("a-layout-footer", [_c("cui-footer")], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }