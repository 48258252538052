var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.exists
    ? _c("span", { staticClass: "notification-badge", style: _vm.outerStyle }, [
        _c("i", {
          staticClass: "fa fa-exclamation-circle",
          style: _vm.innerStyle
        })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }