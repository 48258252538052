<template>
  <div>
    <div :class="settings.isLightTheme ? [$style.navigation, $style.light] : $style.navigation">
        <a-menu
          :theme="settings.isLightTheme ? 'light' : 'dark'"
          :mode="'vertical'"
          :selectedKeys="selectedKeys"
          :openKeys.sync="openKeys"
          slot="overlay"
          @click="handleClick"
          @openChange="handleOpenChange"
        >
          <template v-for="(item) in menuData">
            <item
              v-if="
                (item.authority >= role && !item.withoutPlan.includes(plan) && !item.children && !item.divider) &&
                (!item.driveMonitor || showDriveMonitor) &&
                (!item.hideFleet || !fleet) &&
                (!item.showOperator || operator) &&
                (!item.showPlus || plus) &&
                (!item.showCautionDriver || showCautionDriver)"
              :menu-info="item"
              :styles="$style"
              :key="item.key"
            />
            <a-menu-divider v-if="item.authority >= role && item.divider" :key="item.key" />
            <sub-menu v-if="item.authority >= role && item.children" :menu-info="item" :styles="$style" :key="item.key"/>
          </template>
        </a-menu>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import store from 'store'
import storeVue from '@/store'
import _ from 'lodash'
// import vueCustomScrollbar from 'vue-custom-scrollbar'
import { getMenuData } from '@/services/menu'
import SubMenu from './partials/submenu'
import Item from './partials/item'

export default {
  name: 'menu-top',
  components: { SubMenu, Item },
  props: {
    settings: Object,
    withDrawer: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
      required: false,
    },
  },
  created() {
    const _this = this
    this.role = storeVue.getters.role
    this.plan = storeVue.getters.company_plan
    this.fleet = storeVue.getters.fleet
    if (this.role === null) {
      this.role = 3
      const result = Vue.prototype.$api.send('get', 'user')
      result.then(function(res) {
        _this.role = res.role
        _this.fleet = res.fleet_flag
        _this.operator = res.operator_flag
      })
    }
  },
  mounted() {
    this.openKeys = store.get('app.menu.openedKeys') || []
    this.selectedKeys = store.get('app.menu.selectedKeys') || []
    this.setSelectedKeys()
  },
  data() {
    return {
      menuData: getMenuData,
      selectedKeys: [],
      openKeys: [],
      role: 3,
      plan: 0,
      fleet: 1,
    }
  },
  computed: {
    userRole() {
      return storeVue.getters.role
    },
    companyPlan() {
      return storeVue.getters.company_plan
    },
    fleetFlag() {
      return storeVue.getters.fleet
    },
    showDriveMonitor() {
      return storeVue.getters.show_drive_monitor
    },
    plus() {
      return storeVue.getters.plus_flag
    },
    operator() {
      return storeVue.getters.operator
    },
    showCautionDriver() {
      if (this.role <= 2 && storeVue.getters.show_caution_driver == null) {
        this.$store.dispatch('FETCH_COMPANY_INFO')
        return 0
      }
      return storeVue.getters.show_caution_driver
    },
  },
  watch: {
    'settings.isMenuCollapsed'() {
      this.openKeys = []
    },
    '$route'() {
      this.setSelectedKeys()
    },
    userRole(val) {
      this.role = val
    },
    companyPlan(val) {
      this.plan = val
    },
    fleetFlag(val) {
      this.fleet = val
    },
  },
  methods: {
    handleClick(e) {
      if (e.key === 'settings') {
        this.$store.commit('CHANGE_SETTING', { setting: 'isSettingsOpen', value: true })
        return
      }
      this.selectedKeys = [e.key]
      if (this.close) {
        this.close()
      }
    },
    handleOpenChange(openKeys) {
      store.set('app.menu.openedKeys', openKeys)
      this.openKeys = openKeys
    },
    setSelectedKeys() {
      const pathname = this.$route.path
      const menuData = this.menuData.concat()
      const flattenItems = (items, key) =>
        items.reduce((flattenedItems, item) => {
          flattenedItems.push(item)
          if (Array.isArray(item[key])) {
            return flattenedItems.concat(flattenItems(item[key], key))
          }
          return flattenedItems
        }, [])
      const selectedItem = _.find(flattenItems(menuData, 'children'), [
        'url',
        pathname,
      ])
      this.selectedKeys = selectedItem ? [selectedItem.key] : []
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
