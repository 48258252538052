export default {
  get({ values }) {
    return [200, {
      data: {
        id: 1,
        name: 'Genext',
        comment: '管理用',
        plan: 0,
        owned_number: 30,
        separation_of_days: '00:00:00',
        max_user: 0,
        exclude_violations: [
          {
            id: 49,
            company_id: 1,
            violation_type: 1,
            created_at: '2021-11-19 20:15:21',
            updated_at: '2021-11-19 20:15:21',
          },
        ],
        exclude_announcements: [
          {
            id: 10,
            company_id: 1,
            code: 2,
            code_sub: 1,
            created_at: '2021-11-09 09:58:20',
            updated_at: '2021-11-09 09:58:20',
          },
        ],
        show_policy_everytime: 0,
        created_at: '2020-06-29T07:09:49.000000Z',
        updated_at: '2021-12-02T08:11:19.000000Z',
        deleted_at: null,
        show_drive_monitor: 1,
        show_trajectory: 1,
        use_analyze: 1,
        use_announce: 1,
        use_raw_download: 1,
        announce_threshold_maximum_speed: 95.2,
        announce_threshold_maximum_speed_zone30: 98,
        announce_threshold_temporary_stop: 100,
        announce_threshold_railroad_stop: 100,
        email_send_time: '17:15:00',
        email_number_of_ranking: 10,
        email_content_compliance_rate: '1111111010',
        email_content_time_rate: '0000101010',
        email_content_distance_rate: '0100101010',
      },
    }]
  },
}
