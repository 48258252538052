/* eslint-disable */
import mockServer from 'axios-mock-server'
import mock0 from './oauth/token'
import mock1 from './api/vehicles/list/datastore'
import mock2 from './api/vehicles/list/_vehicleId'
import mock3 from './api/vehicles/datastore'
import mock4 from './api/vehicles/_vehicleId'
import mock5 from './api/users/datastore'
import mock6 from './api/users/_userId/datastore'
import mock7 from './api/users/_userId/branches'
import mock8 from './api/users/_userId'
import mock9 from './api/users'
import mock10 from './api/user/vehicles'
import mock11 from './api/user/datastore'
import mock12 from './api/user/dashboard_vehicles'
import mock13 from './api/user/change_belong_company'
import mock14 from './api/user/branches'
import mock15 from './api/user/acquirable_vehicles'
import mock16 from './api/user/_userId/user_read_range'
import mock17 from './api/user'
import mock18 from './api/statistics'
import mock19 from './api/notice_email_address'
import mock20 from './api/groups'
import mock21 from './api/drive_monitor/driving'
import mock22 from './api/drive_data/vehicle'
import mock23 from './api/drive_data/user/datastore'
import mock24 from './api/drive_data/user/_userId/announcements_announced'
import mock25 from './api/drive_data/user/_userId'
import mock26 from './api/drive_data/user'
import mock27 from './api/drive_data/datastore'
import mock28 from './api/drive_data'
import mock29 from './api/datastore'
import mock30 from './api/company/histories'
import mock31 from './api/company/datastore'
import mock32 from './api/company'
import mock33 from './api/companies/datastore'
import mock34 from './api/companies/_companyId'
import mock35 from './api/companies'
import mock36 from './api/branches_users'
import mock37 from './api/branches/datastore'
import mock38 from './api/branches/_branchId/users'
import mock39 from './api/branches/_branchId/datastore'
import mock40 from './api/branches/_branchId'
import mock41 from './api/branches'
import mock42 from './api/api_usage/_companyId'
import mock43 from './api/acquirable_users'

export default (client) => mockServer([
  {
    path: '/oauth/token',
    methods: mock0
  },
  {
    path: '/api/vehicles/list/datastore',
    methods: mock1
  },
  {
    path: '/api/vehicles/list/_vehicleId',
    methods: mock2
  },
  {
    path: '/api/vehicles/datastore',
    methods: mock3
  },
  {
    path: '/api/vehicles/_vehicleId',
    methods: mock4
  },
  {
    path: '/api/users/datastore',
    methods: mock5
  },
  {
    path: '/api/users/_userId/datastore',
    methods: mock6
  },
  {
    path: '/api/users/_userId/branches',
    methods: mock7
  },
  {
    path: '/api/users/_userId',
    methods: mock8
  },
  {
    path: '/api/users',
    methods: mock9
  },
  {
    path: '/api/user/vehicles',
    methods: mock10
  },
  {
    path: '/api/user/datastore',
    methods: mock11
  },
  {
    path: '/api/user/dashboard_vehicles',
    methods: mock12
  },
  {
    path: '/api/user/change_belong_company',
    methods: mock13
  },
  {
    path: '/api/user/branches',
    methods: mock14
  },
  {
    path: '/api/user/acquirable_vehicles',
    methods: mock15
  },
  {
    path: '/api/user/_userId/user_read_range',
    methods: mock16
  },
  {
    path: '/api/user',
    methods: mock17
  },
  {
    path: '/api/statistics',
    methods: mock18
  },
  {
    path: '/api/notice_email_address',
    methods: mock19
  },
  {
    path: '/api/groups',
    methods: mock20
  },
  {
    path: '/api/drive_monitor/driving',
    methods: mock21
  },
  {
    path: '/api/drive_data/vehicle',
    methods: mock22
  },
  {
    path: '/api/drive_data/user/datastore',
    methods: mock23
  },
  {
    path: '/api/drive_data/user/_userId/announcements_announced',
    methods: mock24
  },
  {
    path: '/api/drive_data/user/_userId',
    methods: mock25
  },
  {
    path: '/api/drive_data/user',
    methods: mock26
  },
  {
    path: '/api/drive_data/datastore',
    methods: mock27
  },
  {
    path: '/api/drive_data',
    methods: mock28
  },
  {
    path: '/api/datastore',
    methods: mock29
  },
  {
    path: '/api/company/histories',
    methods: mock30
  },
  {
    path: '/api/company/datastore',
    methods: mock31
  },
  {
    path: '/api/company',
    methods: mock32
  },
  {
    path: '/api/companies/datastore',
    methods: mock33
  },
  {
    path: '/api/companies/_companyId',
    methods: mock34
  },
  {
    path: '/api/companies',
    methods: mock35
  },
  {
    path: '/api/branches_users',
    methods: mock36
  },
  {
    path: '/api/branches/datastore',
    methods: mock37
  },
  {
    path: '/api/branches/_branchId/users',
    methods: mock38
  },
  {
    path: '/api/branches/_branchId/datastore',
    methods: mock39
  },
  {
    path: '/api/branches/_branchId',
    methods: mock40
  },
  {
    path: '/api/branches',
    methods: mock41
  },
  {
    path: '/api/api_usage/_companyId',
    methods: mock42
  },
  {
    path: '/api/acquirable_users',
    methods: mock43
  }
], client, '')
