import datastore from './datastore'
import moment from 'moment'
export default {
  get({ params }) {
    let result = datastore.drive_data.map((element) => element)
    if (params.page) {
      const page = parseInt(params.page, 10)
      if (!page) {
        return [500, {}]
      }
      if (params.dateStart) {
        const comp = moment(params.dateStart)
        if (comp.isValid()) {
          result = result.filter((obj) => moment(obj.date).isSameOrAfter(comp))
        }
      }
      if (params.dateEnd) {
        const comp = moment(params.dateEnd)
        if (comp.isValid()) {
          result = result.filter((obj) => moment(obj.date).isSameOrBefore(comp))
        }
      }
      if (params.users) {
        if (params.users.length > 0) {
          const userIds = params.users.map((user) => parseInt(user))
          result = result.filter((obj) => userIds.includes(obj.user_id))
        }
      }
      if (params.vehicles) {
        if (params.vehicles.length > 0) {
          const vehicleIds = params.vehicles.map((vehicle) => parseInt(vehicle))
          result = result.filter((obj) => vehicleIds.includes(obj.vehicle_id))
        }
      }
      if (params.branches) {
        if (params.branches.length > 0) {
          const branchIds = params.branches.map((branch) => parseInt(branch))
          result = result.filter((obj) => branchIds.includes(obj.branch_id))
        }
      }
      if (params.sortOrder && params.sortItem) {
        if (params.sortOrder !== 'asc' && params.sortOrder !== 'desc') {
          return [500, {}]
        }
        if (params.sortItem === 'drive_data.date') {
          result.sort((former, latter) => {
            const first = moment(former.date)
            const second = moment(latter.date)
            if (first.isAfter(second)) {
              return params.sortOrder === 'asc' ? 1 : -1
            } else if (first.isBefore(second)) {
              return params.sortOrder === 'desc' ? -1 : 1
            } else {
              return 0
            }
          })
        } else if (params.sortItem === 'users.name') {
          result.sort((former, latter) => former === latter ? 0 : former.user_name > latter.user_name ? params.sortOrder === 'asc' ? 1 : -1 : params.sortOrder === 'asc' ? -1 : 1)
        } else if (params.sortItem === 'vehicles.name') {
          result.sort((former, latter) => former === latter ? 0 : former.user_name > latter.user_name ? params.sortOrder === 'asc' ? 1 : -1 : params.sortOrder === 'asc' ? -1 : 1)
        } else if (params.sortItem === 'branches.name') {
          result.sort((former, latter) => former === latter ? 0 : former.user_name > latter.user_name ? params.sortOrder === 'asc' ? 1 : -1 : params.sortOrder === 'asc' ? -1 : 1)
        }
      }
      return [200, { data: result,
        links: {
          first: 'api/drive_data?page=1',
          last: 'api/drive_data?page=6',
          prev: null,
          next: 'api/drive_data?page=2',
        },
        meta: {
          current_page: page,
          from: 1,
          last_page: 6,
          path: 'api/drive_data',
          per_page: 20,
          to: 20,
          total: result.length,
        } }]
    }
    return [500, {}]
  },
}
