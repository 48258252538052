var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-menu-item",
    _vm._g(
      _vm._b(
        { key: _vm.menuInfo.key, attrs: { disabled: _vm.menuInfo.disabled } },
        "a-menu-item",
        _vm.$props,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.menuInfo.url
        ? _c(
            "router-link",
            { attrs: { to: _vm.menuInfo.url } },
            [
              _vm.menuInfo.icon
                ? _c("i", { class: [_vm.styles.icon, _vm.menuInfo.icon] })
                : _vm._e(),
              _c("span", { class: _vm.styles.title }, [
                _vm._v(_vm._s(_vm.menuInfo.title))
              ]),
              _c("notification-badge", {
                attrs: {
                  "notification-key": _vm.menuInfo.notificationKey,
                  "menu-position": "left"
                }
              }),
              _vm.menuInfo.pro
                ? _c("span", { staticClass: "badge badge-primary ml-2" }, [
                    _vm._v("PRO")
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      !_vm.menuInfo.url
        ? _c("span", { attrs: { to: _vm.menuInfo.url } }, [
            _vm.menuInfo.icon
              ? _c("i", { class: [_vm.styles.icon, _vm.menuInfo.icon] })
              : _vm._e(),
            _c("span", { class: _vm.styles.title }, [
              _vm._v(_vm._s(_vm.menuInfo.title))
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }