var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: "Clean UI Settings",
        placement: "right",
        closable: true,
        visible: _vm.settings.isSettingsOpen,
        zIndex: 1010
      },
      on: { close: _vm.onClose }
    },
    [
      _c("div", { class: _vm.$style.description }, [
        _vm._v(
          '\n    This component "Settings" gives possibility to construct custom blocks with any widgets, components and elements\n    inside, like this theme settings\n  '
        )
      ]),
      _c(
        "div",
        { class: _vm.$style.item },
        [
          _c("a-switch", {
            attrs: { checked: _vm.settings.isMenuTop },
            on: {
              click: function($event) {
                return _vm.settingChange($event, "isMenuTop")
              }
            }
          }),
          _c("span", { class: _vm.$style.itemLabel }, [
            _vm._v("Menu Top (Horizontal)")
          ])
        ],
        1
      ),
      _c(
        "div",
        { class: _vm.$style.item },
        [
          _c("a-switch", {
            attrs: { checked: _vm.settings.isMenuCollapsed },
            on: {
              click: function($event) {
                return _vm.settingChange($event, "isMenuCollapsed")
              }
            }
          }),
          _c("span", { class: _vm.$style.itemLabel }, [
            _vm._v("Collapsed Menu")
          ])
        ],
        1
      ),
      _c(
        "div",
        { class: _vm.$style.item },
        [
          _c("a-switch", {
            attrs: { checked: _vm.settings.isMenuShadow },
            on: {
              click: function($event) {
                return _vm.settingChange($event, "isMenuShadow")
              }
            }
          }),
          _c("span", { class: _vm.$style.itemLabel }, [_vm._v("Menu Shadow")])
        ],
        1
      ),
      _c(
        "div",
        { class: _vm.$style.item },
        [
          _c("a-switch", {
            attrs: { checked: _vm.settings.isLightTheme },
            on: {
              click: function($event) {
                return _vm.settingChange($event, "isLightTheme")
              }
            }
          }),
          _c("span", { class: _vm.$style.itemLabel }, [_vm._v("Light Theme")])
        ],
        1
      ),
      _c(
        "div",
        { class: _vm.$style.item },
        [
          _c("a-switch", {
            attrs: { checked: _vm.settings.isBorderless },
            on: {
              click: function($event) {
                return _vm.settingChange($event, "isBorderless")
              }
            }
          }),
          _c("span", { class: _vm.$style.itemLabel }, [
            _vm._v("Borderless Cards")
          ])
        ],
        1
      ),
      _c(
        "div",
        { class: _vm.$style.item },
        [
          _c("a-switch", {
            attrs: { checked: _vm.settings.isSquaredBorders },
            on: {
              click: function($event) {
                return _vm.settingChange($event, "isSquaredBorders")
              }
            }
          }),
          _c("span", { class: _vm.$style.itemLabel }, [
            _vm._v("Squared Borders")
          ])
        ],
        1
      ),
      _c(
        "div",
        { class: _vm.$style.item },
        [
          _c("a-switch", {
            attrs: { checked: _vm.settings.isFixedWidth },
            on: {
              click: function($event) {
                return _vm.settingChange($event, "isFixedWidth")
              }
            }
          }),
          _c("span", { class: _vm.$style.itemLabel }, [_vm._v("Fixed Width")])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }