export default [{
  id: 1,
  branch_id: 1,
  branch_name: '本社',
  user_id: 1,
  user: {
    id: 1,
    company_id: 1,
    role: 1,
    name: 'テスト太郎',
    email: 'test@test.com',
    created_at: '2020-06-29 16:09:50',
    updated_at: '2021-11-11 17:21:38',
    deleted_at: null,
    email_new: null,
    confirmation_token: null,
    bounce_flag: 99,
    mobile_model: null,
    mobile_sim: null,
    unavailable: 0,
    announcement_flag: 1,
    last_login: '2021-11-11 17:21:38',
  },
  name: '1234567',
  number: '1234567',
  logger_id: 1,
  car_type: 6,
  manufacturer: '',
  model: '',
  inspection_expiration_date: null,
  ownership: 0,
  monthly_lease_fee: null,
  date_purchase: null,
  drive_recorder: 0,
  only_app: 0,
  created_at: '2020-06-29T07:16:21.000000Z',
  updated_at: '2021-07-21T05:51:26.000000Z',
  deleted_at: null,
}]
