import datastore from './datastore'
export default {
  get({ values }) {
    if (values.userId - 1 <= datastore.length) {
      return [
        200,
        {
          data: datastore[values.userId - 1],
          links: [],
        },
      ]
    }
    return [500, {}]
  },
}
