import datastore from './datastore'
export default {
  get({ values }) {
    return [
      200,
      {
        data: datastore,
        links: [],
      },
    ]
  },
}
