<template>
  <div :class="[$style.topbar, settings.isMobileView ? $style.mobileTopbar :'']">
    <a-dropdown
      :trigger="['click']"
      placement="bottomLeft"
      :overlayClassName="$style.NavigationTopMenu"
      v-model="NavigationMenuVisible"
      v-if="settings.isMobileView"
    >
      <div :class="$style.openMenuButton">
        <i class="fa fa-bars fa-2x fa-regular" v-if="!NavigationMenuVisible" style="color:white"></i>
        <i class="fa fa-close fa-2x fa-regular" v-if="NavigationMenuVisible" style="color:white"></i>
      </div>
      <cui-menu-top slot="overlay" :close="closeNavigationMenu" :settings="settings"/>
    </a-dropdown>
    <img :class="$style.mobileLogoText"
      v-if="settings.isMobileView"
      src="resources/images/logoText.png"
      alt
    >
    <cui-profile-menu :settings="settings"/>
  </div>
</template>

<script>
import CuiProfileMenu from '@/components/LayoutComponents/Topbar/ProfileMenu'
import CuiMenuTop from '@/components/LayoutComponents/Menu/MenuTop'
export default {
  components: {
    CuiProfileMenu, CuiMenuTop,
  },
  data() {
    return {
      NavigationMenuVisible: false,
    }
  },
  props: {
    settings: Object,
  },
  methods: {
    closeNavigationMenu() {
      this.NavigationMenuVisible = false
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
