export default {
  get({ values }) {
    return [200, {
      data: [
        {
          id: 1,
          name: 'グループテスト',
          comment: null,
          users: [
            {
              id: 36,
              company_id: 1,
              role: 0,
              name: '飯田 貫',
              email: 'iida@kenpalinc.com',
              created_at: '2020-09-07 22:14:01',
              updated_at: '2021-12-05 13:49:11',
              deleted_at: null,
              email_new: null,
              confirmation_token: null,
              bounce_flag: 0,
              mobile_model: null,
              mobile_sim: null,
              unavailable: 0,
              announcement_flag: 1,
              last_login: '2021-12-05 13:49:11',
              pivot: {
                group_id: 1,
                user_id: 36,
              },
            },
            {
              id: 6,
              company_id: 1,
              role: 3,
              name: 'テストドライバー2',
              email: 'test_user2@gmail.com',
              created_at: '2020-06-29 16:25:52',
              updated_at: '2021-12-02 13:43:37',
              deleted_at: null,
              email_new: null,
              confirmation_token: null,
              bounce_flag: 3,
              mobile_model: null,
              mobile_sim: null,
              unavailable: 0,
              announcement_flag: 1,
              last_login: '2021-12-02 13:43:37',
              pivot: {
                group_id: 1,
                user_id: 6,
              },
            },
          ],
          vehicles: [
            {
              id: 40,
              branch_id: 5,
              name: '車両920_Kenpal',
              created_at: '2020-09-15 21:56:27',
              updated_at: '2021-10-26 19:21:57',
              deleted_at: null,
              user_id: null,
              number: '920-1001',
              logger_id: 920,
              car_type: 6,
              longitude: 0,
              latitude: 0,
              status: 0,
              angle: 0,
              only_app: 0,
              latest_gps_date_time: null,
              pivot: {
                group_id: 1,
                vehicle_id: 40,
              },
            },
            {
              id: 39,
              branch_id: 5,
              name: '車両919_Kenpal',
              created_at: '2020-09-15 21:56:14',
              updated_at: '2021-06-01 20:34:01',
              deleted_at: null,
              user_id: 25,
              number: '919-1001',
              logger_id: 919,
              car_type: 6,
              longitude: 122.406417,
              latitude: 37.785833,
              status: 0,
              angle: 0,
              only_app: 0,
              latest_gps_date_time: null,
              pivot: {
                group_id: 1,
                vehicle_id: 39,
              },
            },
            {
              id: 38,
              branch_id: 5,
              name: '車両918_Kenpal',
              created_at: '2020-09-15 21:56:00',
              updated_at: '2021-06-14 21:17:26',
              deleted_at: null,
              user_id: null,
              number: '918-1001',
              logger_id: 918,
              car_type: 6,
              longitude: -121.417333,
              latitude: 37.421998,
              status: 0,
              angle: 0,
              only_app: 0,
              latest_gps_date_time: null,
              pivot: {
                group_id: 1,
                vehicle_id: 38,
              },
            },
          ],
        },
        {
          id: 2,
          name: '新横浜',
          comment: null,
          users: [
            {
              id: 8,
              company_id: 1,
              role: 0,
              name: '笠原 一',
              email: 'hajime_kasahara@genext.co.jp',
              created_at: '2020-08-21 16:44:04',
              updated_at: '2021-12-11 20:37:16',
              deleted_at: null,
              email_new: null,
              confirmation_token: null,
              bounce_flag: 0,
              mobile_model: null,
              mobile_sim: null,
              unavailable: 0,
              announcement_flag: 1,
              last_login: '2021-12-11 20:37:16',
              pivot: {
                group_id: 2,
                user_id: 8,
              },
            },
            {
              id: 7,
              company_id: 1,
              role: 0,
              name: '山地 瞭',
              email: 'ryo_yamaji@genext.co.jp',
              created_at: '2020-07-07 19:43:06',
              updated_at: '2021-11-25 19:09:25',
              deleted_at: null,
              email_new: null,
              confirmation_token: null,
              bounce_flag: 0,
              mobile_model: null,
              mobile_sim: null,
              unavailable: 0,
              announcement_flag: 1,
              last_login: '2021-11-25 19:09:25',
              pivot: {
                group_id: 2,
                user_id: 7,
              },
            },
          ],
          vehicles: [
            {
              id: 11,
              branch_id: 4,
              name: '車両1_genext',
              created_at: '2020-09-11 16:10:41',
              updated_at: '2021-12-06 16:28:59',
              deleted_at: null,
              user_id: null,
              number: '車両1_genext',
              logger_id: 10,
              car_type: 6,
              longitude: 139.617905,
              latitude: 35.459527,
              status: 0,
              angle: 90,
              only_app: 0,
              latest_gps_date_time: '2021-12-06 16:28:56',
              pivot: {
                group_id: 2,
                vehicle_id: 11,
              },
            },
            {
              id: 10,
              branch_id: 4,
              name: 'ロガー10136',
              created_at: '2020-09-08 16:03:50',
              updated_at: '2021-06-07 16:46:03',
              deleted_at: null,
              user_id: null,
              number: 'ロガー10136',
              logger_id: 10136,
              car_type: 6,
              longitude: 139.555567,
              latitude: 35.663233,
              status: 0,
              angle: 0,
              only_app: 0,
              latest_gps_date_time: null,
              pivot: {
                group_id: 2,
                vehicle_id: 10,
              },
            },
          ],
        },
        {
          id: 6,
          name: 'ヤマジテストグループ',
          comment: null,
          users: [
            {
              id: 69,
              company_id: 1,
              role: 3,
              name: '山地ドライバー',
              email: 'ryo.yamaji@studi-ol.com',
              created_at: '2021-04-13 12:44:47',
              updated_at: '2021-04-13 12:45:05',
              deleted_at: null,
              email_new: null,
              confirmation_token: null,
              bounce_flag: 0,
              mobile_model: null,
              mobile_sim: null,
              unavailable: 0,
              announcement_flag: 0,
              last_login: null,
              pivot: {
                group_id: 6,
                user_id: 69,
              },
            },
            {
              id: 55,
              company_id: 1,
              role: 3,
              name: '横村テスト',
              email: 'ainaka38@gmail.com',
              created_at: '2020-12-09 16:42:26',
              updated_at: '2020-12-21 11:13:15',
              deleted_at: null,
              email_new: null,
              confirmation_token: null,
              bounce_flag: 0,
              mobile_model: null,
              mobile_sim: null,
              unavailable: 0,
              announcement_flag: 0,
              last_login: null,
              pivot: {
                group_id: 6,
                user_id: 55,
              },
            },
            {
              id: 36,
              company_id: 1,
              role: 0,
              name: '飯田 貫',
              email: 'iida@kenpalinc.com',
              created_at: '2020-09-07 22:14:01',
              updated_at: '2021-12-05 13:49:11',
              deleted_at: null,
              email_new: null,
              confirmation_token: null,
              bounce_flag: 0,
              mobile_model: null,
              mobile_sim: null,
              unavailable: 0,
              announcement_flag: 1,
              last_login: '2021-12-05 13:49:11',
              pivot: {
                group_id: 6,
                user_id: 36,
              },
            },
            {
              id: 35,
              company_id: 1,
              role: 3,
              name: 'Hajime Kasahara',
              email: 'test_genext9@gmail.com',
              created_at: '2020-08-29 13:13:08',
              updated_at: '2021-12-11 20:02:39',
              deleted_at: null,
              email_new: null,
              confirmation_token: '',
              bounce_flag: 3,
              mobile_model: null,
              mobile_sim: null,
              unavailable: 0,
              announcement_flag: 1,
              last_login: '2021-12-11 20:02:39',
              pivot: {
                group_id: 6,
                user_id: 35,
              },
            },
            {
              id: 34,
              company_id: 1,
              role: 3,
              name: 'カサハラハジメ',
              email: 'test_genext8@gmail.com',
              created_at: '2020-08-29 13:13:08',
              updated_at: '2021-07-13 18:20:38',
              deleted_at: null,
              email_new: null,
              confirmation_token: '',
              bounce_flag: 3,
              mobile_model: null,
              mobile_sim: null,
              unavailable: 0,
              announcement_flag: 1,
              last_login: null,
              pivot: {
                group_id: 6,
                user_id: 34,
              },
            },
            {
              id: 33,
              company_id: 1,
              role: 3,
              name: 'かさはらはじめ',
              email: 'test_genext7@gmail.com',
              created_at: '2020-08-29 13:13:08',
              updated_at: '2020-09-17 14:04:38',
              deleted_at: null,
              email_new: null,
              confirmation_token: '',
              bounce_flag: 3,
              mobile_model: null,
              mobile_sim: null,
              unavailable: 0,
              announcement_flag: 0,
              last_login: null,
              pivot: {
                group_id: 6,
                user_id: 33,
              },
            },
          ],
          vehicles: [],
        },
        {
          id: 7,
          name: 'ワタナベテストグループ',
          comment: null,
          users: [
            {
              id: 38,
              company_id: 1,
              role: 0,
              name: '渡邊 俊樹',
              email: 'toshiki_watanabe@genext.co.jp',
              created_at: '2020-09-11 16:04:39',
              updated_at: '2021-11-25 14:20:15',
              deleted_at: null,
              email_new: null,
              confirmation_token: null,
              bounce_flag: 0,
              mobile_model: null,
              mobile_sim: null,
              unavailable: 0,
              announcement_flag: 1,
              last_login: '2021-11-25 14:20:15',
              pivot: {
                group_id: 7,
                user_id: 38,
              },
            },
          ],
          vehicles: [],
        },
      ],
      links: [],
    }]
  },
}
