export default [{
  id: 1,
  company_id: 1,
  company_name: 'Genext',
  name: '本社',
  address: '新横浜',
  comment: '',
  alcohol_check_type: 0,
  alcohol_check_default_alcohol_detector: 0,
  alcohol_check_default_drinking: 0,
  alcohol_check_default_roll_call_type: 0,
  created_at: '2020-06-29T07:09:49.000000Z',
  updated_at: '2020-06-29T07:09:49.000000Z',
  deleted_at: null,
}]
