<template>
  <a-layout>
    <a-layout-content>
      <div :class="$style.layout">
        <div :class="$style.left">
          <p :class="$style.msgText">WELCOME TO</p>
          <div :class="$style.logoContainer">
            <img
              src="resources/images/logo-white.png"
              alt="AI-Contact"
              :class="$style.logo"
            >
            <img
              src="resources/images/logoText.png"
              alt="AI-Contact"
              :class="$style.logoText"
            >
          </div>
        </div>
        <div :class="$style.right">
          <div :class="$style.loginFormContainer">
            <router-view/>
          </div>
          <div :class="$style.rightFooter">
            <cui-footer/>
          </div>
        </div>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import CuiFooter from '@/components/LayoutComponents/Footer'
export default {
  name: 'LoginLayout',
  components: { CuiFooter },
  data: function () {
    return {
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
