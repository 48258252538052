export default {
  get({ values }) {
    return [
      200,
      {
        data: [
          {
            id: 1,
            name: 'Genext',
            comment: '\u7ba1\u7406\u7528',
            plan: 0,
            max_user: 0,
            exclude_violations: [
              {
                id: 49,
                company_id: 1,
                violation_type: 1,
                created_at: '2021-11-19 20:15:21',
                updated_at: '2021-11-19 20:15:21',
              },
            ],
            exclude_announcements: [
              {
                id: 10,
                company_id: 1,
                code: 2,
                code_sub: 1,
                created_at: '2021-11-09 09:58:20',
                updated_at: '2021-11-09 09:58:20',
              },
            ],
            show_policy_everytime: 0,
            created_at: '2020-06-29T07:09:49.000000Z',
            updated_at: '2021-12-02T08:11:19.000000Z',
            deleted_at: null,
            show_drive_monitor: 1,
            show_trajectory: 1,
            use_analyze: 1,
            use_announce: 1,
            use_raw_download: 1,
            announce_threshold_maximum_speed: 95.2,
            announce_threshold_maximum_speed_zone30: 98,
            announce_threshold_temporary_stop: 100,
            announce_threshold_railroad_stop: 100,
            email_send_time: '17:15:00',
            email_number_of_ranking: 10,
            email_content_compliance_rate: '1111111010',
            email_content_time_rate: '0000101010',
            email_content_distance_rate: '0100101010',
          },
          {
            id: 2,
            name: '\u30c6\u30b9\u30c8\u682a\u5f0f\u4f1a\u793e',
            comment: '\u901a\u5e38\u5229\u7528',
            plan: 4,
            max_user: 0,
            exclude_violations: [],
            exclude_announcements: [],
            show_policy_everytime: 0,
            created_at: '2020-06-29T07:09:49.000000Z',
            updated_at: '2021-03-16T04:43:20.000000Z',
            deleted_at: null,
            show_drive_monitor: 1,
            show_trajectory: 1,
            use_analyze: 1,
            use_announce: 0,
            use_raw_download: 0,
            announce_threshold_maximum_speed: 100,
            announce_threshold_maximum_speed_zone30: 100,
            announce_threshold_temporary_stop: 100,
            announce_threshold_railroad_stop: 100,
            email_send_time: '07:00:00',
            email_number_of_ranking: 10,
            email_content_compliance_rate: '1111110000',
            email_content_time_rate: '0000000000',
            email_content_distance_rate: '0000000000',
          },
          {
            id: 3,
            name:
              'NEC\u30cd\u30af\u30b5\u30bd\u30ea\u30e5\u30fc\u30b7\u30e7\u30f3\u30ba\u682a\u5f0f\u4f1a\u793e',
            comment: '\u30c6\u30b9\u30c8\u5229\u7528',
            plan: 0,
            max_user: 3,
            exclude_violations: [],
            exclude_announcements: [],
            show_policy_everytime: 0,
            created_at: '2020-09-28T08:58:31.000000Z',
            updated_at: '2020-10-01T00:59:54.000000Z',
            deleted_at: null,
            show_drive_monitor: 1,
            show_trajectory: 1,
            use_analyze: 1,
            use_announce: 0,
            use_raw_download: 0,
            announce_threshold_maximum_speed: 100,
            announce_threshold_maximum_speed_zone30: 100,
            announce_threshold_temporary_stop: 100,
            announce_threshold_railroad_stop: 100,
            email_send_time: '07:00:00',
            email_number_of_ranking: 10,
            email_content_compliance_rate: '1111110000',
            email_content_time_rate: '0000000000',
            email_content_distance_rate: '0000000000',
          },
          {
            id: 4,
            name:
              '\u30bd\u30d5\u30c8\u30d0\u30f3\u30af\u682a\u5f0f\u4f1a\u793e',
            comment: '\u30c6\u30b9\u30c8\u5229\u7528',
            plan: 1,
            max_user: 3,
            exclude_violations: [],
            exclude_announcements: [],
            show_policy_everytime: 0,
            created_at: '2020-10-01T00:55:57.000000Z',
            updated_at: '2020-11-18T18:05:29.000000Z',
            deleted_at: null,
            show_drive_monitor: 1,
            show_trajectory: 1,
            use_analyze: 1,
            use_announce: 0,
            use_raw_download: 0,
            announce_threshold_maximum_speed: 100,
            announce_threshold_maximum_speed_zone30: 100,
            announce_threshold_temporary_stop: 100,
            announce_threshold_railroad_stop: 100,
            email_send_time: '07:00:00',
            email_number_of_ranking: 10,
            email_content_compliance_rate: '1111110000',
            email_content_time_rate: '0000000000',
            email_content_distance_rate: '0000000000',
          },
          {
            id: 5,
            name: 'API\u30c6\u30b9\u30c8',
            comment: null,
            plan: 2,
            max_user: 0,
            exclude_violations: [],
            exclude_announcements: [],
            show_policy_everytime: 0,
            created_at: '2020-11-18T18:05:50.000000Z',
            updated_at: '2020-11-18T18:07:45.000000Z',
            deleted_at: null,
            show_drive_monitor: 1,
            show_trajectory: 1,
            use_analyze: 1,
            use_announce: 0,
            use_raw_download: 0,
            announce_threshold_maximum_speed: 100,
            announce_threshold_maximum_speed_zone30: 100,
            announce_threshold_temporary_stop: 100,
            announce_threshold_railroad_stop: 100,
            email_send_time: '07:00:00',
            email_number_of_ranking: 10,
            email_content_compliance_rate: '1111110000',
            email_content_time_rate: '0000000000',
            email_content_distance_rate: '0000000000',
          },
          {
            id: 6,
            name: '\u682a\u5f0f\u4f1a\u793e\u30ab\u30fc\u30e1\u30a4\u30c8',
            comment: null,
            plan: 2,
            max_user: 0,
            exclude_violations: [],
            exclude_announcements: [],
            show_policy_everytime: 0,
            created_at: '2020-11-19T08:18:09.000000Z',
            updated_at: '2020-11-19T08:18:59.000000Z',
            deleted_at: null,
            show_drive_monitor: 1,
            show_trajectory: 1,
            use_analyze: 1,
            use_announce: 0,
            use_raw_download: 0,
            announce_threshold_maximum_speed: 100,
            announce_threshold_maximum_speed_zone30: 100,
            announce_threshold_temporary_stop: 100,
            announce_threshold_railroad_stop: 100,
            email_send_time: '07:00:00',
            email_number_of_ranking: 10,
            email_content_compliance_rate: '1111110000',
            email_content_time_rate: '0000000000',
            email_content_distance_rate: '0000000000',
          },
          {
            id: 7,
            name: '\u3010\u5c71\u5730\u3011\u30c6\u30b9\u30c8\u4f1a\u793e',
            comment: '\u5c71\u5730\u306e\u30c6\u30b9\u30c8\u7528',
            plan: 4,
            max_user: 5,
            exclude_violations: [],
            exclude_announcements: [],
            show_policy_everytime: 0,
            created_at: '2020-12-21T02:12:58.000000Z',
            updated_at: '2021-04-13T05:36:01.000000Z',
            deleted_at: null,
            show_drive_monitor: 1,
            show_trajectory: 1,
            use_analyze: 1,
            use_announce: 0,
            use_raw_download: 0,
            announce_threshold_maximum_speed: 100,
            announce_threshold_maximum_speed_zone30: 100,
            announce_threshold_temporary_stop: 100,
            announce_threshold_railroad_stop: 100,
            email_send_time: '07:00:00',
            email_number_of_ranking: 10,
            email_content_compliance_rate: '1111110000',
            email_content_time_rate: '0000000000',
            email_content_distance_rate: '0000000000',
          },
          {
            id: 8,
            name:
              '\u682a\u5f0f\u4f1a\u793e\u30bc\u30f3\u30ea\u30f3\u30c7\u30fc\u30bf\u30b3\u30e0',
            comment: null,
            plan: 2,
            max_user: 0,
            exclude_violations: [],
            exclude_announcements: [],
            show_policy_everytime: 0,
            created_at: '2020-12-23T08:28:03.000000Z',
            updated_at: '2020-12-23T08:36:16.000000Z',
            deleted_at: null,
            show_drive_monitor: 1,
            show_trajectory: 1,
            use_analyze: 1,
            use_announce: 0,
            use_raw_download: 0,
            announce_threshold_maximum_speed: 100,
            announce_threshold_maximum_speed_zone30: 100,
            announce_threshold_temporary_stop: 100,
            announce_threshold_railroad_stop: 100,
            email_send_time: '07:00:00',
            email_number_of_ranking: 10,
            email_content_compliance_rate: '1111110000',
            email_content_time_rate: '0000000000',
            email_content_distance_rate: '0000000000',
          },
          {
            id: 9,
            name:
              'NEC\u30cd\u30af\u30b5\u30bd\u30ea\u30e5\u30fc\u30b7\u30e7\u30f3\u30ba\u682a\u5f0f\u4f1a\u793e',
            comment:
              'API\u691c\u8a3c\u74b0\u5883\u3068\u3057\u3066\u63d0\u4f9b\u3002\uff082021\u5e741\u670821\u65e5\uff5e\uff09',
            plan: 2,
            max_user: 0,
            exclude_violations: [],
            exclude_announcements: [],
            show_policy_everytime: 0,
            created_at: '2021-01-21T06:57:23.000000Z',
            updated_at: '2021-01-21T08:36:43.000000Z',
            deleted_at: null,
            show_drive_monitor: 1,
            show_trajectory: 1,
            use_analyze: 1,
            use_announce: 0,
            use_raw_download: 0,
            announce_threshold_maximum_speed: 100,
            announce_threshold_maximum_speed_zone30: 100,
            announce_threshold_temporary_stop: 100,
            announce_threshold_railroad_stop: 100,
            email_send_time: '07:00:00',
            email_number_of_ranking: 10,
            email_content_compliance_rate: '1111110000',
            email_content_time_rate: '0000000000',
            email_content_distance_rate: '0000000000',
          },
          {
            id: 11,
            name:
              '\u3010\u5c71\u5730\u3011\u30c6\u30b9\u30c8\u4f1a\u793e(\u8907\u88fd: 2021-02-26)',
            comment: null,
            plan: 4,
            max_user: 5,
            exclude_violations: [],
            exclude_announcements: [],
            show_policy_everytime: 0,
            created_at: '2021-02-26T09:22:55.000000Z',
            updated_at: '2021-04-13T01:42:16.000000Z',
            deleted_at: null,
            show_drive_monitor: 0,
            show_trajectory: 0,
            use_analyze: 1,
            use_announce: 0,
            use_raw_download: 0,
            announce_threshold_maximum_speed: 100,
            announce_threshold_maximum_speed_zone30: 100,
            announce_threshold_temporary_stop: 100,
            announce_threshold_railroad_stop: 100,
            email_send_time: '07:00:00',
            email_number_of_ranking: 10,
            email_content_compliance_rate: '1111110000',
            email_content_time_rate: '0000000000',
            email_content_distance_rate: '0000000000',
          },
          {
            id: 12,
            name:
              '\u6771\u4eac\u6d77\u4e0a\u65e5\u52d5\u30ea\u30b9\u30af\u30b3\u30f3\u30b5\u30eb\u30c6\u30a3\u30f3\u30b0\u682a\u5f0f\u4f1a\u793e',
            comment: null,
            plan: 2,
            max_user: 0,
            exclude_violations: [],
            exclude_announcements: [],
            show_policy_everytime: 0,
            created_at: '2021-03-02T02:28:20.000000Z',
            updated_at: '2021-03-02T02:32:10.000000Z',
            deleted_at: null,
            show_drive_monitor: 1,
            show_trajectory: 1,
            use_analyze: 1,
            use_announce: 0,
            use_raw_download: 0,
            announce_threshold_maximum_speed: 100,
            announce_threshold_maximum_speed_zone30: 100,
            announce_threshold_temporary_stop: 100,
            announce_threshold_railroad_stop: 100,
            email_send_time: '07:00:00',
            email_number_of_ranking: 10,
            email_content_compliance_rate: '1111110000',
            email_content_time_rate: '0000000000',
            email_content_distance_rate: '0000000000',
          },
          {
            id: 13,
            name:
              '\u4e98\u7406\u753a\u5f79\u5834\uff08\u691c\u8a3c\u74b0\u5883\uff09',
            comment: null,
            plan: 4,
            max_user: 0,
            exclude_violations: [],
            exclude_announcements: [],
            show_policy_everytime: 0,
            created_at: '2021-06-15T06:31:07.000000Z',
            updated_at: '2021-06-18T02:21:48.000000Z',
            deleted_at: null,
            show_drive_monitor: 0,
            show_trajectory: 0,
            use_analyze: 1,
            use_announce: 0,
            use_raw_download: 0,
            announce_threshold_maximum_speed: 100,
            announce_threshold_maximum_speed_zone30: 100,
            announce_threshold_temporary_stop: 100,
            announce_threshold_railroad_stop: 100,
            email_send_time: '07:00:00',
            email_number_of_ranking: 10,
            email_content_compliance_rate: '1111110000',
            email_content_time_rate: '0000000000',
            email_content_distance_rate: '0000000000',
          },
          {
            id: 14,
            name: '\u5ca1\u5d0e\u30c6\u30b9\u30c81',
            comment: null,
            plan: 0,
            max_user: 6,
            exclude_violations: [],
            exclude_announcements: [],
            show_policy_everytime: 0,
            created_at: '2021-08-07T09:00:54.000000Z',
            updated_at: '2021-08-10T13:15:38.000000Z',
            deleted_at: null,
            show_drive_monitor: 1,
            show_trajectory: 1,
            use_analyze: 1,
            use_announce: 0,
            use_raw_download: 0,
            announce_threshold_maximum_speed: 100,
            announce_threshold_maximum_speed_zone30: 100,
            announce_threshold_temporary_stop: 100,
            announce_threshold_railroad_stop: 100,
            email_send_time: '07:00:00',
            email_number_of_ranking: 10,
            email_content_compliance_rate: '1111110000',
            email_content_time_rate: '0000000000',
            email_content_distance_rate: '0000000000',
          },
          {
            id: 15,
            name: '\u682a\u5f0f\u4f1a\u793e\u6587\u5275',
            comment: 'AI-Contact NOW\u30c8\u30e9\u30a4\u30a2\u30eb',
            plan: 0,
            max_user: 0,
            exclude_violations: [],
            exclude_announcements: [],
            show_policy_everytime: 0,
            created_at: '2021-10-01T04:37:28.000000Z',
            updated_at: '2021-10-01T04:37:28.000000Z',
            deleted_at: null,
            show_drive_monitor: 1,
            show_trajectory: 1,
            use_analyze: 0,
            use_announce: 1,
            use_raw_download: 0,
            announce_threshold_maximum_speed: 100,
            announce_threshold_maximum_speed_zone30: 100,
            announce_threshold_temporary_stop: 100,
            announce_threshold_railroad_stop: 100,
            email_send_time: '07:00:00',
            email_number_of_ranking: 10,
            email_content_compliance_rate: '1111110000',
            email_content_time_rate: '0000000000',
            email_content_distance_rate: '0000000000',
          },
        ],
        links: [],
      },
    ]
  },
}
